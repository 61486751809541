export enum FormType {
  Select = 'select',
  Radio = 'radio',
  Number = 'number',
  Email = 'email',
  Password = 'password',
  Text = 'text',
  LazyLoadingSelect = 'lazy-loading-select',
  Date = 'date',
  Checkbox = 'checkbox',
  File = 'file',
  Photos = 'photos',
  PlacesAutocomplete = 'places-autocomplete',
  CountrySelect = 'country-select'
}
