import { Context } from '@nuxt/types/app'
import openUrl from '~/lib/url/openUrl'
import { WHITELIST_PAGES } from '~/lib/auth'

const whitelist = ['login-error', 'error']
const redirectFromExclusion = ['index', ...WHITELIST_PAGES, ...whitelist]

const isLoggedIn: (ctx: Context, cb: Function) => Promise<void> = async ({
  redirect,
  store,
  route,
  $services,
  $platform,
  $auth,
  app
}) => {
  if ($auth.strategy.options.name === 'sso') return

  const isAfterLogin = !!route.query.code
  const hasRefreshToken = !!route.query.refresh_token
  const isRegister = route.query?.register === 'true'
  const redirectFrom = sessionStorage.getItem('redirectFrom')
  const isRedirectFromExcluded =
    !!route.name && redirectFromExclusion.includes(route.name)
  const isWelcomePage = route.name === 'welcome'

  if (whitelist.includes(route.name || '') || process.env.STORYBOOK) {
    store.commit('user/animationDone')
    return
  }

  if (!isRedirectFromExcluded && !redirectFrom) {
    sessionStorage.setItem('redirectFrom', route.fullPath)
    // set redirect to nuxt auth, in case user changes to sso login
    $auth.$storage.setUniversal('redirect', route.fullPath)
  }

  if (isRegister) {
    openUrl($services.login.registerUrl, '_self')
    store.commit('user/animationDone')
    return
  }

  // get refresh token from LC
  if (hasRefreshToken) {
    try {
      await store.dispatch('user/updateRefreshToken', route.query.refresh_token)
    } catch (err) {
      redirect('/welcome')
      return
    }
  }

  if (isAfterLogin) {
    try {
      // reset apollo cache to prevent bleeding data from one user to another
      await app?.apolloProvider?.defaultClient.resetStore()
    } catch {}
    if ($platform.isNative) return
    try {
      await store.dispatch('user/login', route.query)
      if (redirectFrom) redirect(redirectFrom)
      store.commit('user/animationDone')
    } catch (err) {
      redirect({ name: 'login-error' })
      store.commit('user/animationDone')
      return
    }
  }

  if (!store.getters['user/isLoggedIn']) {
    try {
      await store.dispatch('user/refreshAccessToken')
      if (store.getters['user/isLoggedIn']) {
        $platform.native.notifications?.setup()
        store.commit('user/animationDone')
      } else if (isAfterLogin) {
        redirect({ name: 'login-error' })
        store.commit('user/animationDone')
      }
    } catch (err) {
      if (!isWelcomePage) redirect('/welcome')
    }
  }
}

export default isLoggedIn
