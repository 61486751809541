//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DOMPurify from 'dompurify'
import marked from 'marked'

export default {
  name: 'MoleculesFormCheckbox',
  inheritAttrs: false,
  props: {
    description: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedDescription() {
      return DOMPurify.sanitize(marked(this.description))
    },
    errorCssClass() {
      if (this.hasError) {
        return 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
      }

      return ''
    }
  }
}
