//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash-es'

const SIZE = {
  s: {
    cssClass: 'sm:max-w-sm'
  },
  m: {
    cssClass: 'sm:max-w-lg'
  },
  l: {
    cssClass: 'sm:max-w-5xl'
  }
}

const DEFAULT_SIZE = 'm'

export default {
  props: {
    title: { type: String, default: undefined },
    content: { type: String, default: undefined },
    size: {
      type: String,
      default: DEFAULT_SIZE
    },
    showCloseButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    cssClass() {
      return get(SIZE, [this.size, 'cssClass'], SIZE[DEFAULT_SIZE].cssClass)
    }
  },
  mounted() {
    const escapeHandler = (e) => {
      if (e.key === 'Escape') {
        this.close()
      }
    }
    document.addEventListener('keydown', escapeHandler)
    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('keydown', escapeHandler)
    })
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
