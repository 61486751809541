var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-b border-gray-200 overflow-x-auto overflow-y-hidden"},[_c('div',{staticClass:"sm:hidden px-4 pb-4"},[_c('label',{staticClass:"sr-only",attrs:{"for":"tabs"}},[_vm._v("Select a tab")]),_vm._v(" "),_c('atoms-select',{class:_vm.selectClass,attrs:{"id":"tabs","name":"tabs","options":_vm.tabs,"value":_vm.value,"aria-label":"tab-select"},on:{"change":_vm.onChange}})],1),_vm._v(" "),_c('div',{staticClass:"hidden sm:flex"},[_c('nav',{staticClass:"-mb-px flex space-x-5 select-none",attrs:{"aria-label":"tab-select"}},_vm._l((_vm.tabs),function(tab,index){return _c('a',{key:tab.label,class:[
          ("__tid_tab_nav_" + (_vm.getTestId(
            tab.label
          )) + " whitespace-nowrap py-4 px-1 border-b-2 mb-px font-medium text-sm cursor-pointer"),
          _vm.active === index
            ? 'border-primary-500 text-primary-600'
            : 'border-transparent text-gray-500 hover:text-gray-600 hover:border-primary-300'
        ],attrs:{"href":tab.href,"aria-current":_vm.active === index && 'page',"aria-label":("select-item-" + (tab.key))},on:{"click":function($event){$event.preventDefault();return _vm.$emit('change', index)}}},[(tab.iconName)?_c('atoms-icon',{staticClass:"h-4 w-4",class:tab.iconClass,attrs:{"name":tab.iconName}}):_vm._e(),_vm._v("\n        "+_vm._s(_vm.$t(tab.label))+"\n      ")],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }