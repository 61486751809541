//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const bgClasses = {
  gray: '!bg-gray-500',
  yellow: '!bg-yellow-600',
  red: '!bg-red-800',
  green: '!bg-green-700'
}
export default {
  props: {
    title: {
      type: String,
      default: 'Status'
    },
    subtitle: {
      type: String,
      default: 'All works just fine'
    },
    bg: {
      type: String,
      default: null
    },
    status: {
      type: String,
      default: 'gray'
    }
  },
  computed: {
    bgClass() {
      return bgClasses[this.status]
    }
  }
}
