//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: Number,
      required: true
    }
  }
}
