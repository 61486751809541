//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sortBy } from 'lodash-es'
import Scroll2ViewMixin from '~/mixins/Scroll2ViewMixin'
import { Product } from '~/models/product/Product'

export default {
  mixins: [Scroll2ViewMixin],
  props: {
    product: { type: [Product, Object], required: true }
  },
  data: () => ({
    activeCategoryId: null,
    isLoading: false
  }),
  computed: {
    documents() {
      return this.$store.getters['documents/documents'](this.product?.ref)
    },
    categories() {
      return this.$store.getters['documents/categories'](this.product?.ref)
    },
    sortedCategories() {
      const cats = this.categories || []
      return sortBy(cats, 'title')
    }
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.loadDocuments()
      }
    },
    product: {
      immediate: true,
      handler() {
        if (!this.documents) {
          this.loadDocuments()
        }
      }
    }
  },
  methods: {
    toggle(id) {
      if (this.activeCategoryId === id) {
        this.activeCategoryId = null
      } else {
        this.activeCategoryId = id
        this.scroll2view(id)
      }
    },
    getDocuments(categoryId) {
      return this.$store.getters['documents/documentsByCategoryId'](
        categoryId,
        this.product?.ref
      )
    },
    async loadDocuments() {
      if (!this.product || !this.product.ref) return

      this.isLoading = true
      try {
        await this.$store.dispatch('documents/request', this.product.ref)
      } catch (e) {
        // an 404 return is okay, because that only indicates,
        // that there are no documents for this ref.
        // any other error should be displayed
        if (!e?.message.includes('404')) {
          this.$store.dispatch('notify/error', {
            message: this.$t('sources_details.documents_error')
          })
        }
      }
      this.isLoading = false
    }
  }
}
