//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    color: {
      type: String,
      default: 'gray'
    },
    counter: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    badgeColor() {
      switch (this.color) {
        case 'green':
          return 'bg-green-400'
        case 'red':
          return 'bg-red-400'
        case 'yellow':
          return 'bg-yellow-400'
        case 'primary':
          return 'bg-primary-400'
        default:
          return `bg-gray-300`
      }
    },
    textColor() {
      switch (this.color) {
        case 'green':
          return 'text-green-800'
        case 'red':
          return 'text-red-800'
        case 'yellow':
          return 'text-yellow-800'
        case 'primary':
          return 'text-white'
        default:
          return `text-gray-500`
      }
    }
  }
}
