//
//
//
//
//
//
//
//
//

import NotificationMixin from '~/mixins/notification-mixin/NotificationMixin'

const MAX_FILES = 5

export default {
  mixins: [NotificationMixin],
  props: {
    thing: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isLoading: false
  }),
  methods: {
    async onSubmit({ data, validation }) {
      if (!validation.$invalid) {
        this.isLoading = true
        await this.submit(data)
        this.isLoading = false
      }
    },
    async submit(formData) {
      const payload = this.createPayload(formData)

      if (payload.files.length > MAX_FILES) {
        return this.notify().maxFilesError.call(this)
      }

      try {
        await this.$api.prioService.post(payload)
        await this.notify().saveSuccess.call(this)
        return this.$emit('close')
      } catch {
        return this.notify().saveError.call(this)
      }
    },
    createPayload({ phoneNumber, description, photos }) {
      return {
        serial_number: this.thing.sn,
        ref_number: this.thing.model,
        email: this.$store.getters['user/profile'].email,
        phone_number: phoneNumber,
        description,
        files: photos.map(
          (photo) => `data:image/${photo.format};base64,${photo.base64String}`
        )
      }
    },
    notify() {
      const saveSuccessMessage = this.$t('prio_service.submitted')
      const saveErrorMessage = this.$t('prio_service.error')
      const maxFilesErrorMessage = this.$t('prio_service.max_files_reached')

      return {
        saveSuccess() {
          return this.notifySuccess(saveSuccessMessage)
        },
        saveError() {
          return this.notifyError(saveErrorMessage)
        },
        maxFilesError() {
          return this.notifyError(maxFilesErrorMessage)
        }
      }
    }
  }
}
