//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const bgClasses = {
  gray: '!bg-gray-500',
  yellow: '!bg-yellow-600',
  red: '!bg-red-800',
  green: '!bg-primary-500'
}
export default {
  props: {
    index: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Status'
    },
    subtitle: {
      type: String,
      default: 'All works just fine'
    },
    bg: {
      type: String,
      default: null
    },
    status: {
      type: String,
      default: 'gray'
    }
  },
  computed: {
    bgClass() {
      return bgClasses[this.status]
    },
    thingId() {
      return this.$route.params.thingId
    }
  }
}
