import { render, staticRenderFns } from "./Parameters.vue?vue&type=template&id=35edd5cf&"
import script from "./Parameters.vue?vue&type=script&lang=js&"
export * from "./Parameters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesAlert: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Alert.vue').default,MoleculesEmptyState: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/EmptyState.vue').default,ComponentComposerPure: require('/builds/duerrdental/vistor/frontend/vsm/components/ComponentComposerPure.vue').default})
