//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    organizationTree: {
      type: Array,
      default: () => []
    },
    selectedOrganizationId: {
      type: String,
      default: null
    },
    depth: {
      type: Number,
      default: 0
    }
  }
}
