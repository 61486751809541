//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      navigation: this.$envConfig.navigation,
      notificationPosition: window.innerWidth < 768 ? 'bottom' : 'top'
    }
  },
  head() {
    const defaultFavicon = '/favicon/vsm.png'
    const safariFavicon = '/favicon/vsm-black.svg'
    return {
      title: this.$t('app_name'),
      meta: [{ hid: 'description', name: 'description', content: '' }],
      link: [
        {
          hid: 'icon',
          rel: 'icon',
          type: 'image/png',
          href: defaultFavicon
        },
        // iOS Web
        {
          hid: 'apple-touch-icon',
          rel: 'apple-touch-icon',
          type: 'image/png',
          href: defaultFavicon
        },
        // Safari (pinned tab)
        {
          hid: 'mask-icon',
          rel: 'mask-icon',
          href: safariFavicon,
          color: '#00BBEE'
        }
      ],
      script: [
        {
          src: `https://maps.googleapis.com/maps/api/js?key=${this.$envConfig.API_KEY_GOOGLE_PLACES}&libraries=places`,
          body: true
        }
      ]
    }
  },
  computed: {
    loading() {
      return this.$store.getters['user/loadingSources']
    },
    user() {
      return {
        name: this.$store.getters['user/profile'].name || 'No Name',
        email:
          this.$store.getters['user/profile'].email || 'no@email.example.com'
      }
    },
    isNative() {
      return this.$platform.isNative
    },
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn']
    }
  },
  watch: {
    '$nuxt.isOnline': {
      handler(online) {
        const onlinePayload = {
          message: this.$t('messages.online')
        }
        const offlinePayload = {
          type: 'warning',
          message: this.$t('warnings.no_internet'),
          dismiss: false,
          timeout: 99999999
        }
        if (online) {
          this.$store.dispatch('notify/info', onlinePayload)
          this.$store.dispatch('notify/hide', offlinePayload)
        } else {
          this.$store.dispatch('notify/dialog', offlinePayload)
          this.$store.dispatch('notify/hide', onlinePayload)
        }
      }
    }
  },
  created() {
    this.$store.dispatch('queue/setup')
  },
  async mounted() {
    window.onresize = () => {
      this.notificationPosition = window.innerWidth < 768 ? 'bottom' : 'top'
    }

    // capture change to visibility
    document.addEventListener('visibilitychange', () => {
      // re-retch date on current route when tab was re-activated
      if (document.visibilityState === 'visible') {
        this.$apolloProvider.defaultClient.restore()
        this.$apolloProvider.defaultClient.reFetchObservableQueries()
        this.$nuxt.refresh()
      }
    })

    await this.$platform.native.notifications.setup() // set up notifications if already allowed
    this.$platform.native.notifications.addListeners()

    if (this.$platform.isNative) {
      const { StatusBar, Style } = require('@capacitor/status-bar')
      StatusBar.setStyle({ style: Style.Dark })
    }

    // TEMP: sync warranty things from local storage
    this.$store.dispatch('warranty/syncProducts')
  }
}
