import { render, staticRenderFns } from "./EditInner.vue?vue&type=template&id=5a1d967f&"
import script from "./EditInner.vue?vue&type=script&lang=js&"
export * from "./EditInner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesFormInput: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/form/Input.vue').default,MoleculesOrganizationFormAddress: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/organization/form/Address.vue').default})
