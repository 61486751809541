var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('molecules-dropdown',{attrs:{"menu-class":"!rounded-none","item-class":"!rounded-none text-xl py-6 border-b !border-b-gray-200 last:border-none","position":_vm.position,"items":_vm.options,"show":_vm.showOptions},on:{"select":_vm.onSelect},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('button',{ref:"button",class:[
          'flex w-full cursor-pointer py-2 px-3 border border-gray-300 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm',
          isOpen && '_tid_tab-select-open',
          !isOpen && '_tid_tab-select-closed'
        ],attrs:{"type":"button","aria-label":"tab-select"},on:{"click":toggle}},[_c('span',{staticClass:"flex w-full items-center truncate"},[_c('span',{staticClass:"truncate"},[_vm._v("\n            "+_vm._s(_vm.$t(_vm.innerValueLabel))+"\n          ")]),_vm._v(" "),_c('span',{staticClass:"flex items-center ml-auto pointer-events-none"},[_c('atoms-icon',{class:[
                'w-5 h-5 text-gray-400 transition-transform',
                isOpen && 'rotate-180'
              ],attrs:{"name":"chevron-down"}})],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }