//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getGoogleMapLink } from '~/lib/geocoder'
import { isObjectWithValue } from '~/lib/utils/object'

export default {
  props: {
    isOfflineThing: { type: Boolean, default: false },
    thing: { type: Object, default: () => {} },
    isLoading: { type: Boolean, default: true },
    contextItems: { type: Array, default: () => [] },
    pendingConfig: { type: Boolean, default: false },
    scrollTop: { type: Number, default: 0 }
  },
  computed: {
    showSkeleton() {
      return this.isLoading
    },
    googleMapLink() {
      if (!isObjectWithValue(this.thing)) return undefined
      const { orgAddress, placeId } = this.thing
      if (!orgAddress || !placeId) return undefined
      return getGoogleMapLink(orgAddress, placeId)
    },
    scrollMargin() {
      const height = document.getElementById('things-header-details')
        ?.offsetHeight
      return this.scrollTop < height ? -1 * this.scrollTop : -1 * height
    },
    scrollOpacity() {
      if (this.scrollTop === 0) return 1
      const height = document.getElementById('things-header-details')
        ?.offsetHeight
      if (this.scrollTop < height) return 1 - this.scrollTop / height
      return 0
    }
  },
  methods: {
    onContextSelect(item) {
      this.$emit('contextSelect', item)
    }
  }
}
