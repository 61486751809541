//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    thing: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  }
}
