//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { allowsTo } from '~/lib/allowsTo'

export default {
  props: {
    navigation: { type: Object, required: true },
    username: { type: String, default: null },
    email: { type: String, default: null },
    thingAlertCount: { type: Number, default: 0 },
    thingAlertColor: { type: String, default: '' },
    inboxAlertCount: { type: Number, default: 0 }
  },
  computed: {
    mainNavigation() {
      return this.filterNavigation(this.navigation.main)
    },
    subNavigation() {
      return this.filterNavigation(this.navigation.sub)
    },
    userNavigation() {
      return this.navigation.user.map(this.filterNavigation)
    }
  },
  methods: {
    getBadgeCount(key) {
      if (key === 'inbox' && this.inboxAlertCount > 0)
        return this.inboxAlertCount
    },
    filterNavigation(nav) {
      const userCapabilities = this.$store.getters['user/capabilities']
      return nav.filter((item) => allowsTo(item, userCapabilities))
    }
  }
}
