//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { throttle } from 'lodash-es'
import { Gauge } from '@chrisheanan/vue-gauge'
import FormInputMixin from '~/mixins/FormInputMixin'

export default {
  name: 'MoleculesFormRange',
  components: {
    Gauge
  },
  mixins: [FormInputMixin],
  inheritAttrs: false,
  props: {
    value: { type: Number, required: true },
    min: { type: Number, required: true },
    max: { type: Number, required: true },
    step: { type: Number, default: 1 },
    unit: { type: String, default: 'Bar' }
  },
  data() {
    return {
      val: this.value
    }
  },
  computed: {
    innerValue() {
      return this.$parentForm?.formData[this.innerName]
    }
  },
  watch: {
    innerValue(val) {
      this.val = parseFloat(val)
    }
  },
  methods: {
    format(value) {
      return this.unit ? `${value} ${this.unit}` : value
    },
    getAngle(e) {
      if (e.target.tagName !== 'path') {
        return
      }
      const rect = e.target.getBoundingClientRect()
      const size = {
        w: rect.width,
        h: rect.height
      }

      function nearest(value, min, max, step, percent) {
        const mode = percent <= 0.5 ? 'floor' : 'ceil'
        const steps = (max - min) / step
        const zerone = Math[mode](((value - min) * steps) / (max - min)) / steps // bring to 0-1 range
        return zerone * (max - min) + min
      }

      const dx = size.w / 2 - e.offsetX
      const dy = e.offsetY - size.h
      const percent = Math.abs(Math.atan2(dy, dx) / Math.PI)

      const rough = percent * (this.max - this.min) + this.min
      const val = nearest(rough, this.min, this.max, this.step, percent)
      this.val = val

      this.handleInput(this.val, 'input')
    },
    onMouseWheel: throttle(
      function (e) {
        if (e.deltaY >= 1) {
          this.val -= this.step
        } else if (e.deltaY <= -1) {
          this.val += this.step
        }

        if (this.val > this.max) this.val = this.max
        if (this.val < this.min) this.val = this.min

        this.handleInput(this.val, 'input')
      },
      0,
      { leading: true, maxWait: 100 }
    ),
    onInput() {}
  }
}
