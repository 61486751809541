//
//
//
//
//
//
//
//

export default {
  name: 'OrganismsParamsGroupToggle',
  inject: {
    $parentForm: {
      default: null
    }
  },
  props: {
    title: { type: String, default: '' },
    toggleElementName: { type: String, required: true },
    childNames: { type: Array, required: true }
  },
  mounted() {
    this.$on('toggle', this.onToggle.bind(this))
  },
  destroyed() {
    this.$off('toggle')
  },
  methods: {
    onToggle(isOn) {
      if (isOn) {
        this.resetValuesByName(this.filterChildNames(this.toggleElementName))
      }
    },
    resetValuesByName(names) {
      names.forEach((name) => {
        const defaultValue = this.$parentForm.dynamicDefaults[name]
        this.$parentForm.updateField(name, defaultValue)
      })
    },
    filterChildNames(name) {
      return this.childNames.filter((childName) => childName !== name)
    }
  }
}
