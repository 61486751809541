var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_tid_category transition-all p-4",class:[
    _vm.active
      ? 'bg-white border-gray-200 border-b border-t sm:border sm:py-4 sm:px-5 sm:-mx-4 my-1'
      : 'border sm:rounded border-transparent hover:bg-gray-50 hover:border-gray-200 sm:py-2 sm:px-3 sm:-mx-2'
  ],attrs:{"id":_vm.id}},[_c('div',{class:[
      'cursor-pointer select-none flex justify-between items-center',
      _vm.active ? 'py-4 px-5 -my-3 -mx-5' : 'py-3 px-3 -my-3 -mx-3'
    ],on:{"click":function($event){return _vm.$emit('toggle', _vm.id)}}},[_c('div',{staticClass:"flex space-x-2",class:_vm.showSkeleton && 'py-1'},[(_vm.showSkeleton)?_c('atoms-skeleton',{attrs:{"index":_vm.index,"show":_vm.showSkeleton}},[_c('div',{staticClass:"w-5 h-5"})]):_vm._e(),_vm._v(" "),_c('atoms-skeleton',{attrs:{"index":_vm.index,"min":3,"max":8,"show":_vm.showSkeleton}},[(_vm.showSkeleton)?_c('span',[_vm._v(" Loading ")]):_c('molecules-heading',{attrs:{"tag":"h4","title":_vm.title,"icon":_vm.icon(_vm.id),"classes":_vm.active ? 'text-primary-500' : 'text-gray-700'}})],1)],1),_vm._v(" "),(!_vm.showSkeleton)?_c('atoms-icon',{class:[
        '_tid_category-icon w-4 h-4 text-gray-500 mr-2 transition-transform',
        _vm.active && 'rotate-180'
      ],attrs:{"name":"chevron-down"}}):_vm._e()],1),_vm._v(" "),(_vm.active)?_c('molecules-transition-list',{staticClass:"space-y-4 pt-6"},_vm._l((_vm.docs),function(document){return _c('molecules-documents-preview',{key:document.id,attrs:{"document":document,"product":_vm.product}})}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }