import { LegalType } from '~/models/legal/LegalType'

export class LegalDocumentId {
  constructor(
    public readonly type: LegalType,
    public readonly version: string,
    public readonly locale: string
  ) {}

  public is(id: LegalDocumentId) {
    return (
      this.hasType(id.type) &&
      id.version === this.version &&
      id.locale === this.locale
    )
  }

  public hasType(type: LegalType) {
    return type === this.type
  }
}
