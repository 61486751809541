//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import openUrl from '~/lib/url/openUrl'
import { Favorite } from '~/models/document/Favorite'
import { Download } from '~/models/document/Download'

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    document: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isDownloading: false
    }
  },
  computed: {
    isServiceApp() {
      return false
    },
    isDownloaded() {
      return this.$store.getters['documents/downloads/isDownloaded'](
        this.download
      )
    },
    isBookmarked() {
      return this.$store.getters['documents/favorites/isBookmarked'](
        this.favorite
      )
    },
    download() {
      return new Download(this.document.fileName)
    },
    favorite() {
      return new Favorite(this.product.ref, this.document)
    }
  },
  methods: {
    async clickAction() {
      if (this.document.isStream) return this.openStream()
      if (!this.$platform.isNative) return this.openExternalLink()
      if (this.isDownloaded) return this.openDownloadedFile()
      if (this.document.isDownloadable) {
        await this.onDownload()
        this.openDownloadedFile()
      }
    },
    async onDownload() {
      this.isDownloading = true
      await this.$store.dispatch('documents/downloads/download', this.document)
      this.isDownloading = false
    },
    async onBookmark() {
      const payload = { product: this.product, favorite: this.favorite }
      await this.$store.dispatch('documents/favorites/toggle', payload)

      if (!this.isBookmarked) {
        this.onRemoveBookmark(this.document.id)
      }
    },
    onRemoveBookmark(id) {
      const payload = {
        id,
        type: 'info',
        message: this.$t('favourites.remove'),
        accept: {
          label: this.$t('buttons.undo'),
          callback: () => this.onBookmark()
        },
        dismiss: false,
        timeout: 10000
      }
      this.$store.dispatch('notify/dialog', payload)
    },
    openDownloadedFile() {
      this.$store.dispatch('documents/downloads/open', this.document)
    },
    removeDownloadedFile() {
      this.$store.dispatch('documents/downloads/remove', this.download)
    },
    openExternalLink() {
      window.open(this.document.url, '_blank')
    },
    openStream() {
      const ref = openUrl(this.document.url, '_blank', {
        toolbarcolor: '#ffffff',
        closebuttoncolor: '#475569',
        hidenavigationbuttons: 'yes',
        location: 'no',
        footer: 'no',
        hideurlbar: 'yes',
        toolbartranslucent: 'yes',
        hardwareback: 'no'
      })
      if (this.$platform.isNative) {
        window.screen.orientation.lock('landscape')
        ref.addEventListener('exit', () => {
          window.screen.orientation.lock('portrait')
          window.screen.orientation.unlock()
        })
      }
    }
  }
}
