var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed z-50 inset-0 overflow-y-auto mt-safe",attrs:{"aria-labelledby":"modal-title","role":"dialog","aria-modal":"true"}},[_c('div',{class:[
      'flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center',
      _vm.size === 'l' ? 'lg:block lg:p-0 !pb-20' : 'sm:block sm:p-0'
    ]},[_c('transition',{attrs:{"appear":"","enter-class":"ease-out duration-300","enter-active-class":"opacity-0","enter-to-class":"opacity-100","leave-class":"ease-in duration-200","leave-active-class":"opacity-100","leave-to-class":"opacity-0"}},[_c('div',{staticClass:"fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity",attrs:{"aria-hidden":"true"}})]),_vm._v(" "),(_vm.size !== 'l')?_c('span',{staticClass:"hidden sm:inline-block sm:align-middle sm:h-screen",attrs:{"aria-hidden":"true"}},[_vm._v("​")]):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"appear":"","enter-class":"ease-out duration-300","enter-active-class":"opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95","enter-to-class":"opacity-100 translate-y-0 sm:scale-100","leave-class":"ease-in duration-200","leave-active-class":"opacity-100 translate-y-0 sm:scale-100","leave-to-class":"opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"}},[_c('div',{class:[
          'modal-content',
          'inline-block align-bottom bg-white rounded-lg px-6 pt-3 text-left overflow-hidden shadow-xl transform transition-all',
          'mt-24 sm:align-middle sm:w-full p-6',
          _vm.cssClass
        ]},[_c('div',[_c('div',{staticClass:"mt-3"},[_vm._t("default",[(!!_vm.title || _vm.showCloseButton)?_c('div',{staticClass:"flex justify-between"},[_vm._t("title",[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900",attrs:{"id":"modal-title"}},[_vm._v("\n                    "+_vm._s(_vm.title)+"\n                  ")])],{"title":_vm.title}),_vm._v(" "),(_vm.showCloseButton)?_c('molecules-button',{staticClass:"hover:bg-transparent hover:border-transparent absolute right-1 top-4",attrs:{"icon":"x"},on:{"click":_vm.close}}):_vm._e()],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mt-2 sm:mt-5 text-sm"},[_vm._t("content",[_vm._v("\n                  "+_vm._s(_vm.content)+"\n                ")])],2)])],2)]),_vm._v(" "),_c('div',{staticClass:"mt-5 sm:mt-6"},[_vm._t("actions")],2)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }