import { render, staticRenderFns } from "./ThingsHeader.vue?vue&type=template&id=41cc036a&"
import script from "./ThingsHeader.vue?vue&type=script&lang=js&"
export * from "./ThingsHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesThingsState: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/things/State.vue').default,AtomsSkeleton: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Skeleton.vue').default,AtomsBadge: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Badge.vue').default,MoleculesHeading: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Heading.vue').default,AtomsIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Icon.vue').default,MoleculesButton: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Button.vue').default,MoleculesDropdown: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Dropdown.vue').default,MoleculesIconText: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/IconText.vue').default})
