import { AxiosInstance } from 'axios'
import { Carrier } from '~/carrier/Carrier'
import { LegalDocument } from '~/models/legal/LegalDocument'
import { IGetLegalDocumentDto } from '~/carrier/legal/input/IGetLegalDocumentDto'
import { LegalDocumentId } from '~/models/legal/LegalDocumentId'

export class LegalCarrier extends Carrier {
  constructor(api: AxiosInstance) {
    super(api, 'legal')
  }

  public async get(id: LegalDocumentId): Promise<LegalDocument> {
    const { type, version, locale } = id
    const url = `${this.path}/${type}/${version}/${locale}`
    const { data = {} } = await this.api.get<IGetLegalDocumentDto>(url)

    return LegalDocument.fromJson(id, data)
  }
}
