//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { maintenanceDue } from '~/models/thing/State'

export default {
  props: {
    things: { type: Array, default: () => [], required: true }
  },
  computed: {
    maintennceDue() {
      return maintenanceDue
    }
  }
}
