//
//
//

export default {
  name: 'MoleculesFormFile',
  inheritAttrs: false,
  props: {
    // Value will be defined as prop and not provided to the input because otherwise the Vue runtime will
    // throw an error "This input element accepts a filename, which may only be programmatically set to
    // the empty string."
    // Actually it is not necessary to provide a "state" this will be handled by the browser.
    value: {
      type: [Array, FileList],
      required: true
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        const isFileList = value instanceof FileList

        if (this.$el && isFileList) {
          this.$el.files = value
          return
        }

        if (this.$el) {
          this.$el.value = null
        }
      }
    }
  }
}
