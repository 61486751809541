//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Protocol } from '~/models/organization/subscription/Protocol'
import Validations from '~/config/validations'
import { Subscription } from '~/models/organization/subscription/Subscription'

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    organizationId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    formData: { email: '', webhook: '', protocol: Protocol.Email }
  }),
  computed: {
    isServicePartner() {
      return this.$store.getters['user/isServicePartner']
    },
    isRoot() {
      return this.$store.getters['organization/isRoot'](this.organizationId)
    },
    protocols() {
      const data = [
        {
          id: Protocol.Email,
          value: Protocol.Email,
          label: this.$t(`settings.subscription.protocol.${Protocol.Email}`)
        }
      ]
      if (this.isServicePartner) {
        data.push({
          id: Protocol.Webhook,
          value: Protocol.Webhook,
          label: this.$t(`settings.subscription.protocol.${Protocol.Webhook}`)
        })
      }
      return data
    },
    rules() {
      const definitions = {
        email: { required: this.formData.protocol === Protocol.Email },
        url: {
          required: this.formData.protocol === Protocol.Webhook,
          url: { required_protocol: true }
        },
        protocol: { required: true }
      }
      const { protocol, url, email } = Validations.buildValidationRules(
        this.$i18n.locale,
        definitions
      )
      return { protocol, url, email }
    }
  },
  methods: {
    onChangeProtocol(e) {
      this.formData.protocol = e.target.value
    },
    submit() {
      this.$refs.form.handleSubmit()
    },
    onSubmit({ data, validation }) {
      if (!validation.$invalid) {
        const subscription = new Subscription(
          '',
          this.isEmail(data.protocol) ? data.email : data.url,
          data.protocol,
          true,
          this.organizationId
        )
        this.$emit('create', subscription)
      }
    },
    isEmail(protocol) {
      return protocol === Protocol.Email
    },
    isWebhook(protocol) {
      return protocol === Protocol.Webhook
    }
  }
}
