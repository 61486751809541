//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isEmpty } from 'lodash-es'
export default {
  props: {
    organization: {
      type: Object,
      required: true
    },
    organizationById: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({ isEditAddressModeEnabled: false, transition: 'none' }),
  watch: {
    organization() {
      if (!isEmpty(this.organization.address))
        this.isEditAddressModeEnabled = true
    }
  },
  mounted() {
    if (!isEmpty(this.organizationById.address)) {
      this.isEditAddressModeEnabled = true
    }
  },
  methods: {
    onClick() {
      this.transition = 'fade'
      this.isEditAddressModeEnabled = true
      setTimeout(() => (this.transition = 'none'), 50)
    }
  }
}
