import { render, staticRenderFns } from "./Form.vue?vue&type=template&id=e3baa2b6&"
import script from "./Form.vue?vue&type=script&lang=js&"
export * from "./Form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesProductScanner: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/ProductScanner.vue').default,MoleculesFormInput: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/form/Input.vue').default,AtomsIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Icon.vue').default,MoleculesButton: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Button.vue').default,MoleculesFormWide: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/FormWide.vue').default,MoleculesForm: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Form.vue').default})
