import { render, staticRenderFns } from "./PrioServiceModal.vue?vue&type=template&id=d258d01e&"
import script from "./PrioServiceModal.vue?vue&type=script&lang=js&"
export * from "./PrioServiceModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesPrioServiceModal: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/prio-service/Modal.vue').default})
