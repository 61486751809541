import typeCheck from '../../lib/typeCheck'

const typeMap = `{
    zoom: Number,
    search: Maybe String,
    center: {
      lat: Number,
      lng: Number,
      ...
    },
    ...
  }`

export function resetToken(state) {
  state.token = ''
}

export function map(state, map) {
  typeCheck(map, `Undefined | ${typeMap}`)
  state.map = map
}

export function eula(state, eula) {
  state.eula = eula
}

export function login(state, token) {
  state.token = token
}

export function animationDone(state) {
  state.showAnimation = false
}

export function setLoadingSources(state, newState) {
  state.loadingSources = !!newState
}

export function rorg(state, rorg) {
  state.rorg = rorg
}

export function role(state, role) {
  state.role = role
}

export function rootOrgType(state, rootOrgType) {
  state.rootOrgType = rootOrgType
}

export function accessType(state, accessType) {
  state.accessType = accessType
}

export function setProfile(state, data) {
  state.id = data.id
  state.email = data.email
  state.firstName = data.firstName
  state.lastName = data.lastName
  state.name = data.name
}
