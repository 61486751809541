//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: { type: String, default: null },
    icon: { type: String, default: null },
    description: { type: String, default: null }
  }
}
