//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    activeId: { type: String, default: undefined },
    listComponent: { type: String, default: undefined },
    emptyText: { type: String, default: undefined },
    title: { type: String, required: true },
    icon: { type: String, default: undefined },
    loading: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
    hasInfiniteScroll: { type: Boolean, default: false },
    hasMore: { type: Boolean, default: false }
  },
  data() {
    return {
      backRoute: this.$route.name
    }
  }
}
