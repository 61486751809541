//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isObjectWithValue } from '~/lib/utils/object'
import { InboxMessage } from '~/models/inbox/Message'

export default {
  props: {
    item: { type: Object, required: true },
    index: { type: Number, default: undefined },
    active: { type: Boolean, default: false },
    to: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    showSkeleton() {
      return !isObjectWithValue(this.item)
    },
    message() {
      return !this.showSkeleton && InboxMessage.fromObject(this.item)
    },
    isUnread() {
      return !this.message.read && !this.active
    },
    orgName() {
      return this.message.orgName || this.message.inviterOrgName
    }
  }
}
