//
//
//
//
//
//
//
//
//
//
//
//
//

import { State } from '~/models/thing/State'

const colorMapping = {
  green: 'text-green-600',
  yellow: 'text-yellow-600',
  red: 'text-red-600',
  gray: 'text-gray-400'
}

export default {
  props: {
    state: {
      type: String,
      default: () => State.Default,
      validator(state) {
        return Object.keys(colorMapping).includes(state)
      }
    }
  },
  computed: {
    icon() {
      return `svg/states/${this.state}`
    },
    color() {
      return colorMapping[this.state]
    }
  }
}
