//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: { type: String, required: true },
    description: { type: String, default: undefined },
    dense: { type: Boolean, default: false },
    superDense: { type: Boolean, default: false },
    wrapped: { type: Boolean, default: false }
  }
}
