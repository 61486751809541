//
//
//
//
//
//

export default {
  name: 'OrganismsParamsPressureDelta',
  inject: {
    $parentForm: {
      default: null
    }
  },
  computed: {
    from() {
      return this.$parentForm?.formData['settings.comprpressure.setl'] ?? 0
    },
    to() {
      return this.$parentForm?.formData['settings.comprpressure.seth'] ?? 0
    },
    dirtyFrom() {
      return (
        this.$parentForm?.$v.formData['settings.comprpressure.setl']?.$dirty ??
        false
      )
    },
    dirtyTo() {
      return (
        this.$parentForm?.$v.formData['settings.comprpressure.seth']?.$dirty ??
        false
      )
    }
  },
  watch: {
    from() {
      this.onChange()
    },
    to() {
      this.onChange()
    }
  },
  methods: {
    onChange() {
      this.$slots.default.forEach((vnode) => {
        if (vnode.tag.indexOf('MoleculesFormInput') <= -1) return
        if (vnode.data.props.name.includes('.setl')) {
          // disable if other field is dirty
          vnode.data.props.disabled = this.dirtyTo
        } else if (vnode.data.props.name.includes('.seth')) {
          // disable if other field is dirty
          vnode.data.props.disabled = this.dirtyFrom
        }
      })
    }
  }
}
