import { render, staticRenderFns } from "./Documents.vue?vue&type=template&id=6246e7cc&"
import script from "./Documents.vue?vue&type=script&lang=js&"
export * from "./Documents.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesDocumentsCategory: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/documents/Category.vue').default,MoleculesSkeletonList: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/SkeletonList.vue').default,MoleculesEmptyState: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/EmptyState.vue').default})
