//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const colorConfig = {
  red: { ping: 'bg-red-700', badge: 'bg-red-800' },
  yellow: { ping: 'bg-yellow-300', badge: 'bg-yellow-400' },
  primary: { ping: 'bg-primary-700', badge: 'bg-primary-800' }
}

export default {
  props: {
    show: { type: Boolean, default: false },
    color: { type: String, default: 'primary' }
  },
  computed: {
    pulseColors() {
      return colorConfig[this.color]
    }
  }
}
