//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Validations from '~/config/validations'

export default {
  props: {
    markFormError: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    formData: {
      confirm: false
    }
  }),
  computed: {
    rules() {
      const definitions = {
        confirm: { required: true, checked: (value) => value === true }
      }

      const { confirm } = Validations.buildValidationRules(
        this.$i18n.locale,
        definitions
      )

      return { confirm }
    }
  }
}
