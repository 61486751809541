//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormInputMixin from '~/mixins/FormInputMixin'

export default {
  name: 'MoleculesFormToggle',
  mixins: [FormInputMixin],
  inheritAttrs: false,
  props: {
    id: { type: String, default: undefined },
    value: { type: Boolean, default: false },
    alert: { type: Object, default: () => {} },
    ariaLabel: { type: String, default: undefined }
  },
  computed: {
    innerValue() {
      if (this.$parentForm) {
        return this.castValue(this.$parentForm.formData[this.name])
      }
      return this.castValue(this.value)
    }
  },
  methods: {
    castValue(value) {
      if ([null, undefined].includes(value)) {
        return false
      }
      return [1, '1', true, 'true'].includes(value)
    },
    onInput(value) {
      this.handleInput(value, 'input')
      // TODO: We have to find a better way to notify the group toggle,
      //   The toggle element should not know that the parent is interested
      //   in observing the event, instead it should only emit the toggle
      //   event.
      this.$parent.$emit('toggle', value)
    }
  }
}
