//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const config = {
  base: {
    primary: 'border-transparent',
    secondary:
      'bg-white border-gray-300 hover:bg-gray-50 group-hover:bg-gray-50',
    tertiary: 'bg-transparent border-transparent shadow-none'
  },
  colors: {
    gray: {
      primary:
        'bg-gray-300 hover:border-gray-400 group-hover:border-gray-400 text-gray-800',
      secondary: 'text-gray-600',
      tertiary:
        'text-gray-700 hover:text-gray-800 group-hover:text-gray-800 hover:border-gray-200 group-hover:border-gray-200 hover:bg-white group-hover:bg-white'
    },
    red: {
      primary: 'bg-red-800 hover:bg-red-700 group-hover:bg-red-700 text-white',
      secondary: 'text-red-800 hover:text-red-700 group-hover:text-red-700',
      tertiary: 'text-red-800 hover:text-red-600 group-hover:text-red-600'
    },
    green: {
      primary:
        'bg-green-700 hover:bg-green-600 group-hover:bg-green-600 text-white',
      secondary:
        'text-green-700 hover:text-green-600 group-hover:text-green-600',
      tertiary: 'text-green-800 hover:text-green-600 group-hover:text-green-600'
    },
    blue: {
      primary:
        'bg-primary-500 hover:bg-primary-600 group-hover:bg-primary-600 text-white',
      secondary:
        'text-primary-500 hover:text-primary-400 group-hover:text-primary-400',
      tertiary:
        'text-primary-600 hover:text-primary-400 group-hover:text-primary-400'
    },
    white: {
      primary:
        'bg-white shadow-none border-transparent hover:bg-gray-100 group-hover:bg-gray-100 hover:border-gray-200 group-hover:border-gray-200 text-gray-800 hover:text-gray-900 group-hover:text-gray-900',
      secondary:
        'text-primary-500 hover:text-primary-400 group-hover:text-primary-400',
      tertiary: 'text-gray-200 hover:text-gray-50 group-hover:text-gray-50'
    }
  }
}
export default {
  props: {
    id: {
      type: String,
      default: undefined
    },
    /**
     * @param {('gray'|'blue'|'green'|'red'|'white')}
     */
    color: {
      type: String,
      default: 'gray',
      validator: (val) =>
        ['gray', 'blue', 'green', 'red', 'white'].includes(val)
    },
    primary: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    ariaLabel: {
      type: String,
      default: undefined
    }
  },
  computed: {
    colorClass() {
      const primary = this.primary
      const secondary = !primary && this.secondary
      const color = this.color

      let mode = 'tertiary'
      if (primary) {
        mode = 'primary'
      } else if (secondary) {
        mode = 'secondary'
      }

      const colorClass = [config.base[mode], config.colors[color][mode]]
      return colorClass.join(' ')
    }
  }
}
