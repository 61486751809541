//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    organizationId: {
      type: String,
      required: true
    },
    members: {
      type: Array,
      default: () => []
    }
  }
}
