//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isPast } from 'date-fns'
import { getLocalizedText } from '~/lib/utils/localization'
import Scroll2ViewMixin from '~/mixins/Scroll2ViewMixin'

export default {
  mixins: [Scroll2ViewMixin],
  props: {
    index: { type: Number, required: true },
    title: { type: Object, default: () => {} },
    intervals: {
      type: Object,
      default: () => ({
        device: null,
        date: null
      })
    },
    id: { type: String, default: '' },
    steps: { type: Array, default: () => [] },
    active: { type: Boolean, default: false },
    hasConfirm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showParts: false,
      isToggled: false,
      deviceCategories: [],
      parts: [],
      partsId: `${this.id}-parts`
    }
  },
  computed: {
    showSkeleton() {
      return this.steps?.length <= 0
    },
    isActive() {
      return this.active || (this.isDue && !this.isToggled)
    },
    isAdmin() {
      return this.$store.getters['user/isAdmin']
    },
    isTechnician() {
      return this.$store.getters['user/isTechnician']
    },
    isConfirmable() {
      const configNameList = this.intervals?.device?.configuration_name_list
      const hasConfig = configNameList ? configNameList.length > 0 : false
      return (
        (this.isTechnician || this.isAdmin) &&
        (this.intervals?.date || hasConfig) &&
        this.hasConfirm
      )
    },
    isDueDate() {
      const { due } = this.intervals.date || {}
      return due !== undefined && isPast(Date.parse(due))
    },
    isDueDevice() {
      const { due } = this.intervals.device || {}
      return due !== undefined && parseInt(due) <= 0
    },
    isDue() {
      return !!this.isDueDate || !!this.isDueDevice
    },
    due() {
      if (this.$i18n.locale) {
        // this is called to update on locale change
      }
      const { due: dueDate } = this.intervals.date || {}
      const { due: dueDevice, unit } = this.intervals.device || {}
      if (!dueDate && !dueDevice) return null

      const dueDateText = dueDate && this.$filters.dateDiff(Date.parse(dueDate))
      const dueDeviceText = dueDevice && this.getDueDeviceText(dueDevice, unit)

      // only one interval exists
      if (!dueDevice) return this.$t('maintenance.due_date', { dueDateText })
      if (!dueDate) return this.$t('maintenance.due_device', { dueDeviceText })

      // only one interval is due
      if (this.isDueDate && !this.isDueDevice)
        return this.$t('maintenance.due_date', { dueDateText })
      if (this.isDueDevice && !this.isDueDate)
        return this.$t('maintenance.due_device', { dueDeviceText })

      return this.$t('maintenance.due_date_device', {
        dueDateText,
        dueDeviceText
      })
    },
    showDue() {
      return this.hasConfirm && !!this.due
    }
  },
  watch: {
    steps: {
      handler() {
        if (this.steps.length > 0) {
          if (this.steps[0].device_category) {
            // get a list of unqiue device categories
            this.deviceCategories = this.steps.reduce((acc, step) => {
              if (step.device_category && !acc.includes(step.device_category)) {
                acc = [...acc, step.device_category]
              }
              return acc
            }, [])
          }
          // get a list of numbered parts
          this.parts = this.steps.reduce((acc, step) => {
            if (step.parts) {
              const numberedParts = step.parts.map((p, index) => ({
                number: acc.length + index + 1, // accumulative number
                ...p
              }))
              step.parts = numberedParts // update prop data
              acc = acc.concat(numberedParts)
            }
            return acc
          }, [])
        }
      },
      immediate: true
    },
    active() {
      // close parts list when active is toggled
      if (!this.active) {
        this.showParts = false
      }
    }
  },
  methods: {
    onToggle() {
      this.$emit('toggle', this.index)
      this.isToggled = true
    },
    onToggleParts() {
      this.showParts = !this.showParts
      if (!this.showParts) return
      this.scroll2view(this.partsId, { block: 'end' })
    },
    getCategorizedSteps(deviceCategory) {
      return this.steps.filter(
        (step) => step.device_category === deviceCategory
      )
    },
    getDueDeviceText(dueDevice, unit) {
      if (unit === 'hour') {
        return (
          this.$filters.runtime(dueDevice, unit) +
          ' ' +
          this.$t('_api.dashboard.stat.thing.runtime')
        )
      }
      return `${dueDevice} ${unit}`
    },
    getLocalizedText(textObj) {
      return getLocalizedText(textObj, this.$i18n.locale)
    },
    getPartNumbers(parts) {
      return parts.reduce((acc, part) => acc.concat(`(${part.number})`), '')
    },
    icon(categoryId) {
      if (categoryId && iconMap[categoryId]) {
        return iconMap[categoryId]
      }
      return iconMap['no-category']
    }
  }
}
