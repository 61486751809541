//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    item: { type: Object, required: true },
    isolated: { type: Boolean, default: false },
    badgeCount: { type: Number, default: 0 }
  }
}
