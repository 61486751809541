import { render, staticRenderFns } from "./Disposal.vue?vue&type=template&id=88b3f71a&"
import script from "./Disposal.vue?vue&type=script&lang=js&"
export * from "./Disposal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Icon.vue').default,AtomsBadge: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Badge.vue').default,MoleculesFormInput: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/form/Input.vue').default,MoleculesFormToggle: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/form/Toggle.vue').default,MoleculesButton: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Button.vue').default,MoleculesCard: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Card.vue').default,MoleculesForm: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Form.vue').default,MoleculesFormWide: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/FormWide.vue').default})
