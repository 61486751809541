//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import paginate from 'jw-paginate'

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    initialPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    maxPages: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      pager: {}
    }
  },
  watch: {
    items() {
      this.setPage(this.initialPage)
    }
  },
  created() {
    // set page if items array isn't empty
    if (this.items && this.items.length) {
      this.setPage(this.initialPage)
    }
  },
  methods: {
    transformPages(pages, totalPages) {
      const ellipsis = '…'

      // no change needed
      if (!pages || !pages.length || !totalPages || totalPages < this.maxPages)
        return pages

      // set first page
      if (pages[0] !== 1) {
        pages[0] = 1
        pages.splice(1, 0, ellipsis)
      }

      // set last page
      const lastIndex = pages.length - 1
      if (pages[lastIndex] !== totalPages) {
        pages[lastIndex] = totalPages
        pages.splice(lastIndex, 0, ellipsis)
      }

      return pages
    },
    setPage(page) {
      const { items, pageSize, maxPages, transformPages } = this

      // get new pager object for specified page
      const pager = paginate(items.length, page, pageSize, maxPages)

      // get new page of items from items array
      const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1)

      // update pager
      this.pager = {
        ...pager,
        pages: transformPages(pager.pages, pager.totalPages)
      }

      // emit change page event to parent component
      this.$emit('changePage', pageOfItems)
    }
  }
}
