//
//
//
//
//
//

import { forIn, isNull, isUndefined, unset } from 'lodash-es'
import { validationMixin } from 'vuelidate'
import {
  required,
  decimal,
  minValue,
  maxValue,
  helpers
} from 'vuelidate/lib/validators'
import { calcValue } from '~/lib/utils/number'

export default {
  mixins: [validationMixin],
  provide() {
    // TODO: We should avoid making the whole component public available.
    return {
      $parentForm: this
    }
  },
  props: {
    /**
     * The value of the form. Can be passed via v-model.
     */
    value: {
      type: Object,
      required: true
    },
    /**
     * Vuelidate instance
     */
    rules: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formData: {},
      isLoading: false,
      dynamicRules: {},
      dynamicDefaults: {},
      // list of fields that should be send on save
      // if empty all will besend
      // usage: $parentForm.limitFields(['settings.comprpressure.seth'])
      fieldsToSend: null
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        Object.keys(value).forEach((key) => {
          // FIXME: If default values are set, the form will not work,
          //   because a primitive value will be set, but the code
          //   expects a vuelidate value, as you can see in Line 112:
          //     this.$v.formData[key].$touch()
          this.$set(this.formData, key, this.getValue(value[key]))
        })
        this.$v.$reset()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$v.$reset()
    })
  },
  validations() {
    return {
      formData: { ...this.dynamicRules, ...this.rules }
    }
  },
  methods: {
    // register dynamically created form inputs
    registerField(key, data, readOnly) {
      if (Object.keys(this.formData).includes(key) === false) {
        // remove timezone from date field
        if (key === 'settings.time.setrtc' && data.value.includes('+')) {
          const newValue = data.value.split('+')
          data.value = newValue[0]
        }
        this.$set(this.formData, key, data.value)
        this.$set(this.dynamicDefaults, key, data.value)
        const rules = {}
        if (!readOnly) {
          if (data.required) rules.required = required

          const dateTimeNoOffset = (value) =>
            !helpers.req(value) || !value.includes('+')
          if (key === 'settings.time.setrtc')
            rules.dateTimeNoOffset = dateTimeNoOffset

          const isNumberOrRange = (type) => ['number', 'range'].includes(type)
          if (isNumberOrRange(data.type)) rules.decimal = decimal
          if (isNumberOrRange(data.type) && data.min !== undefined)
            rules.minValue = minValue(data.min)
          if (isNumberOrRange(data.type) && data.max !== undefined)
            rules.maxValue = maxValue(data.max)

          const stepValue = (step, min, max) => (value) =>
            !helpers.req(value) ||
            Math.round(calcValue(value, min, max) * 100) % (step * 100) === 0
          if (isNumberOrRange(data.type) && data.step) {
            rules.stepValue = stepValue(data.step, data.min, data.max)
          }
        }
        this.$set(this.dynamicRules, key, rules)
        this.$v.$reset()
      }
    },
    updateField(key, value) {
      // TODO: We have to refactor this implementation, but since
      //   this can cause a lot of side effects, we should create
      //   an issue for that.
      const isDefaultValue = value === this.dynamicDefaults[key]
      let val = value
      if (typeof value === 'string' && value.trim() === '') {
        val = null
      }

      if (isDefaultValue) {
        // The form data value has to be reset before
        //   val === this.formData[key]
        // because of this scenario:
        //    default value: ''
        //    form data value: null
        //    new value: ''
        this.$v.formData[key].$reset()
      }

      if (val === this.formData[key]) {
        return
      }

      this.$set(this.formData, key, val)
      this.$emit('input', { ...this.formData })

      if (!isDefaultValue) {
        this.$v.formData[key].$touch()
      }

      this.$emit('change', key, val)
    },
    handleSubmit() {
      // TODO: Add is 'no changes' and error message as default form behaviour
      this.isLoading = true
      if (this.$v.$invalid || this.$v.$anyDirty) {
        this.$v.$touch()
      }
      let data = { ...this.formData }
      if (this.fieldsToSend?.length > 0) {
        data = forIn(data, (_val, key) => {
          if (this.fieldsToSend.includes(key)) {
            unset(data, key)
          }
        })
      }
      this.$emit('submit', {
        validation: { ...this.$v },
        data,
        formReset: this.formReset,
        finishLoading: this.finishLoading
      })
    },
    handleValidate() {
      this.$emit('validate', {
        validation: { ...this.$v },
        data: { ...this.formData }
      })
    },
    formReset() {
      this.isLoading = false
      this.$emit('reset', { ...this.dynamicDefaults, ...this.value })
      this.$v.$reset()
    },
    finishLoading() {
      this.isLoading = false
    },
    getValue(value) {
      if (this.isValue(value)) {
        return value
      }

      return null
    },
    isValue(value) {
      return !isNull(value) && !isUndefined(value)
    },
    submit() {
      this.handleSubmit()
    },
    reset() {
      this.formReset()
    },
    limitFields(fields) {
      this.fieldsToSend = fields
    }
  }
}
