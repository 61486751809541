import { render, staticRenderFns } from "./Heading.vue?vue&type=template&id=16f58ed6&"
import script from "./Heading.vue?vue&type=script&lang=js&"
export * from "./Heading.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Icon.vue').default})
