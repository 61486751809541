//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { NewDeviceAddedInboxMessage } from '~/models/inbox/Message'

export default {
  props: {
    message: { type: NewDeviceAddedInboxMessage, required: true }
  },
  computed: {
    showSkeleton() {
      return !this.message
    }
  },
  methods: {
    viewDevice() {
      this.$router.push({
        name: 'things-thingId-dashboard',
        params: { thingId: this.message.thingId }
      })
    }
  }
}
