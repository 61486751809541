import ORGANIZATION_THINGS from '~/queries/things.gql'

export default (cache, thingId) => {
  const query = {
    query: ORGANIZATION_THINGS
  }
  const data = cache.readQuery(query)
  const index = data?.orgData?.things?.findIndex((t) => t.id === thingId)
  if (data && index !== -1) {
    // Mutate cache result
    data?.orgData?.things?.splice(index, 1)
    // Write back to the cache
    cache.writeQuery({
      ...query,
      data
    })
  }
}
