//
//
//
//
//
//
//
//
//
//
//
//
//

import { isNil } from 'lodash-es'

export default {
  name: 'MoleculesFormSelect',
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: () => ''
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedOptions() {
      return this.options
        .map(this.addLabel.bind(this))
        .map(this.addId.bind(this))
        .map(this.addSelected.bind(this))
    }
  },
  methods: {
    addLabel(option) {
      const hasLabel = !isNil(option.label)

      if (hasLabel) {
        return option
      }

      return { ...option, label: option.value }
    },
    addId(option) {
      if (this.hasId(option)) {
        return option
      }

      return { ...option, id: option.value }
    },
    addSelected(option) {
      if (this.isSelected(option)) {
        return { ...option, selected: true }
      }

      return option
    },
    hasId(option) {
      return !isNil(option.id)
    },
    formatLabel(label) {
      return this.$te(label) ? this.$t(label) : label
    },
    isSelected({ value, id, ...option }) {
      if (this.hasId({ value, id, ...option })) {
        return this.value === id
      }

      return this.value === value
    }
  }
}
