//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    createText: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({ isModalVisible: false, isPending: false }),
  watch: {
    isLoading(isLoading) {
      if (isLoading) {
        this.isPending = true
      }

      if (!isLoading && this.isPending) {
        this.isPending = false
        this.isModalVisible = false
      }
    }
  }
}
