var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('atoms-skeleton',{attrs:{"show":_vm.loading === true,"index":_vm.index}},[(_vm.type === 'label')?_c('div',{staticClass:"pt-2 -mb-2 px-1 text-gray-600 font-medium col-span-1 overflow-hidden"},[_vm._v("\n    "+_vm._s(_vm.val)+"\n  ")]):_c('div',{staticClass:"dashboard-card shadow-sm border-gray-300 text-gray-800 col-span-1 rounded !relative !min-h-[80px] overflow-hidden",class:[
      _vm.val === 'N/A' ? 'dashboard-card__na' : '',
      _vm.color ? _vm.levelColor.cardClass : 'border'
    ]},[_c('div',{staticClass:"p-3 flex flex-col w-full h-full justify-between"},[_c('div',{staticClass:"widget-header flex w-full h-full text-xs px-px font-medium justify-between tracking-wider uppercase text-gray-800"},[_c('span',{attrs:{"title":_vm.label}},[_vm._t("default")],2),_vm._v(" "),(_vm.color)?_c('atoms-icon',{class:[
            'w-4 h-4',
            _vm.color && _vm.levelColor.iconClass,
            _vm.color && ("_tid_dashboard-widget-" + (_vm.levelColor.key))
          ],attrs:{"name":_vm.levelColor.icon}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"self-end"},[(_vm.progress)?_c('molecules-dashboard-progress',{staticClass:"ml-2 mb-2",attrs:{"value":_vm.valNormalized}}):_vm._e(),_vm._v(" "),(_vm.icon)?_c('atoms-icon',{staticClass:"ml-px mb-px",attrs:{"name":("svg/dashboard/" + _vm.icon)}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"flex flex-col font-normal relative items-end self-start"},[_c('span',{staticClass:"isolate z-10 flex pl-2 text-right items-baseline"},[_c('span',{staticClass:"font-medium break-words",class:[
                _vm.valueLength > 7 && 'text-xs pt-1.5 sm:text-md sm:pt-1',
                _vm.valueLenBetween(5, 7) && 'text-md pt-px',
                _vm.valueLenBetween(0, 4) && 'text-lg',
                _vm.color && _vm.levelColor.textClass
              ],domProps:{"innerHTML":_vm._s(_vm.valFormatted)}}),_vm._v(" "),_c('small',{class:[_vm.isTemperature ? 'text-lg' : 'text-md', 'ml-1']},[_vm._v("\n              "+_vm._s(_vm.unit)+"\n            ")])]),_vm._v(" "),(_vm.subline)?_c('small',{staticClass:"pl-2 pt-1 opacity-70 uppercase font-light -mt-1 text-right text-[0.6em] 2xl:text-[0.7em] leading-3",domProps:{"innerHTML":_vm._s(_vm._subline)}}):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }