//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isObject } from 'lodash-es'

export default {
  props: {
    tag: { type: String, default: 'li' },
    item: { type: Object, default: () => ({}) },
    index: { type: Number, default: undefined },
    active: { type: Boolean, default: false },
    to: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    showSkeleton() {
      if (!isObject(this.item)) return true

      return Object.keys(this.item).length === 0
    },
    showOrgName() {
      const hasMoreThanOneOrg = this.$store.getters[
        'organization/hasMoreThanOneOrg'
      ]
      return !!this.item.orgName && hasMoreThanOneOrg
    },
    showRef() {
      return !(
        this.$route.name === 'things' || this.$route.name.startsWith('things-')
      )
    },
    showBadges() {
      return (
        this.item?.maintenance_due ||
        this.item?.no_analytics ||
        this.item?.isPending
      )
    },
    hasServicePartner() {
      const isNotServicePartner = !this.$store.getters['user/isServicePartner']
      const isThingAssigned = !!this.item?.servicepartner?.id
      return isNotServicePartner && isThingAssigned
    }
  }
}
