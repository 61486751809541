//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get, isEmpty } from 'lodash-es'
import { toLowerCase } from '~/lib/utils/string'
import { maintenanceDue } from '~/models/thing/State'

export default {
  props: {
    things: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    searchQuery: '',
    selectedOrganizations: [],
    selectedCategories: [],
    selectedStates: []
  }),
  computed: {
    filteredThings() {
      return this.things
        .filter(this.filterBySearchQuery)
        .filter(this.filterBySelectedOrganizations)
        .filter(this.filterBySelectedCategories)
        .filter(this.filterBySelectedStates)
    },
    organizations() {
      const organizations = this.$store.getters['organization/getTree']
      return organizations
    },
    hasFilter() {
      return (
        this.searchQuery !== '' ||
        this.selectedOrganizations.length > 0 ||
        this.selectedCategories.length > 0 ||
        this.selectedStates.length > 0
      )
    }
  },
  watch: {
    things: {
      immediate: true,
      handler() {
        this.update(() => null)
      }
    },
    $route(route) {
      this.$router.push({
        name: route.name,
        params: route.params,
        query: this.createQuery()
      })
    }
  },
  mounted() {
    this.update(this.setFiltersBasedOnQueryParameters.bind(this))
  },
  methods: {
    update(updateFilter) {
      updateFilter()
      this.updateQueryParameters()
      this.$emit('input', this.filteredThings)
    },
    setSearchQuery(event) {
      this.searchQuery = get(event, ['target', 'value'], '')
    },
    setSelectedOrganizations(organizations) {
      this.selectedOrganizations = organizations
    },
    setSelectedCategories(categories) {
      this.selectedCategories = categories
    },
    setSelectedStates(states) {
      this.selectedStates = states
    },
    filterBySearchQuery(thing) {
      const { name, category, model, sn } = thing
      const searchQuery = toLowerCase(this.searchQuery)

      return (
        toLowerCase(name).includes(searchQuery) ||
        toLowerCase(category).includes(searchQuery) ||
        toLowerCase(model).includes(searchQuery) ||
        toLowerCase(sn).includes(searchQuery)
      )
    },
    filterBySelectedOrganizations(thing) {
      if (this.selectedOrganizations.length === 0) {
        return true
      }

      return this.selectedOrganizations.includes(thing.org)
    },
    filterBySelectedCategories(thing) {
      if (this.selectedCategories.length === 0) {
        return true
      }

      return this.selectedCategories.includes(thing.category)
    },
    filterBySelectedStates(thing) {
      if (this.selectedStates.length === 0) {
        return true
      }

      return (
        this.selectedStates.includes(thing.localState) ||
        (this.selectedStates.includes(maintenanceDue) &&
          !!thing.maintenance_due)
      )
    },
    clearFilters() {
      this.searchQuery = ''
      this.selectedOrganizations = []
      this.selectedCategories = []
      this.selectedStates = []
      this.searchQuery = ''
      this.update(() => null)
    },
    setFiltersBasedOnQueryParameters() {
      const {
        searchQuery,
        organizations,
        categories,
        states
      } = this.$route.query

      this.searchQuery = searchQuery || ''
      this.selectedOrganizations = this.parseJsonArray(organizations)
      this.selectedCategories = this.parseJsonArray(categories)
      this.selectedStates = this.parseJsonArray(states)
    },
    updateQueryParameters() {
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: this.createQuery()
      })
    },
    parseJsonArray(jsonString = '[]') {
      try {
        return JSON.parse(jsonString)
      } catch {
        return []
      }
    },
    createQuery() {
      const query = {}

      if (!isEmpty(this.searchQuery)) {
        query.searchQuery = this.searchQuery
      }

      if (this.selectedOrganizations.length > 0) {
        query.organizations = JSON.stringify(this.selectedOrganizations)
      }

      if (this.selectedCategories.length > 0) {
        query.categories = JSON.stringify(this.selectedCategories)
      }

      if (this.selectedStates.length > 0) {
        query.states = JSON.stringify(this.selectedStates)
      }

      return query
    }
  }
}
