//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  computed: {
    path() {
      return this.$store.getters['organization/getPathForOrg'](
        this.organizationId
      )
    }
  },
  methods: {
    getName(organizationId) {
      const organization = this.getOrganization(organizationId)
      return organization.name
    },
    getOrganization(organizationId) {
      return this.$store.getters['organization/byId'](organizationId) || {}
    }
  }
}
