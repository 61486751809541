//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    icon: { type: String, required: true },
    showSkeleton: { type: Boolean, default: true }
  }
}
