//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    tabs: { type: Array, required: true },
    value: { type: [Number, String], default: undefined },
    selectClass: { type: String, default: '' }
  },
  computed: {
    active() {
      return typeof this.value === 'string' ? parseInt(this.value) : this.value
    }
  },
  methods: {
    onChange(value) {
      this.$emit('change', value)
    },
    getTestId(label) {
      if (label.includes('.')) {
        const lastPart = label.split('.').slice(-1)
        return lastPart
      }
      return label
    }
  }
}
