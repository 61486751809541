import { get } from 'lodash-es'
import { FormType } from '~/components/molecules/form/FormType'

const inverseGroupToggleChildren = ['settings.time.ntpaddr']

export default {
  inject: {
    $parentForm: {
      default: null
    }
  },
  props: {
    type: {
      type: String,
      default: 'text',
      validator(val) {
        return [
          FormType.Email,
          FormType.Number,
          FormType.Password,
          FormType.Text,
          FormType.Select,
          FormType.Radio,
          FormType.LazyLoadingSelect,
          FormType.Date,
          FormType.Checkbox,
          FormType.File,
          FormType.Photos,
          FormType.PlacesAutocomplete
        ].includes(val)
      }
    },
    id: { type: String, default: undefined },
    label: { type: String, default: undefined },
    name: { type: String, required: true },
    required: { type: Boolean, default: undefined },
    unit: { type: String, default: undefined },
    placeholder: { type: String, default: undefined },
    meta: { type: String, default: undefined },
    value: { type: [String, Number], default: undefined },
    error: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    toggleBy: { type: String, default: undefined },
    min: { type: [Number, String], default: undefined },
    max: { type: [Number, String], default: undefined },
    step: { type: Number, default: undefined }
  },
  computed: {
    innerId() {
      return this.id || this.innerName
    },
    innerName() {
      return this.name || this.label.replaceAll(/s+/g, '-').toLowerCase()
    },
    innerValue() {
      return Object.keys(this.$parentForm?.formData || {}).includes(
        this.innerName
      )
        ? this.$parentForm?.formData[this.innerName]
        : this.value
    },
    isRequired() {
      if (this.required !== undefined) {
        return this.required
      }
      return (
        this.validationContext?.required ||
        this.validationContext?.$params?.required
      )
    },
    validationContext() {
      return this.$parentForm?.$v?.formData[this.name]
    },
    innerLabel() {
      return this.$t(this.innerLabelTextKey)
    },
    innerLabelTextKey() {
      if (this.label) return this.label
      if (this.meta) return this.meta
      if (this.$te(`_api.${this.innerName}.label`)) {
        return this.$t(`_api.${this.innerName}.label`)
      }
      return `formFields.${this.innerName}.label`
    },
    isDisabled() {
      if (this.readOnly) return true
      if (this.toggleBy && this.toggleBy !== this.name) {
        const value = this.$parentForm?.formData[this.toggleBy]
        const disabled = [true, false].includes(value) && value === true
        return inverseGroupToggleChildren.includes(this.name)
          ? !disabled
          : disabled
      }
      return this.disabled === true
    },
    errors() {
      try {
        if (
          !this.validationContext.$dirty ||
          !this.validationContext.$invalid
        ) {
          return false
        }

        const errors = []
        Object.keys(this.validationContext.$params).forEach((rule) => {
          if (this.validationContext[rule] === false) {
            errors.push(rule)
          }
        })
        if (errors.length <= 0) return false
        return errors
      } catch (err) {
        return false
      }
    },
    innerError() {
      if (this.errors && this.errors.length > 0) {
        let tPath = `formFields.${this.innerName}.errors.${this.errors[0]}`
        if (!this.meta && this.$te(tPath)) {
          return this.$t(tPath)
        }
        if (this.meta) {
          tPath = `${this.meta}_${this.errors[0]}`
          if (this.$te(tPath)) {
            return this.$t(tPath)
          } else {
            tPath = `${this.meta}_error`
            if (this.$te(tPath)) {
              return this.$t(tPath)
            }
          }
        }

        return this.$t(`formFields.default.errors.${this.errors[0]}`)
      }
      return this.error
    }
  },
  created() {
    this.$parentForm?.registerField(
      this.name,
      {
        value: this.value,
        required: this.required === true,
        type: this.type,
        min: this.min,
        max: this.max,
        step: this.step
      },
      this.readOnly
    )
  },
  methods: {
    handleInput(e, eventType) {
      const isEvent = !!e?.preventDefault
      const value = isEvent ? this.getValueFromEvent(e) : e
      const type = isEvent ? e?.type : eventType

      isEvent && e.preventDefault()

      this.$emit(type, e)
      this.$parentForm?.updateField(this.name, value, type)
    },
    getLabelTextKey() {
      if (this.label) return this.label
      if (this.meta) return this.meta
      return `formFields.${this.innerName}.label`
    },
    getValueFromEvent(event) {
      const type = get(event, ['target', 'type'])
      const checked = get(event, ['target', 'checked'], false)
      const files = get(event, ['target', 'files'])
      const value = get(event, ['target', 'value'])

      if (type === 'checkbox') {
        return checked
      }

      if (type === 'file') {
        return files
      }

      if (type === 'number') {
        const parsedValue = parseFloat(value)
        return Number.isNaN(parsedValue) ? 0 : parsedValue
      }

      return value
    }
  }
}
