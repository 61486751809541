//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const types = {
  info: {
    container: 'border-l-4 border-primary-400 bg-primary-100 p-4',
    iconClass: 'w-5 h-5 flex-shrink-0 text-primary-400',
    content: 'ml-3 text-primary-700',
    icon: 'information-circle'
  },
  success: {
    container: 'border-l-4 border-green-400 bg-green-50 p-4',
    iconClass: 'w-5 h-5 flex-shrink-0 text-green-400',
    content: 'ml-3 text-green-700',
    icon: 'check-circle'
  },
  warning: {
    container: 'border-l-4 border-yellow-400 bg-yellow-50 p-4',
    iconClass: 'w-5 h-5 flex-shrink-0 text-yellow-400',
    content: 'ml-3 text-yellow-700',
    icon: 'solid/exclamation'
  },
  error: {
    container: 'border-l-4 border-red-400 bg-red-50 p-4',
    iconClass: 'w-5 h-5 flex-shrink-0 text-red-400',
    content: 'ml-3 text-red-700',
    icon: 'x-circle'
  }
}

export default {
  name: 'Alert',
  props: {
    alert: {
      type: String,
      default: 'warning',
      validator: (t) => Object.keys(types).includes(t)
    }
  },
  data() {
    return {
      classes: {
        container: types[this.alert].container,
        icon: types[this.alert].iconClass,
        content: types[this.alert].content
      },
      icon: types[this.alert].icon
    }
  }
}
