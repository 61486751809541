//
//
//
//
//
//
//
//

export default {
  name: 'OrganismsParamsGroup',
  props: {
    title: { type: String, default: '' }
  }
}
