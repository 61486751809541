export default {
  methods: {
    // TODO: Add typings
    animate(this: any, prop: any, removeAfter = 750) {
      const timeoutKey = `${prop}-timeout`
      clearTimeout(this[timeoutKey])
      this[prop] = true
      this[timeoutKey] = setTimeout(() => {
        this[prop] = false
      }, removeAfter)
    }
  }
}
