//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ORG_INVITE_MUTATION from '~/queries/inbox/organizationInvite.mutation.gql'
import { OrganizationInvitationInboxMessage } from '~/models/inbox/Message'

export default {
  props: {
    message: { type: OrganizationInvitationInboxMessage, required: true }
  },
  data() {
    return { isLoading: false }
  },
  computed: {
    showSkeleton() {
      return !this.message
    },
    isPending() {
      return this.message.isPending
    }
  },
  methods: {
    async accept() {
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: ORG_INVITE_MUTATION,
          variables: {
            input: {
              userEmail: this.message.inviteeEmail,
              orgId: this.message.inviterOrgId,
              action: OrganizationInvitationInboxMessage.acceptAction
            }
          }
        })
        if (this.$auth.strategy.options.name === 'sso') {
          await this.$auth.refreshTokens()
          await this.$store.dispatch('user/loadSources')
        } else {
          await this.$store.dispatch('user/refreshAccessToken')
        }
        this.$store.dispatch(
          'notify/success',
          this.$t('inbox.org_invite.accept_success')
        )
        this.$router.push({ name: 'dashboard' })
      } catch (e) {
        this.$store.dispatch('notify/error', e.message)
      } finally {
        this.isLoading = false
      }
    },
    async decline() {
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: ORG_INVITE_MUTATION,
          variables: {
            input: {
              userEmail: this.message.inviteeEmail,
              orgId: this.message.inviterOrgId,
              action: OrganizationInvitationInboxMessage.declineAction
            }
          }
        })
        this.$store.dispatch(
          'notify/success',
          this.$t('inbox.org_invite.decline_success')
        )
      } catch (e) {
        this.$store.dispatch('notify/error', e.message)
      } finally {
        this.isLoading = false
      }
    }
  }
}
