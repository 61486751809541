import { render, staticRenderFns } from "./Toggle.vue?vue&type=template&id=84f530dc&"
import script from "./Toggle.vue?vue&type=script&lang=js&"
export * from "./Toggle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsToggle: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Toggle.vue').default,MoleculesAlert: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Alert.vue').default})
