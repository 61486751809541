//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { isToday, isYesterday } from 'date-fns'
import Validations from '~/config/validations'
import AnimatableMixin from '~/mixins/AnimatableMixin'

export default {
  components: {
    'v-date-picker': DatePicker
  },
  mixins: [AnimatableMixin],
  props: {
    title: {
      type: String,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedDate: new Date(),
    formData: {
      technician_company_name: '',
      technician_email: ''
    },
    formError: false
  }),
  computed: {
    isServicePartner() {
      return this.$store.getters['user/isServicePartner']
    },
    isPracticeAdmin() {
      return !this.isServicePartner && this.$store.getters['user/isAdmin']
    },
    confirmButtonText() {
      if (isToday(this.selectedDate)) {
        return this.$t('maintenance.modal.confirmToday')
      }
      if (isYesterday(this.selectedDate)) {
        return this.$t('maintenance.modal.confirmYesterday')
      }
      return this.$filters.date(this.selectedDate)
    },
    attributes() {
      return [
        {
          key: 'today',
          highlight: {
            color: 'gray',
            fillMode: 'light',
            class: '!text-gray-800 !border-gray-400'
          }
        }
      ]
    },
    rules() {
      const definitions = {
        technicianCompanyName: { required: this.isPracticeAdmin },
        email: {
          required: this.isPracticeAdmin
        }
      }
      const { technicianCompanyName, email } = Validations.buildValidationRules(
        this.$i18n.locale,
        definitions
      )
      return {
        technician_company_name: technicianCompanyName,
        technician_email: email
      }
    }
  },
  methods: {
    onInput(date) {
      if (date !== null) {
        this.selectedDate = date
      }
    },
    onConfirm({ data, validation }) {
      if (validation.$invalid) {
        return this.animate('formError')
      }
      const confirmData = { task: this.task, date: this.selectedDate }
      if (this.isPracticeAdmin) {
        confirmData.technician = { ...data }
      }
      this.$emit('confirm', confirmData)
    }
  }
}
