//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isDropdownLoading: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    elementDisabled: {
      type: Boolean,
      default: false
    },
    dropdownItems: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onSelect({ callback }, { close }) {
      this.$emit('dropdown-select', callback, close)
    }
  }
}
