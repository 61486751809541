import { render, staticRenderFns } from "./MessagesRow.vue?vue&type=template&id=d58a7636&"
import script from "./MessagesRow.vue?vue&type=script&lang=js&"
export * from "./MessagesRow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Icon.vue').default,MoleculesHeading: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Heading.vue').default,MoleculesButton: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Button.vue').default,Modal: require('/builds/duerrdental/vistor/frontend/vsm/components/Modal.vue').default})
