//
//
//
//
//
//
//
//
//

export default {
  props: {
    selectedCategories: {
      type: Array,
      default: () => []
    },
    things: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    categoriesDropdownItems() {
      return this.categories.map(this.createDropdownItem)
    },
    categories() {
      // TODO: We should place this somewhere else to make it reusable
      return [...new Set(this.things.map(({ category }) => category))]
    }
  },
  methods: {
    createDropdownItem(category) {
      return { label: '_api.product_category.' + category, key: category }
    }
  }
}
