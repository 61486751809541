//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: { type: Number, default: undefined },
    options: { type: Array, required: true },
    showOptions: { type: Boolean, default: false },
    position: { type: String, default: () => 'bottom' }
  },
  computed: {
    innerValue() {
      return this.options[this.value]
    },
    innerValueLabel() {
      return this.innerValue?.label || 'options.placeholder'
    }
  },
  methods: {
    onSelect(option) {
      const index = this.options.findIndex((o) => o === option)
      this.$emit('change', index)
      this.$refs.button?.focus()
    }
  }
}
