//
//
//
//
//
//
//
//
//
//
//

import { normalizePercent } from '~/lib/utils/number'

function getCoordinatesForPercent(percent) {
  const x = Math.cos(2 * Math.PI * percent)
  const y = Math.sin(2 * Math.PI * percent)
  return [x, y]
}
export default {
  props: {
    percent: {
      type: Number,
      validator(val) {
        return isNaN(val) || (val >= 0 && val <= 1)
      },
      default: 0
    }
  },
  computed: {
    slices() {
      return [
        { percent: normalizePercent(this.percent), color: 'currentColor' }
      ]
    },
    items() {
      let cumulativePercent = 0

      const items = []

      this.slices.forEach((slice) => {
        // destructuring assignment sets the two variables at once
        const [startX, startY] = getCoordinatesForPercent(cumulativePercent)

        // each slice starts where the last slice ended, so keep a cumulative percent
        cumulativePercent += slice.percent

        const [endX, endY] = getCoordinatesForPercent(cumulativePercent)

        // if the slice is more than 50%, take the large arc (the long way around)
        const largeArcFlag = slice.percent > 0.5 ? 1 : 0

        // create an array and join it just for code readability
        items.push({
          path: [
            `M ${startX} ${startY}`, // Move
            `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
            `L 0 0` // Line
          ].join(' '),
          color: slice.color
        })
      })

      return items
    }
  }
}
