//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    organizationId: {
      type: [String, Object],
      default: undefined
    },
    type: {
      type: String,
      default: 'solid'
    }
  },
  computed: {
    hasValidOrgId() {
      return typeof this.organizationId === 'string'
    },
    isRoot() {
      return (
        !this.hasValidOrgId ||
        this.$store.getters['organization/isRoot'](this.organizationId)
      )
    },
    isRegion() {
      return (
        this.hasValidOrgId &&
        this.$store.getters['organization/isRegion'](this.organizationId)
      )
    },
    iconName() {
      if (this.isRoot) {
        return `${this.type}/office-building`
      }

      if (this.isRegion) {
        return `${this.type}/briefcase`
      }

      return `${this.type}/location-marker`
    }
  }
}
