//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    tag: { type: String, default: 'li' },
    active: { type: Boolean, default: false },
    index: { type: Number, default: undefined },
    item: { type: Object, default: () => {} }
  },
  computed: {
    showSkeleton() {
      return (
        !this.item || !this.item.title || !(this.item.teaser || this.item.text)
      )
    }
  }
}
