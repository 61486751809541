//
//
//
//
//
//
//
//
//
//

import { OrganizationInvitationInboxMessage } from '~/models/inbox/Message'

const acceptedConfig = {
  ariaLabel: 'accepted-status',
  iconColor: 'text-green-400',
  iconName: 'outline/check-circle',
  text: 'inbox.status.accepted'
}

const declinedConfig = {
  ariaLabel: 'declined-status',
  iconColor: 'text-red-400',
  iconName: 'outline/x-circle',
  text: 'inbox.status.declined'
}

const expiredConfig = {
  ariaLabel: 'expired-status',
  iconColor: 'text-gray-400',
  iconName: 'outline/clock',
  text: 'inbox.status.expired'
}

export default {
  props: {
    message: { type: OrganizationInvitationInboxMessage, required: true }
  },
  computed: {
    statusConfig() {
      if (this.message.isAccepted) return acceptedConfig
      if (this.message.isDeclined) return declinedConfig
      return expiredConfig
    }
  }
}
