/* eslint-disable camelcase */
import { Product } from '../product/Product'
import { ServicePartner, ServicePartnerDto } from './ServicePartner'
import { State } from './State'

export type ThingDto = {
  id: string
  name?: string
  model?: string
  image?: string
  sn?: string
  org?: string
  category?: string
  state?: string
  no_analytics?: boolean
  maintenance_due?: boolean
  ts_last_seen?: string
  localState?: State
  installation_date?: string
  warranty_date?: string
  servicepartner?: ServicePartnerDto
}

const IMAGE_PLATE_SCANNER_CATEGORY = 'imageplatescanner'
const IMAGE_PLATE_CATEGORY = 'imageplate'

export default class Thing {
  public isPending: boolean = false
  public orgName: string = ''
  public orgAddress: string = ''

  // TODO: Give properties a more meaningful name, like image -> imageUrl or ts_last_seen = tsLastSeen
  constructor(
    readonly id: string = '',
    readonly name: string = '',
    readonly model: string = '', // ref
    readonly image: string = '',
    readonly sn: string = '',
    readonly org: string = '',
    readonly category: string = '',
    readonly state: string = '',
    readonly no_analytics: boolean = false,
    readonly maintenance_due: boolean = false,
    readonly ts_last_seen: string = new Date().getTime().toString(),
    readonly localState: State = State.Default,
    readonly installation_date: string = '',
    readonly warranty_date: string = '',
    readonly servicepartner?: ServicePartner
  ) {
    if (category === '_api.product_category.' || !category) {
      this.category = 'other_equipment'
    }
  }

  public get isScanner() {
    return this.category === IMAGE_PLATE_SCANNER_CATEGORY
  }

  public get isPlate() {
    return this.category === IMAGE_PLATE_CATEGORY
  }

  static fromObject(dto: ThingDto): Thing {
    return new Thing(
      dto.id,
      dto.name,
      dto.model,
      dto.image,
      dto.sn,
      dto.org,
      dto.category,
      dto.state,
      dto.no_analytics,
      dto.maintenance_due,
      dto.ts_last_seen,
      dto.localState,
      dto.installation_date,
      dto.warranty_date,
      dto.servicepartner && ServicePartner.fromObject(dto.servicepartner)
    )
  }

  static fromProduct(product: Product): Thing {
    const thing = new Thing(
      product.deviceId,
      product.name,
      product.ref,
      product.thumb,
      product.sn
    )
    thing.orgName = product.orgName
    return thing
  }
}
