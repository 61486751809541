import { render, staticRenderFns } from "./Breadcrumbs.vue?vue&type=template&id=5bfd92a2&"
import script from "./Breadcrumbs.vue?vue&type=script&lang=js&"
export * from "./Breadcrumbs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Icon.vue').default,MoleculesOrganizationIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/organization/Icon.vue').default})
