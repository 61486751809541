//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Member } from '~/models/organization/member/Member'
import Validations from '~/config/validations'
import { getRoles } from '~/models/user/Role'

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({ formData: { email: '', role: '' } }),
  computed: {
    isServicePartner() {
      return this.$store.getters['user/isServicePartner']
    },
    roles() {
      const roles = getRoles(this.isServicePartner)
      return roles.map((role) => {
        return {
          id: role,
          value: role,
          label: this.$t(`settings.members.${role}`)
        }
      })
    },
    rules() {
      const definitions = {
        email: { required: true },
        role: { required: true }
      }
      const { role, email } = Validations.buildValidationRules(
        this.$i18n.locale,
        definitions
      )
      return { role, email }
    }
  },
  methods: {
    submit() {
      this.$refs.form.handleSubmit()
    },
    onSubmit({ data, validation }) {
      if (!validation.$invalid) {
        const member = new Member(data.email, data.email, data.role)
        this.$emit('create', member)
      }
    }
  }
}
