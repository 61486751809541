//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    text: { type: String, default: undefined },
    icon: { type: [String, Boolean], default: 'inbox' },
    tag: { type: String, default: 'div' }
  }
}
