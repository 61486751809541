var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col min-w-0 flex-1 screen:overflow-hidden"},[_c('div',{staticClass:"flex-1 z-0 flex flex-col screen:overflow-hidden"},[(_vm.activeId)?_c('atoms-navigation-portal',[_c('nav',{staticClass:"_tid_navigate-back",attrs:{"aria-label":"Breadcrumb"}},[_c('router-link',{attrs:{"to":{ name: _vm.backRoute },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"inline-flex py-3 items-center space-x-2 text-sm font-medium",attrs:{"href":href},on:{"click":navigate}},[_c('atoms-icon',{staticClass:"-ml-2 h-5 w-5 opacity-50",attrs:{"name":"chevron-left"}}),_vm._v(" "),_c('span',[_vm._v("\n              "+_vm._s(_vm.$t(_vm.title))+"\n            ")])],1)]}}],null,false,4265902807)})],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex h-full"},[_vm._t("main",[(_vm.activeId)?_c('div',{staticClass:"flex-1 overflow-auto"},[_vm._t("default")],2):_c('main',{staticClass:"hidden xl:flex flex-col items-center justify-center relative z-0 overflow-y-hidden focus:outline-none order-last w-full"},[_c('p',{staticClass:"flex items-center text-gray-400 font-medium pointer-events-none select-none"},[_vm._t("main-empty",[_c('molecules-empty-state',{attrs:{"text":_vm.title,"icon":_vm.icon}})])],2)])]),_vm._v(" "),_c('molecules-list',_vm._g({class:[
          'w-full xl:w-96 2xl:w-128 select-none',
          _vm.activeId && 'hidden xl:flex',
          !_vm.hasInfiniteScroll && _vm.loading && 'pointer-events-none'
        ],attrs:{"active-id":_vm.activeId,"items":_vm.items,"title":_vm.title,"loading":_vm.loading,"empty-icon":_vm.icon,"empty-text":_vm.emptyText,"component":_vm.listComponent,"has-infinite-scroll":_vm.hasInfiniteScroll,"has-more":_vm.hasMore},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._t("listItem",null,null,props)]}}],null,true)},_vm.$listeners),[_vm._v(" "),_c('template',{slot:"header"},[_vm._t("header")],2)],2)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }