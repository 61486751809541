//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gsap from 'gsap'

export default {
  props: {
    delay: { type: Number, default: 0.2 },
    stagger: { type: Number, default: 0.1 },
    duration: { type: Number, default: 0.3 },
    tag: { type: String, default: 'div' }
  },
  data() {
    return {
      elements: [],
      doneFn: []
    }
  },
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0
      el.style.visibility = 'hidden'
      el.style.transform = 'translateY(0px)'
      this.elements.push(el)
    },
    enter(_el, done) {
      this.doneFn.push(done)
      if (this.elements.length) {
        const timeline = gsap.timeline({
          delay: this.$props.delay,
          onComplete: this.doneFn.forEach((fn) => fn())
        })
        timeline.staggerTo(
          this.elements,
          this.$props.duration,
          {
            autoAlpha: 1,
            transform: 'translateY(-15px)',
            ease: 'back.out(2)'
          },
          this.$props.stagger
        )
        this.elements = []
      }
    }
  }
}
