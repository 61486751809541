import { render, staticRenderFns } from "./Item.vue?vue&type=template&id=b9d5719e&"
import script from "./Item.vue?vue&type=script&lang=js&"
export * from "./Item.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsSkeleton: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Skeleton.vue').default,AtomsIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Icon.vue').default})
