import { OrgType } from '~/models/organization/Organization'
import { AccessType } from '~/models/user/AccessType'
import { Role } from '~/models/user/Role'

export interface IUserState {
  accessType: AccessType | string
  id: string
  email: string
  firstName: string
  lastName: string
  name: string
  org: string
  role: Role | string
  rorg: string
  rootOrgType: OrgType | string
  language: string
  token: any
  eula: any
  showAnimation: boolean
  loadingSources: boolean
}

export default (): IUserState => ({
  accessType: '',
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  name: '',
  org: '',
  role: '',
  rorg: '',
  rootOrgType: '',
  language: '',
  token: '',
  eula: '',
  showAnimation: true,
  loadingSources: false
})
