//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isString } from 'lodash-es'
import THING_SERVICEPARTNER from '~/queries/thing/servicepartner.gql'
import THING_SERVICEPARTNER_MUTATION from '~/queries/thing/servicepartner.mutation.gql'
import THING_SERVICEPARTNER_DELETE from '~/queries/thing/servicepartnerDelete.mutation.gql'
import Validations from '~/config/validations'
import NotificationMixin from '~/mixins/notification-mixin/NotificationMixin'

export default {
  mixins: [NotificationMixin],
  props: {
    thing: { type: Object, default: () => {} }
  },
  apollo: {
    servicePartner: {
      query: THING_SERVICEPARTNER,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          thingId: this.thing?.id
        }
      },
      update({ servicepartnerQuery }) {
        this.isLoading.query = false
        return servicepartnerQuery.servicepartner
      }
    }
  },
  data: () => ({
    isLoading: { query: true, delete: false, save: false },
    showDeleteModal: false,
    servicePartners: []
  }),
  computed: {
    thingHasValidOrg() {
      return this.$store.getters['organization/isValidOrg'](this.thing?.org)
    },
    formData() {
      return {
        servicePartner: this.servicePartner || { name: '', id: '' },
        dataPrivacy: ''
      }
    },
    rules() {
      const mustHaveId = ({ id }) => isString(id) && id.length > 0
      const isChecked = (value) => value === true
      const definitions = {
        servicePartner: {
          required: !this.servicePartner,
          customValidation: mustHaveId
        },
        dataPrivacy: { required: true, customValidation: isChecked }
      }
      const { servicePartner, dataPrivacy } = Validations.buildValidationRules(
        this.$i18n.locale,
        definitions
      )

      return {
        servicePartner,
        dataPrivacy
      }
    }
  },
  methods: {
    async onDelete() {
      this.isLoading.delete = true
      await this.delete()
      this.isLoading.delete = false
      this.showDeleteModal = false
    },
    async delete() {
      try {
        await this.$apollo.mutate({
          mutation: THING_SERVICEPARTNER_DELETE,
          variables: { thingId: this.thing.id },
          update: () => this.$apollo.queries.servicePartner.refetch()
        })

        return this.notifySuccess(this.$t('servicePartner.remove_success'))
      } catch {
        return this.notifyError(this.$t('servicePartner.remove_error'))
      }
    },
    async onSubmit({ data, validation }) {
      if (validation.$invalid) return

      this.isLoading.save = true
      await this.submit(data)
      this.isLoading.save = false
    },
    async submit(data) {
      try {
        await this.$apollo.mutate({
          mutation: THING_SERVICEPARTNER_MUTATION,
          variables: {
            thingId: this.thing.id,
            servicepartnerId: data.servicePartner.id
          }
        })
        this.notifySuccess(this.$t('servicePartner.save_success'))
        this.$apollo.queries.servicePartner.refetch()
      } catch {
        this.notifyError(this.$t('servicePartner.save_error'))
      }
    },
    async search(query) {
      this.servicePartners = await this.$api.servicePartner.search(query)
    }
  }
}
