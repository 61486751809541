var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoggedIn)?_c('div',{class:[
    'font-sans flex screen:h-screen antialiased px-0-safe pb-0-safe',
    _vm.isNative &&
      'before:absolute before:pt-safe before:w-full before:bg-primary-800', // status bar background
    _vm.isNative && 'is-mobile-app'
  ]},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.loading)?_c('div',{key:"content",staticClass:"flex flex-col lg:flex-row screen:h-screen flex-grow w-full pt-safe"},[_c('organisms-navigation',{staticClass:"print:hidden bg-primary-800",attrs:{"navigation":_vm.navigation,"username":_vm.user.name,"email":_vm.user.email}}),_vm._v(" "),_c('nuxt',{staticClass:"bg-gray flex flex-grow overflow-y-scroll"})],1):_c('molecules-loading-screen')],1),_vm._v(" "),_c('portal-target',{attrs:{"name":"modals"}}),_vm._v(" "),_c('organisms-notifications',{attrs:{"position":_vm.notificationPosition},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var notifications = ref.notifications;
  var close = ref.close;
return _vm._l((notifications),function(notification){return _c('molecules-notification',{key:notification.id,attrs:{"title":notification.title,"accept":notification.accept,"dismiss":notification.dismiss,"is-dialog":notification.isDialog,"message":notification.message,"type":notification.type},on:{"close":function($event){return close(notification)}}})})}}],null,false,3182324855)}),_vm._v(" "),_c('organisms-confirmation-modal')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }