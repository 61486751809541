//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
  name: 'MoleculesFormPlacesAutocomplete',
  components: {
    VueGoogleAutocomplete
  },
  props: {
    types: {
      type: String,
      default: 'establishment'
    },
    fields: {
      type: Array,
      default: () => [
        'address_components',
        'formatted_address',
        'geometry',
        'id',
        'name',
        'place_id',
        'type',
        'vicinity'
      ]
    },
    onPlaceChanged: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      inputCssClass:
        'border px-3 py-2 w-full focus:ring-1 sm:text-sm bg-white pr-10 border-gray-300 text-primary-900 placeholder-primary-300 focus:ring-primary-500 focus:border-primary-500 shadow-sm',
      isPlaceChanged: false,
      name: ''
    }
  },
  computed: {
    value() {
      return this.$attrs.value
    },
    showGoogleAutoComplete() {
      try {
        return this.$nuxt.isOnline && !!google
      } catch {
        return false
      }
    }
  },
  watch: {
    value(val) {
      if (this.showGoogleAutoComplete) this.$refs.autocomplete.update(val)
    }
  },
  methods: {
    onChange(val) {
      if (this.isPlaceChanged) {
        this.$refs.autocomplete.autocompleteText = this.name
        val = this.name
        this.isPlaceChanged = false
      }
      this.$emit('change', val)
    },
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    placechanged(addressData, placeResultData, id) {
      this.name = placeResultData.name
      this.isPlaceChanged = true
      this.onPlaceChanged(addressData, placeResultData, id)
    }
  }
}
