//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isEmpty } from 'lodash-es'

export default {
  props: {
    organizationId: {
      type: String,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    depth: {
      type: Number,
      required: true
    }
  },
  computed: {
    organization() {
      return this.$store.getters['organization/byId'](this.organizationId) || {}
    },
    members() {
      return this.$store.getters['organization/members'](this.organizationId)
    },
    hasAddress() {
      return !isEmpty(this.organization.address?.trim())
    },
    hasInvalidAddress() {
      return (
        this.hasAddress &&
        !this.$store.getters['organization/isValidOrg'](this.organizationId)
      )
    }
  }
}
