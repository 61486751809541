import DOMPurify from 'dompurify'
import marked from 'marked'
import { isUndefined } from 'lodash-es'
import { IGetLegalDocumentDto } from '~/carrier/legal/input/IGetLegalDocumentDto'
import { LegalType } from '~/models/legal/LegalType'
import { LegalDocumentId } from '~/models/legal/LegalDocumentId'

export class LegalDocument {
  constructor(
    private readonly id: LegalDocumentId,
    public readonly content: string
  ) {}

  public static fromJson(id: LegalDocumentId, dto: IGetLegalDocumentDto) {
    if (isUndefined(dto.version) || isUndefined(dto.content)) {
      return LegalDocument.empty()
    }

    const content = DOMPurify.sanitize(marked(dto.content))

    return new LegalDocument(id, content)
  }

  public static empty() {
    const id = new LegalDocumentId(LegalType.Empty, '', '')

    return new LegalDocument(id, '')
  }

  public is(document: LegalDocument) {
    return this.hasId(document.id)
  }

  public hasId(id: LegalDocumentId) {
    return this.id.is(id)
  }

  public get isEmpty() {
    return this.id.hasType(LegalType.Empty)
  }
}
