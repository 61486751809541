import { render, staticRenderFns } from "./CreateModal.vue?vue&type=template&id=2e0686e6&"
import script from "./CreateModal.vue?vue&type=script&lang=js&"
export * from "./CreateModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesButton: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Button.vue').default,Modal: require('/builds/duerrdental/vistor/frontend/vsm/components/Modal.vue').default})
