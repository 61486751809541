//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isObject } from 'lodash-es'
import FormTextInputMixin from '~/mixins/FormInputMixin'

export default {
  mixins: [FormTextInputMixin],
  props: {
    id: { type: String },
    options: { type: Array, required: true },
    value: { type: [String, Object], default: '' },
    disabled: { type: Boolean, default: false },
    menuClass: {
      type: [String, Array],
      default: () => '!w-full max-h-56 !rounded-none'
    },
    itemClass: { type: [String, Array], default: () => '!rounded-none' },
    position: { type: String, default: () => 'bottom' },
    valueLabel: { type: String, default: 'label' }
  },
  data: () => ({
    currentIndex: 0
  }),
  computed: {
    currentValue() {
      return (
        (this.options || [])
          .filter((opt, index) => {
            if (
              opt.id === this.value.id ||
              opt.id === this.value ||
              opt === this.value.id ||
              opt === this.value
            ) {
              this.currentIndex = index
              return true
            }
            return false
          })
          .pop() || {}
      )
    },
    innerValueLabel() {
      return this.valueLabel
        ? this.currentValue[this.valueLabel]
        : this.currentValue
    }
  },
  methods: {
    rotate(dir = +1) {
      let index = this.currentIndex + dir
      if (index < 0) {
        index = this.options.length - 1
      } else if (index >= this.options.length) {
        index = 0
      }
      this.currentIndex = index
      this.onSelect(this.options[index])
    },
    active(item) {
      return this.currentValue.id === item.id || this.currentValue === item.id
    },
    onSelect(option) {
      const val = isObject(this.value) ? { ...option } : option.id || option
      this.$emit('change', val)
      this.$refs.button?.focus()
    }
  }
}
