//
//
//
//
//
//
//
//

export default {
  props: {
    hasMore: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      observer: null
    }
  },
  created() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      rootMargin: '0px',
      threshold: 0
    })
  },
  mounted() {
    this.observer.observe(this.$refs.listEnd)
  },
  beforeUnmount() {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    onElementObserved(entries) {
      if (this.loading || !this.hasMore) return
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) this.$emit('loadMore')
      })
    }
  }
}
