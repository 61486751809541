/* eslint-disable no-console */
/**
 * This is the Legacy Connector, it is Part
 * of VistaSoft Monitor. The Legacy Connector
 * is a C++ application that runs on the
 * customer's local machine and is used to
 * make devices cloud-ready.
 * The Legacy Connector will be represented
 * for the Client as 'Thing'.
 */
export class LegacyConnector {
  static MODEL_ID = '2110300060'

  static allLegacyConnectors = {}

  static isLegacyConnector(thing) {
    const { model, id, sn } = thing
    if (model === LegacyConnector.MODEL_ID) {
      if (!LegacyConnector.allLegacyConnectors[id]) {
        LegacyConnector.allLegacyConnectors[id] = { id, sn }
      }
      return true
    }
  }

  static printLegacyConnectors() {
    console.table(Object.values(LegacyConnector.allLegacyConnectors))
  }
}
