//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getRoles } from '~/models/user/Role'

export default {
  props: {
    member: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isDropdownLoading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({ isEditModeActive: false }),
  computed: {
    isServicePartner() {
      return this.$store.getters['user/isServicePartner']
    },
    isActive() {
      return this.member.status === 'active'
    },
    isDisabled() {
      const isUser =
        this.member.email === this.$store.getters['user/profile'].email
      return isUser
    },
    dropdownItems() {
      if (this.isEditModeActive) {
        return this.editDropdownItems
      }

      return this.mainDropdownItems
    },
    mainDropdownItems() {
      return [
        {
          label: this.$t('buttons.delete'),
          icon: 'trash',
          callback: this.onDelete.bind(this)
        },
        {
          label: this.$t('settings.members.change_role'),
          icon: 'pencil',
          callback: this.enableEditMode.bind(this)
        }
      ]
    },
    editDropdownItems() {
      const roles = getRoles(this.isServicePartner)
      return roles.map((role) => {
        return {
          icon: this.getCheckedRoleIcon(role),
          label: this.$t(`settings.members.${role}`),
          callback: this.onEditRole.bind(this, role)
        }
      })
    }
  },
  methods: {
    onDropdownSelect(callback, close) {
      callback(close)
    },
    onDelete(close) {
      this.$emit('delete')
      close()
    },
    onEditRole(role, close) {
      this.$emit('update', role)
      close()
      this.disableEditMode()
    },
    enableEditMode() {
      this.isEditModeActive = true
    },
    disableEditMode() {
      this.isEditModeActive = false
    },
    getCheckedRoleIcon(role) {
      if (role === this.member.role) {
        return 'solid/check-circle'
      }

      return 'svg/circle'
    }
  }
}
