import { render, staticRenderFns } from "./Widget.vue?vue&type=template&id=4156539c&"
import script from "./Widget.vue?vue&type=script&lang=js&"
export * from "./Widget.vue?vue&type=script&lang=js&"
import style0 from "./Widget.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Icon.vue').default,MoleculesDashboardProgress: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/dashboard/Progress.vue').default,AtomsSkeleton: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Skeleton.vue').default})
