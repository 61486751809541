import { isString } from 'lodash-es'
import { IScanner } from '~/lib/native/IScanner'

export class ProductScanner {
  constructor(private readonly scanner: IScanner) {}

  public async scan() {
    const { barcode } = await this.scanner.scan()
    const serialNumber = ProductScanner.getSerialNumber(barcode || '')
    const referenceNumber = ProductScanner.getReferenceNumber(barcode || '')

    return { serialNumber, referenceNumber }
  }

  private static getSerialNumber(payload: string) {
    if (ProductScanner.isProductId(payload)) {
      const parsedProductId = ProductScanner.parseProductId(payload)
      return parsedProductId ? parsedProductId[3] : ''
    }

    if (ProductScanner.isSerialNumber(payload)) {
      return payload
    }

    return ''
  }

  /**
   * Checks if given String is a serial number.
   */
  private static isSerialNumber(serialNumber: string) {
    if (!isString(serialNumber)) return false

    return serialNumber.match(/^[a-zA-z]{1,2}\d{4,}.*$/)
  }

  private static getReferenceNumber(payload: string) {
    if (ProductScanner.isProductId(payload)) {
      const parsedProductId = ProductScanner.parseProductId(payload)
      return parsedProductId
        ? ProductScanner.removeSuffix(parsedProductId[2])
        : ''
    }

    if (ProductScanner.isReferenceNumber(payload)) {
      return payload
    }

    return null
  }

  private static isReferenceNumber(referenceNumber: string) {
    return isString(referenceNumber)
  }

  /**
   * Parses the Product ID in to an Array:
   * [0]: unused
   * [1]: Labeler Identification Code
   * [2]: Reference ID
   * [3]: Serial Number
   * Regular Expression Helper: https://regex101.com/r/pIeETU/1
   * UDI code documentation: https://confluence.duerrdental.com/display/LABEL/UDI
   */
  private static parseProductId(productId: string): RegExpExecArray | null {
    return /^\+(E247|D678)([A-Z0-9]{4,})\/\${1,2}\+?7?([a-zA-z]{1,2}\d{4,})\/.*$/gm.exec(
      productId
    )
  }

  private static isProductId(productId: string) {
    const parsedProductId = ProductScanner.parseProductId(productId)

    return Array.isArray(parsedProductId)
  }

  private static removeSuffix(parsedString: string) {
    return parsedString.slice(0, -1)
  }
}
