//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    isEnabled: {
      type: Boolean,
      required: true
    },
    hasRootChildren: {
      type: Boolean,
      required: true
    }
  }
}
