//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gsap from 'gsap'
import { isNumericRegex, normalizePercent } from '~/lib/utils/number'

const colorConfig = {
  green: {
    key: 'good',
    textClass: 'text-green-600'
  },
  yellow: {
    key: 'warning',
    icon: 'solid/exclamation',
    iconClass: 'text-yellow-600',
    textClass: 'text-yellow-600',
    cardClass:
      'border-2 !border-yellow-600 ring-2 ring-opacity-50 ring-yellow-600'
  },
  red: {
    key: 'error',
    icon: 'solid/exclamation-circle',
    iconClass: 'text-red-600',
    textClass: 'text-red-600',
    cardClass: 'border-2 !border-red-600 ring-2 ring-opacity-50 ring-red-600'
  }
}

export default {
  props: {
    index: { type: Number, default: 0 },
    loading: { type: Boolean, default: false },
    type: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    unit: {
      type: String,
      default: null
    },
    subline: {
      type: String,
      default: null
    },
    progress: {
      type: Boolean,
      default: false
    },
    animate: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      val: 0
    }
  },
  computed: {
    levelColor() {
      return this.color && colorConfig[this.color]
    },
    valueLength() {
      if (!this.value) return 0
      return this.value.toString().length
    },
    valNormalized() {
      if (this.isFahrenheit) {
        // normalize Fahrenheit between 32° - 212° to 0 - 1
        return normalizePercent((this.val - 32) / (212 - 32))
      } else {
        // normalize val to 0 and 1
        return normalizePercent(this.val / 100)
      }
    },
    valFormatted() {
      if (isNumericRegex(this.val)) {
        return typeof this.val === 'string'
          ? parseFloat(this.val).toFixed()
          : this.val.toFixed()
      }
      return this.val?.replace(/([/\-.])/, '$1<wbr>') // make word breaks possible
    },
    _subline() {
      return this.subline?.replace(/([./-]{1,})/g, '$1<wbr>') // make word breaks possible
    },
    isTemperature() {
      return ['°F', '°C'].includes(this.unit)
    },
    isFahrenheit() {
      return ['°F'].includes(this.unit)
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(valNew, _valOld) {
        if (!isNumericRegex(valNew) || this.animate === false) {
          this.val = valNew
          return
        }
        gsap.killTweensOf(this.tween)
        this.$nextTick(() => {
          gsap.to(this.tween, {
            val: valNew,
            duration: 1.5,
            delay: 0.3 + this.index * 0.1,
            ease: 'sine.inOut',
            onUpdate: () => {
              this.val = this.tween.val
            }
          })
        })
      }
    }
  },
  beforeCreate() {
    this.tween = {
      val: 0
    }
  },
  beforeDestroy() {
    gsap.killTweensOf(this.tween)
  },
  methods: {
    valueLenBetween(min, max) {
      return this.valueLength >= min && this.valueLength <= max
    }
  }
}
