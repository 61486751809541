//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    selectedOrganizations: {
      type: Array,
      default: () => []
    },
    organizations: {
      type: Array,
      default: () => []
    },
    position: {
      type: String,
      default: 'bottom-end'
    },
    menuClass: {
      type: String,
      default: undefined
    }
  },
  computed: {
    organizationDropdownItems() {
      return this.organizations.map(this.createDropdownItem)
    }
  },
  methods: {
    createDropdownItem(organization) {
      return { ...organization, label: organization.name, key: organization.id }
    }
  }
}
