//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    selectedItems: {
      type: Array,
      default: () => []
    },
    dropdownItems: {
      type: Array,
      default: () => []
    },
    toggleText: {
      type: String,
      required: true
    }
  },
  computed: {
    categoriesDropdownItems() {
      return this.dropdownItems
        .map(this.addDropdownIcon)
        .map(this.addDropdownCallback)
    }
  },
  methods: {
    onCategorySelect({ callback }) {
      callback()
    },
    addDropdownIcon(dropdownItem) {
      return {
        ...dropdownItem,
        icon: this.getCheckedIcon(dropdownItem.key)
      }
    },
    addDropdownCallback(dropdownItem) {
      return {
        ...dropdownItem,
        callback: this.toggleItem.bind(this, dropdownItem.key)
      }
    },
    getCheckedIcon(category) {
      if (this.selectedItems.includes(category)) {
        return 'check'
      }

      return null
    },
    toggleItem(key) {
      if (this.selectedItems.includes(key)) {
        return this.$emit(
          'update',
          this.selectedItems.filter(
            (selectedCategory) => selectedCategory !== key
          )
        )
      }

      this.$emit('update', [...this.selectedItems, key])
    }
  }
}
