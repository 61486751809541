//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    parameters: {
      type: Array,
      default: () => []
    },
    parameterTree: {
      type: Object,
      default: () => {}
    },
    scannerMessage: {
      type: String,
      default: undefined
    },
    readOnlyTexts: {
      type: Object,
      default: undefined
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    userRole() {
      return this.$store.getters['user/role']
    },
    text() {
      if (this.isLoading) {
        return this.$t('remote_operations.title')
      } else {
        return this.$t(this.scannerMessage ?? 'remote_operations.no_results')
      }
    }
  }
}
