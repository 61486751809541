//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isEmpty } from 'lodash-es'

export default {
  props: {
    organizations: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    selectedOrganizations: []
  }),
  computed: {
    organizationDropdownItems() {
      const orgTree = this.$store.getters['organization/getTreeWithGeoIpByIds'](
        this.organizations
      )
      return orgTree.map(this.createDropdownItem)
    },
    isFilterActive() {
      return this.selectedOrganizations.length > 0
    },
    filteredOrganizations() {
      if (this.isFilterActive) {
        return this.selectedOrganizations
      }
      return this.organizations
    }
  },
  mounted() {
    this.update(this.setFiltersBasedOnQueryParameters.bind(this))
  },
  methods: {
    clearFilter() {
      this.selectedOrganizations = []
      this.update()
    },
    createDropdownItem(organization) {
      return { ...organization, label: organization.name, key: organization.id }
    },
    setSelectedOrganizations(organizations) {
      this.selectedOrganizations = organizations
    },
    update(updateFilter) {
      updateFilter?.()
      this.updateQueryParameters()
      this.$emit('input', this.filteredOrganizations)
    },
    setFiltersBasedOnQueryParameters() {
      const { organizations } = this.$route.query
      this.selectedOrganizations = this.parseJsonArray(organizations)
    },
    createQuery() {
      const query = {}
      if (!isEmpty(this.searchQuery)) {
        query.searchQuery = this.searchQuery
      }
      if (this.selectedOrganizations.length > 0) {
        query.organizations = JSON.stringify(this.selectedOrganizations)
      }
      return query
    },
    updateQueryParameters() {
      const query = this.createQuery()
      if (this.$route.query.organizations === query.organizations) {
        return
      }
      this.$router.replace({
        name: this.$route.name,
        params: this.$route.params,
        query: this.createQuery()
      })
    },
    parseJsonArray(jsonString = '[]') {
      try {
        return JSON.parse(jsonString)
      } catch (e) {
        return []
      }
    }
  }
}
