//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ScreenOrientation } from '@capacitor/screen-orientation'
import NavigationMixin from '~/mixins/NavigationMixin'

export default {
  mixins: [NavigationMixin],
  props: {
    navigation: { type: Object, required: true },
    username: { type: String, default: null },
    email: { type: String, default: null }
  },
  data() {
    return {
      isMobileMenuOpen: false,
      isNative: this.$platform.isNative,
      isLandscape: false
    }
  },
  computed: {
    showAlertPulse() {
      return this.thingAlertCount > 0 || this.inboxAlertCount > 0
    },
    showProductSearchButton() {
      const hasSearch = !!this.$envConfig.navigation.main.find(
        (n) => n.key === 'search'
      ) // when product search is available in sidebar
      const exclusionList = ['search', 'map', 'legal-legalType', 'login-error']

      return (
        this.isNative &&
        hasSearch &&
        this.$route.meta.isRootPath &&
        !exclusionList.includes(this.$route.name)
      )
    },
    isDefaultPage() {
      return this.$route.name === 'dashboard' || this.$route.name === 'news'
    }
  },
  created() {
    this.$router.beforeEach((_to, _from, next) => {
      this.isMobileMenuOpen = false
      next()
    })
  },
  mounted() {
    if (!this.isNative) return
    this.checkIsLandscape()

    ScreenOrientation.addListener(
      'screenOrientationChange',
      this.checkIsLandscape
    )
    window.onresize = this.checkIsLandscape
  },
  methods: {
    async checkIsLandscape() {
      await ScreenOrientation.orientation() // wait for rotation to be done, else width is inaccurate
      this.isLandscape = window.innerWidth >= 1024
    }
  }
}
