//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isEmpty } from 'lodash-es'

export default {
  inject: {
    $parentForm: {
      default: null
    }
  },
  props: {
    organization: {
      type: Object,
      required: true
    },
    organizationById: {
      type: Object,
      required: true
    },
    onPlaceChanged: {
      type: Function,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isServicePartnerRootOrg() {
      return this.isServicePartner && this.isRootOrg
    },
    isServicePartner() {
      return this.$store.getters['user/isServicePartner']
    },
    isRootOrg() {
      return this.$store.getters['organization/isRoot'](this.organization?.id)
    },
    orgTree() {
      const tree = this.$store.getters['organization/getListExcept'](
        this.organization?.id
      )
      return tree
    },
    options() {
      return this.orgTree.map((org) => ({ id: org.id, label: org.name }))
    },
    hasInvalidAddress() {
      const { address = '', place_id: placeId } = this.organizationById
      const hasAddress = !isEmpty(address?.trim())
      const hasInvalidPlaceId = !placeId
      const isCurrentValue = address === this.organization.address
      return hasAddress && hasInvalidPlaceId && isCurrentValue
    }
  }
}
