//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  computed: {
    model: {
      get() {
        return this.context.model
      },
      set(model) {
        // eslint-disable-next-line vue/no-mutating-props
        this.context.model = model
      }
    }
  }
}
