import { render, staticRenderFns } from "./Map.vue?vue&type=template&id=7ec25aa3&"
import script from "./Map.vue?vue&type=script&lang=js&"
export * from "./Map.vue?vue&type=script&lang=js&"
import style0 from "./Map.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Icon.vue').default,MoleculesButton: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Button.vue').default})
