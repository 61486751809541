var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
    _vm.value ? _vm.innerColor : 'bg-gray-200',
    _vm.disabled ? 'cursor-not-allowed opacity-40' : 'cursor-pointer'
  ],attrs:{"type":"button","tabindex":"0","disabled":_vm.disabled,"aria-pressed":"false","aria-labelledby":"availability-label"},on:{"click":function($event){return _vm.$emit('input', !_vm.value)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return _vm.$emit('input', !_vm.value)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('input', !_vm.value)}]}},[_c('span',{class:[
      'translate-x-0 pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
      _vm.value ? 'translate-x-5' : 'translate-x-0'
    ],attrs:{"aria-hidden":"true"}})])}
var staticRenderFns = []

export { render, staticRenderFns }