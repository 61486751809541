var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"p-2 !-mb-2 w-full"},[_c('div',{staticClass:"flex border-transparent border relative w-full",class:[
      _vm.active
        ? 'bg-white border-gray-200'
        : 'hover:border-gray-300 hover:bg-gray-50',
      _vm.isUnread && 'font-bold'
    ],attrs:{"aria-label":"inbox-item"}},[(_vm.active)?_c('div',{staticClass:"absolute right-0 top-0 h-full pr-1 bg-blue-500 z-10"}):_vm._e(),_vm._v(" "),_c('router-link',{attrs:{"to":_vm.to,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var navigate = ref.navigate;
return [_c('a',{staticClass:"focus:outline-none flex p-3 space-x-3 overflow-hidden w-full border border-transparent",class:_vm.active && 'border-blue-500 shadow',attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"flex-shrink-0 mt-1"},[_c('atoms-skeleton',{attrs:{"index":_vm.index,"show":_vm.showSkeleton}},[(!_vm.showSkeleton && _vm.message.isPending)?_c('div',{staticClass:"w-2.5 h-2.5 rounded-full bg-primary-400",attrs:{"aria-label":"message-pending"}}):_c('div',{staticClass:"w-3 h-3"})])],1),_vm._v(" "),_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex flex-row justify-between"},[_c('div',[_c('atoms-skeleton',{class:_vm.showSkeleton && 'leading-snug',attrs:{"index":_vm.index,"show":_vm.showSkeleton,"min":5,"max":15}},[_c('p',{class:[
                    'text-sm 2xl:text-md flex-1 font-medium text-gray-900 line-clamp-2 leading-tight 2xl:leading-snug',
                    _vm.isUnread && 'font-bold'
                  ],attrs:{"aria-label":"inbox-item-title"}},[_vm._v("\n                  "+_vm._s(_vm.message.title)+"\n                ")])]),_vm._v(" "),_c('atoms-skeleton',{attrs:{"index":_vm.index,"show":_vm.showSkeleton,"min":1,"max":4}},[_c('small',{staticClass:"text-gray-500",attrs:{"aria-label":"inbox-item-subtitle"}},[_vm._v("\n                  "+_vm._s(_vm.message.subtitle)+"\n                ")])])],1),_vm._v(" "),_c('div',[_c('atoms-skeleton',{attrs:{"index":_vm.index,"show":_vm.showSkeleton}},[_c('p',{staticClass:"text-right text-xs text-gray-500",attrs:{"aria-label":"inbox-item-date"}},[_vm._v("\n                  "+_vm._s(_vm._f("date")(_vm.message.createdAt))+"\n                ")])])],1)]),_vm._v(" "),(_vm.orgName)?_c('atoms-skeleton',{attrs:{"index":_vm.index,"show":_vm.showSkeleton,"min":3,"max":4}},[_c('div',{staticClass:"flex flex-row mt-3 text-xs text-gray-500"},[_c('atoms-icon',{staticClass:"w-4 h-4 mr-1 text-gray-400",attrs:{"name":"office-building"}}),_vm._v(" "),_c('p',{attrs:{"aria-label":"inbox-item-organization"}},[_vm._v("\n                "+_vm._s(_vm.orgName)+"\n              ")])],1)]):_c('atoms-skeleton',{attrs:{"index":_vm.index,"show":_vm.showSkeleton,"min":3,"max":4}},[_c('div',{staticClass:"mt-3 h-4"})])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }