const JAPAN_CODE = 'JP'

export async function geocoder(data) {
  const { address } = data
  if (typeof google === 'undefined') {
    return data
  }
  const geocoder = new google.maps.Geocoder()
  try {
    await geocoder.geocode({ address }, (result, status) => {
      if (status === 'OK') {
        const {
          address_components: components = [],
          formatted_address: formattedAddress,
          geometry: { location = {} },
          place_id: placeId = ''
        } = result[0]

        const city = getComponent(components, 'locality')?.long_name || ''
        const zip = getComponent(components, 'postal_code')?.long_name || ''
        const country = getComponent(components, 'country')?.short_name || ''
        const street = getStreet(formattedAddress, zip, country) || ''
        const geoip = location
          ? { latitude: location.lat(), longitude: location.lng() }
          : {}
        data = {
          ...data,
          street,
          zip,
          city,
          country,
          geoip,
          placeId
        }
      }
    })
  } catch (e) {
    if (e.code !== 'ZERO_RESULTS') {
      throw e
    }
  }
  return data
}

export function getLocationInfo(address, place) {
  const { latitude, longitude, postal_code: zip, locality: city } = address
  const {
    address_components: components,
    formatted_address: formattedAddress,
    place_id: placeId
  } = place
  const country = getComponent(components, 'country')?.short_name || ''
  const street = getStreet(formattedAddress, zip, country) || ''
  return {
    address: formattedAddress,
    street,
    zip,
    city,
    country,
    geoip: {
      latitude,
      longitude
    },
    placeId
  }
}

function getComponent(addressComponents, type) {
  return addressComponents.find((c) => c.types.includes(type))
}

function getStreet(formattedAddress, zip, country) {
  if (country === JAPAN_CODE) return ''

  const zipIndex = formattedAddress.lastIndexOf(zip)
  const trimmed = zipIndex
    ? formattedAddress.slice(0, zipIndex).trim()
    : formattedAddress
  const street = trimmed.slice(-1) === ',' ? trimmed.slice(0, -1) : trimmed
  return street
}

export function getGoogleMapLink(address, placeId) {
  const encodedAddress = encodeURIComponent(address) // address is required by google as backup query
  return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}&query_place_id=${placeId}`
}
