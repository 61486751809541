//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Validations from '~/config/validations'

export default {
  props: {
    org: {
      type: String,
      required: true
    },
    orgTree: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return { formData: { org: this.org } }
  },
  computed: {
    rules() {
      const { org } = Validations.buildValidationRules(this.$i18n.locale, {
        org: { required: true }
      })
      return { org }
    },
    options() {
      return this.orgTree.map((org) => {
        const { id, name } = org
        return {
          value: id,
          label: name,
          id,
          name
        }
      })
    }
  },
  watch: {
    org() {
      this.formData = { org: this.org }
    }
  },
  methods: {
    onReset({ org }) {
      this.formData = { org }
    }
  }
}
