//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AnimatableMixin from '~/mixins/AnimatableMixin'

export default {
  name: 'OrganismsParamsForm',
  mixins: [AnimatableMixin],
  props: {
    id: { type: String, default: null },
    title: { type: String, default: null },
    description: { type: String, default: null },
    readOnly: { type: Boolean, default: false },
    children: { type: Array, default: () => [] },
    value: { type: Object, required: true },
    isButtonHidden: { type: Boolean, default: false }
  },
  data() {
    return {
      // TODO: Workaround, because it is currently not possible to set initial values via, the value API
      formData: {},
      formError: false
    }
  },
  computed: {
    display() {
      return !!(Object.keys(this.value).length > 0)
    },
    showButtons() {
      if (this.isButtonHidden) return false
      return (
        this.readOnly ||
        this.loading ||
        this.children.filter((el) => !el.props.readOnly).length > 0
      )
    }
  },
  methods: {
    update(data) {
      if (data) {
        this.formData = data
      }
    },
    reset(data) {
      if (data) {
        this.formData = data
      }
    },
    submit(res) {
      if (res.validation.$invalid) {
        this.animate('formError')
      }
      res.formReset = this.$refs?.form?.formReset
      res.update = this.update.bind(this)
      res.formData = this.getChangedValues(res.data)
      this.$emit('submit', res)
    },
    getChangedValues(data) {
      return Object.entries(data).reduce((formData, [key, value]) => {
        const hasValueChanged = value !== this.value[key]

        if (hasValueChanged) {
          return { ...formData, [key]: value }
        }

        return formData
      }, {})
    }
  }
}
