//
//
//
//
//
//
//
//
//

import { ErrorType } from '~/lib/graphql/error'

export default {
  props: {
    thing: {
      type: Object,
      default: () => ({})
    },
    hasWarrantyPromo: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({ isLoading: false }),
  computed: {
    isOnline() {
      return this.$store.getters['queue/isOnline']
    }
  },
  methods: {
    async onSubmit(data) {
      this.isLoading = true
      try {
        await this.$store.dispatch('warranty/extend', data)
        const hasCouponCode = !!data.coupon_code
        this.$emit('close')
        this.notifySaveSuccess(hasCouponCode)
      } catch (e) {
        let message = 'extend_warranty.form.save_error'

        if (
          e.graphQLErrors?.[0]?.errorType === ErrorType.COUPON_CODE_NOT_FOUND
        ) {
          message = 'extend_warranty.form.coupon_code_error'
        }

        this.$store.dispatch('promiseDialog/open', {
          type: 'error',
          information: this.$t(message),
          showCancelButton: false
        })
      }
      this.isLoading = false
    },
    notifySaveSuccess(hasCouponCode = false) {
      const successMessage = this.$t(
        hasCouponCode
          ? 'extend_warranty.form.save_success_extra'
          : 'extend_warranty.form.save_success'
      )
      const submitPending = this.$t('extend_warranty.form.submit_pending')

      this.$store.dispatch('promiseDialog/open', {
        type: 'success',
        information: this.isOnline ? successMessage : submitPending,
        showCancelButton: false
      })
    }
  }
}
