var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"order-first flex flex-col xl:flex-shrink-0 border-r border-gray-200"},[_c('div',{staticClass:"px-4 pt-6 pb-4 bg-white border-b"},[_c('molecules-heading',{attrs:{"title":_vm.title}}),_vm._v(" "),_vm._t("header")],2),_vm._v(" "),_c('nav',{staticClass:"flex-1 overflow-y-scroll pb-safe",attrs:{"aria-label":_vm.title}},[_c('transition',{attrs:{"name":"fade-fast","mode":"out-in"}},[(!_vm.hasInfiniteScroll && _vm.loading)?_c('molecules-skeleton-list',{key:_vm.k,scopedSlots:_vm._u([(_vm.useSlot)?{key:"default",fn:function(ref){
var index = ref.index;
return [_vm._t("default",null,{"item":{},"index":index,"isLoading":_vm.loading})]}}:{key:"default",fn:function(ref){
var index = ref.index;
return [_c(_vm.component,{tag:"component",attrs:{"item":{},"index":index,"is-loading":_vm.loading}})]}}],null,true)}):(!_vm.loading && !_vm.hasItems)?_c('molecules-empty-state',{key:_vm.k,attrs:{"text":_vm.emptyText,"icon":_vm.emptyIcon}}):_c(_vm.listWrapper,_vm._g({tag:"component",attrs:{"has-more":_vm.hasMore,"loading":_vm.loading},scopedSlots:_vm._u([{key:"loadingIndicator",fn:function(){return [_c('molecules-skeleton-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],scopedSlots:_vm._u([(_vm.useSlot)?{key:"default",fn:function(ref){
var index = ref.index;
return [_vm._t("default",null,{"item":{},"index":index,"isLoading":_vm.loading})]}}:{key:"default",fn:function(ref){
var index = ref.index;
return [_c(_vm.component,{tag:"component",attrs:{"item":{},"index":index,"is-loading":_vm.loading}})]}}],null,true)})]},proxy:true}])},_vm.$listeners),[_c('ul',{key:_vm.k,staticClass:"relative z-0 space-y-px pb-2"},[(_vm.useSlot)?_vm._l((_vm.items),function(item,index){return _c('div',{key:item.id || index},[_vm._t("default",null,{"active":_vm.activeId && item.id === _vm.activeId,"item":item,"index":index})],2)}):_vm._l((_vm.items),function(item,index){return _c(_vm.component,{key:item.id || index,tag:"component",attrs:{"active":_vm.activeId && item.id === _vm.activeId,"item":item,"index":index,"tag":"li"}})})],2)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }