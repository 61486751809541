import { render, staticRenderFns } from "./Element.vue?vue&type=template&id=c86de0b2&"
import script from "./Element.vue?vue&type=script&lang=js&"
export * from "./Element.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesOrganizationIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/organization/Icon.vue').default,AtomsIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Icon.vue').default,MoleculesOrganizationTreeElementMembers: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/organization/tree/ElementMembers.vue').default})
