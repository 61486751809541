//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { State } from '~/models/thing/State'

export default {
  props: {
    things: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    displayedStatusCard() {
      const cards = []
      for (const status of [State.Green, State.Yellow, State.Red]) {
        if (this.thingStatus[status] && this.thingStatus[status].length > 0) {
          cards.push({
            title: this.thingStatus[status].length.toString(),
            subtitle: this.$t(`dashboard.summed_status.${status}`),
            status
          })
        }
      }
      return cards
    },
    thingStatus() {
      return {
        [State.Gray]: this.things.filter((t) => t.localState === State.Gray),
        [State.Green]: this.things.filter((t) => t.localState === State.Green),
        [State.Yellow]: this.things.filter(
          (t) => t.localState === State.Yellow
        ),
        [State.Red]: this.things.filter((t) => t.localState === State.Red)
      }
    }
  }
}
