//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Scroll2ViewMixin from '~/mixins/Scroll2ViewMixin'

export default {
  mixins: [Scroll2ViewMixin],
  props: {
    maintenanceTasks: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    hasConfirm: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    activeIndexes: {}
  }),
  methods: {
    toggle(index) {
      this.$set(this.activeIndexes, index, this.activeIndexes[index] !== true)
      if (this.activeIndexes[index] === false) return
      const task = this.maintenanceTasks[index]
      this.scroll2view(task.task_id)
    }
  }
}
