//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { groupBy } from 'lodash-es'
import { getLocalizedText } from '~/lib/utils/localization'

const confirmedByTextKey = {
  DEVICE: 'device',
  PRACTICE: 'practice',
  SERVICE_PARTNER: 'service_partner'
}

export default {
  props: {
    history: { type: Array, required: true }
  },
  computed: {
    groupByDateHistory() {
      return groupBy(this.history, (confirmation) =>
        this.$filters.date(confirmation.confirmedDate)
      )
    }
  },
  methods: {
    getLocalizedText(textObj) {
      return getLocalizedText(textObj, this.$i18n.locale)
    },
    getConfirmedByText(value) {
      return this.$t('maintenance.confirmed_by.' + confirmedByTextKey[value])
    }
  }
}
