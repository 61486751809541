//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    tag: {
      type: String,
      default: 'h3',
      validator: (value) => ['h2', 'h3', 'h4'].includes(value)
    },
    title: { type: String, default: null },
    icon: { type: String, default: null },
    description: { type: String, default: null },
    classes: { type: [String, Array], default: null }
  },
  computed: {
    classesArray() {
      if (Array.isArray(this.classes)) {
        return this.classes
      } else {
        return [this.classes]
      }
    },
    size() {
      switch (this.tag) {
        case 'h4':
          return 'text-lg sm:text-lg font-semibold'
        case 'h3':
          return 'text-xl sm:text-xl font-semibold'
        case 'h2':
          return 'text-2xl sm:text-2xl font-semibold'
        default:
          return 'text-2xl sm:text-2xl font-medium'
      }
    },
    iconSize() {
      switch (this.tag) {
        case 'h4':
          return 'h-5 w-5'
        case 'h3':
          return 'h-6 w-6'
        case 'h2':
        default:
          return 'h-7 w-7'
      }
    }
  }
}
