//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AtomsInfiniteList from '~/components/atoms/InfiniteList'

export default {
  props: {
    title: { type: String, default: undefined },
    activeId: { type: String, default: undefined },
    items: {
      type: Array,
      default: () => []
    },
    loading: { type: Boolean, default: false },
    emptyText: { type: String, default: undefined },
    emptyIcon: { type: String, default: undefined },
    component: { type: String, default: undefined },
    hasInfiniteScroll: { type: Boolean, default: false },
    hasMore: { type: Boolean, default: false }
  },
  computed: {
    hasItems() {
      return this.items?.length > 0
    },
    useSlot() {
      return !this.component
    },
    k() {
      if (this.loading) return 'loading'
      if (!this.hasItems) return 'empty'
      if (!this.useSlot) return 'slot'
      return 'component'
    },
    listWrapper() {
      if (this.hasInfiniteScroll) return AtomsInfiniteList
      return 'div'
    }
  }
}
