import { render, staticRenderFns } from "./Create.vue?vue&type=template&id=4e472c25&"
import script from "./Create.vue?vue&type=script&lang=js&"
export * from "./Create.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesFormInput: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/form/Input.vue').default,MoleculesForm: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Form.vue').default,MoleculesOrganizationListCreateElement: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/organization/list/CreateElement.vue').default})
