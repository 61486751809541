//
//
//
//
//
//
//
//
//

import { State, maintenanceDue } from '~/models/thing/State'

export default {
  props: {
    selectedStates: {
      type: Array,
      default: () => []
    },
    things: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    statesDropdownItems() {
      return this.states.map(this.createDropdownItem)
    },
    states() {
      return [State.Red, State.Yellow, State.Green, State.Gray, maintenanceDue]
    }
  },
  methods: {
    createDropdownItem(state) {
      return { label: this.$t(`thing.state.${state}`), key: state }
    }
  }
}
