import { render, staticRenderFns } from "./FormWide.vue?vue&type=template&id=6028114e&"
import script from "./FormWide.vue?vue&type=script&lang=js&"
export * from "./FormWide.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports