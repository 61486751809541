//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NotificationMixin from '~/mixins/notification-mixin/NotificationMixin'

export default {
  name: 'MoleculesFormPhotos',
  mixins: [NotificationMixin],
  inheritAttrs: false,
  props: {
    value: {
      type: Array,
      required: true
    },
    hasError: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    photos() {
      return this.value
    },
    hasPhotos() {
      return this.photos.length > 0
    }
  },
  methods: {
    async addPhoto() {
      const photo = await this.$platform.native.camera.getPhoto()
      const photos = [...this.photos, photo]

      if (photo.hasError) {
        return this.notifyError(photo.errorMessage)
      }

      this.updatePhotos(photos)
    },
    removePhoto(index) {
      const photos = this.photos.filter((_, i) => i !== index)

      this.updatePhotos(photos)
    },
    updatePhotos(photos) {
      this.$emit('input', photos)
    },
    isLastPhotoInPhotos(index) {
      return index + 1 === this.photos.length
    },
    createImageSource(photo) {
      return `data:image/${photo.format};base64,${photo.base64String}`
    }
  }
}
