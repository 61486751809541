//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { template } from 'lodash-es'
import openUrl from '~/lib/url/openUrl'

export default {
  props: {
    icon: { type: String, default: 'map' },
    title: { type: String, default: 'not found' },
    description: { type: String, default: 'not found' },
    buttonIcon: { type: String, default: 'map' },
    buttonText: { type: String, default: 'action' },
    buttonLink: { type: String, default: 'target' }
  },
  methods: {
    buttonClick() {
      let target = this.$envConfig.navigation.main.find(
        (n) => n.key === this.buttonLink
      )
      if (!target) {
        // not a target in main navigation, check in user navigation as well
        target = this.$envConfig.navigation.user[1].find(
          (n) => n.key === this.buttonLink
        )
      }
      if (target.route) {
        this.$router.push(target.route)
      } else if (target.url) {
        const compile = template(target.url)
        openUrl(
          compile({ LANGUAGE: this.$i18n.localeProperties.code.toUpperCase() }),
          '_blank'
        )
      }
    }
  }
}
