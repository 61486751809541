//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    subscription: {
      type: Object,
      required: true
    },
    organizationId: {
      type: String,
      required: true
    }
  },
  computed: {
    text() {
      if (this.isOwner) {
        const { protocol } = this.subscription
        const protocolText = this.$t(
          `settings.subscription.protocol.${protocol}`
        )
        const protocolLabel = this.$t('settings.subscription.select_protocol')
        return `${protocolLabel}: ${protocolText}`
      }
      return this.$t('settings.subscription.belong_to_another_org')
    },
    dropdownItems() {
      return [
        {
          label: this.$t('buttons.delete'),
          icon: 'trash',
          callback: this.onDelete.bind(this)
        }
      ]
    },
    isOwner() {
      return this.subscription.orgId === this.organizationId
    }
  },
  methods: {
    onDropdownSelect(callback, close) {
      callback(close)
    },
    onDelete(close) {
      this.$emit('delete')
      close()
    }
  }
}
