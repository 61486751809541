//
//
//
//
//
//
//
//

import { getCodeList } from 'country-list'

export default {
  name: 'MoleculesFormCountrySelect',
  props: {
    value: {
      type: String,
      default: undefined
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    codeList() {
      return getCodeList()
    },
    countryList() {
      return Object.keys(this.codeList)
        .map((c) => ({ id: c, value: this.codeList[c] }))
        .sort((a, b) => (a.value === b.value ? 0 : a.value > b.value ? 1 : -1))
    }
  }
}
