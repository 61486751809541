import { render, staticRenderFns } from "./Element.vue?vue&type=template&id=7aee08a2&"
import script from "./Element.vue?vue&type=script&lang=js&"
export * from "./Element.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesMembersAvatar: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/members/Avatar.vue').default,AtomsSkeleton: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Skeleton.vue').default,MoleculesButton: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Button.vue').default,MoleculesDropdown: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Dropdown.vue').default})
