//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    color: {
      type: String,
      default: 'green',
      validator(val) {
        return ['green', 'blue', 'red'].includes(val)
      }
    }
  },
  computed: {
    innerColor() {
      const classMap = {
        green: 'bg-green-600',
        blue: 'bg-blue-600',
        red: 'bg-red-600'
      }
      return classMap[this.color]
    }
  }
}
