//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    organization: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDeletePossible() {
      return !this.isRoot && !this.hasChildren
    },
    isRoot() {
      return this.$store.getters['organization/isRoot'](this.organization.id)
    },
    hasChildren() {
      return this.$store.getters['organization/hasChildren'](
        this.organization.id
      )
    }
  }
}
