import { MutationTree } from 'vuex'
import { LegalState } from '~/store/legal/state'
import { LegalDocument } from '~/models/legal/LegalDocument'

export default {
  add(state, newDocument: LegalDocument) {
    const { documents } = state
    const emptyDocument = LegalDocument.empty()
    const document = documents.find((d) => d.is(newDocument)) || emptyDocument

    if (document.isEmpty) {
      state.documents.push(newDocument)
    }
  }
} as MutationTree<LegalState>
