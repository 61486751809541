import { ActionHandler } from 'vuex'
import { IRootState } from '~/store'
import { LegalState } from '~/store/legal/state'
import { LegalDocumentId } from '~/models/legal/LegalDocumentId'
import { LegalDocument } from '~/models/legal/LegalDocument'

export default {
  async load({ commit, dispatch, getters }, id: LegalDocumentId) {
    if (getters.has(id)) return

    try {
      const document = await this.$api.legal.get(id)
      commit('add', document)
    } catch {
      dispatch('loadDefault', id)
    }
  },
  async loadDefault({ commit, getters }, id: LegalDocumentId) {
    if (getters.has(id)) return

    const { type, version } = id
    const { defaultLocale } = this.$i18n
    const defaultId = new LegalDocumentId(type, version, defaultLocale)
    const defaultDocument = await this.$api.legal.get(defaultId)
    const document = new LegalDocument(id, defaultDocument.content)

    commit('add', document)
  }
} as { [handler: string]: ActionHandler<LegalState, IRootState> }
