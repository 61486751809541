import { render, staticRenderFns } from "./TabSelect.vue?vue&type=template&id=0bc24b17&"
import script from "./TabSelect.vue?vue&type=script&lang=js&"
export * from "./TabSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Icon.vue').default,MoleculesDropdown: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Dropdown.vue').default})
