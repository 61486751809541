import { render, staticRenderFns } from "./AddThingModal.vue?vue&type=template&id=6e47a2bf&"
import script from "./AddThingModal.vue?vue&type=script&lang=js&"
export * from "./AddThingModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesHeading: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Heading.vue').default,MoleculesImage: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Image.vue').default,MoleculesIconText: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/IconText.vue').default,MoleculesButton: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Button.vue').default,Modal: require('/builds/duerrdental/vistor/frontend/vsm/components/Modal.vue').default})
