//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MoleculesFormRadio',
  inheritAttrs: false,
  props: {
    value: { type: String, default: null },
    name: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    options: { type: Array, default: () => [] }
  },
  data() {
    return {
      val: this.value
    }
  },
  watch: {
    value() {
      this.val = this.value
    }
  },
  methods: {
    getLabel(label) {
      const value = 'remote_operations.radio.options.' + label.toLowerCase()
      return this.$te(value)
        ? this.$t(value)
        : this.$te(value, this.$i18n.fallbackLocale)
        ? this.$t(value, this.$i18n.fallbackLocale)
        : label
    }
  }
}
