export default {
  methods: {
    scroll2view(selector, options) {
      setTimeout(() => {
        try {
          const element = document.getElementById(selector)
          if (!element) return
          if (this.isScrolledIntoView(element)) return
          element.scrollIntoView({
            ...options,
            behavior: 'smooth'
          })
        } catch (err) {}
      }, 200)
    },
    isScrolledIntoView(el) {
      const rect = el.getBoundingClientRect()
      const elemTop = rect.top
      const elemBottom = rect.bottom
      const isVisible = elemTop >= 110 && elemBottom <= window.innerHeight
      return isVisible
    }
  }
}
