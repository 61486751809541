import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=67b44164&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesHeading: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Heading.vue').default,AtomsSkeleton: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Skeleton.vue').default,MoleculesIconText: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/IconText.vue').default})
