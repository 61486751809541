import { render, staticRenderFns } from "./default.vue?vue&type=template&id=41848d64&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsNavigation: require('/builds/duerrdental/vistor/frontend/vsm/components/organisms/Navigation.vue').default,MoleculesLoadingScreen: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/LoadingScreen.vue').default,MoleculesNotification: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Notification.vue').default,OrganismsNotifications: require('/builds/duerrdental/vistor/frontend/vsm/components/organisms/Notifications.vue').default,OrganismsConfirmationModal: require('/builds/duerrdental/vistor/frontend/vsm/components/organisms/ConfirmationModal.vue').default})
