//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import find from 'lodash-es/find'

export default {
  props: {
    selectedItems: {
      type: Array,
      default: () => []
    },
    dropdownItems: {
      type: Array,
      default: () => []
    },
    toggleText: {
      type: String,
      required: true
    },
    position: {
      type: String,
      default: 'bottom-end'
    },
    menuClass: {
      type: String,
      default: undefined
    }
  },
  computed: {
    categoriesDropdownItems() {
      return this.processNestedItems(this.dropdownItems)
        .map(this.addDropdownIcon)
        .map(this.addDropdownCallback)
    }
  },
  methods: {
    processNestedItems(orgs, depth = 0) {
      const items = []
      orgs.forEach((item) => {
        item.key = item.id
        item.depth = depth
        item.label = item.name
        items.push(item)
        if (item.corg) {
          items.push(...this.processNestedItems(item.corg, depth + 1))
        }
      })
      return items
    },
    onCategorySelect({ callback }) {
      callback()
    },
    addDropdownIcon(dropdownItem) {
      return {
        ...dropdownItem,
        icon: this.getCheckedIcon(dropdownItem.key)
      }
    },
    addDropdownCallback(dropdownItem) {
      return {
        ...dropdownItem,
        callback: this.toggleItem.bind(this, dropdownItem.key)
      }
    },
    getCheckedIcon(category) {
      if (this.selectedItems.includes(category)) {
        return 'check'
      }

      return null
    },
    toggleItem(key) {
      const selected = new Set([...this.selectedItems])
      const toggleOn = selected.has(key) !== true

      if (toggleOn) {
        selected.add(key)
      } else {
        selected.delete(key)
      }

      this.toggleChildren(key, selected, toggleOn)

      this.$emit('update', Array.from(selected))
    },
    toggleChildren(key, selected, toggleOn) {
      const item = find(this.categoriesDropdownItems, { key })
      const corgs = item?.corg ? Object.values(item?.corg) : []

      if (corgs.length > 0) {
        corgs.forEach((child) => {
          if (toggleOn === true) {
            selected.add(child.id)
          } else {
            selected.delete(child.id)
          }
          this.toggleChildren(child.key, selected, toggleOn)
        })
      }
    }
  }
}
