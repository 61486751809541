export const calcValue = (value: number, min: number, max: number): number => {
  if (min) return value - min
  else if (max) return max - value
  else return value
}

export const roundToPrecision = (
  value: string | number,
  precision: number
): string | number => {
  const baseValue = typeof value === 'string' ? parseFloat(value) : value
  if (isNaN(baseValue) || precision < 0) return value

  return (
    Math.round((baseValue + Number.EPSILON) * Math.pow(10, precision)) /
    Math.pow(10, precision)
  ).toString()
}

export const isNumericRegex = (value: string | number | undefined): boolean => {
  return String(value)?.match(/^\d+[.]?[\d]*$/)?.length === 1
}

export const normalizePercent = (val: number): number => {
  // fence val between 0 and 1
  return Math.max(0, Math.min(val || 0, 1))
}
