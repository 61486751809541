//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    index: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'metadata'
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    isValueWithSpace() {
      return this.value?.indexOf(' ') !== -1
    },
    _label() {
      const label = this.isValueWithSpace
        ? this.value?.substr(0, this.value.indexOf(' '))
        : this.value
      return label && label.replace(/([/\-.])/, '$1<wbr>') // make word breaks possible
    },
    _subline() {
      return (
        this.isValueWithSpace &&
        this.value
          ?.substr(this.value.indexOf(' '))
          .replace(/([/\-.])/, '$1<wbr>')
      ) // make word breaks possible
    }
  }
}
