//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import THING_ORGANIZATION_MUTATION from '~/queries/thing/organization.mutation.gql'
import THING_NO_ANALYTICS_MUTATION from '~/queries/thing/no_analytics.mutation.gql'
import THING_DELETE_MUTATION from '~/queries/thing/delete.mutation.gql'
import removeThingFromCache from '~/lib/thing/removeThingFromCache'
import AnimatableMixin from '~/mixins/AnimatableMixin'
import NotificationMixin from '~/mixins/notification-mixin/NotificationMixin'

export default {
  mixins: [AnimatableMixin, NotificationMixin],
  props: {
    title: {
      type: String,
      required: true
    },
    thing: {
      type: Object,
      required: true
    },
    orgTree: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      thingSettingsMarkModalOpen: false,
      thingSettingsRemoveModalOpen: false,
      formError: false,
      markFormError: false,
      removeFormError: false,
      isLoadingLocation: false,
      isLoadingMark: false,
      isLoadingRemove: false
    }
  },
  computed: {
    isUserRootOrg() {
      return this.$store.getters['user/rorg'] === this.thing.rorg
    },
    showMarkTestDevice() {
      return (
        this.$store.getters['user/isInternal'] ||
        this.$store.getters['user/isServicePartner']
      )
    }
  },
  methods: {
    async onSubmit({ data, validation }) {
      this.isLoadingLocation = true
      if (validation.$invalid) {
        this.animate('formError')
        return
      }
      if (this.thing.org === data.org) {
        this.notifyInfo(
          this.$t('sources_details_settings.save_to_existing_org')
        )
        this.$emit('close')
        return
      }
      await this.$apollo
        .mutate({
          mutation: THING_ORGANIZATION_MUTATION,
          variables: {
            thingId: this.thing.id,
            org: data.org
          }
        })
        .then(() => {
          this.notifySuccess(
            this.$t('sources_details_settings.save_org_success')
          )
          this.$emit('close')
        })
        .catch(() => {
          this.notifyError(this.$t('sources_details_settings.save_org_error'))
        })

      this.isLoadingLocation = false
    },
    async onMarkDevice({ validation }) {
      this.isLoadingMark = true
      if (validation.$invalid) {
        this.animate('markFormError')
        this.isLoadingMark = false
      } else {
        await this.$apollo
          .mutate({
            mutation: THING_NO_ANALYTICS_MUTATION,
            variables: {
              thingId: this.thing.id,
              noAnalytics: true
            }
          })
          .then(() => {
            this.notifySuccess(
              this.$t('sources_details_settings.mark_test_device_success')
            )
            this.$emit('close')
          })
          .catch(() => {
            this.notifyError(
              this.$t('sources_details_settings.mark_test_device_error')
            )
          })
      }
      this.isLoadingMark = false
    },
    async onRemoveDevice({ validation }) {
      this.isLoadingRemove = true
      if (validation.$invalid) {
        this.animate('removeFormError')
        this.isLoadingRemove = false
      } else {
        await this.$apollo
          .mutate({
            mutation: THING_DELETE_MUTATION,
            variables: {
              thingId: this.thing.id
            },
            update: (cache, { data: { deleteMutation } }) => {
              removeThingFromCache(cache, deleteMutation.id)
            }
          })
          .then(() => {
            this.notifySuccess(
              this.$t('sources_details_settings.remove_success')
            )
            this.$emit('close')
            this.$router.push('/things')
          })
          .catch(() => {
            this.notifyError(this.$t('sources_details_settings.remove_error'))
          })
      }
      this.isLoadingRemove = false
    }
  }
}
