//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    showSkeleton: { type: Boolean, default: true }
  }
}
