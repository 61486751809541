//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    organizationId: {
      type: String,
      required: true
    }
  }
}
