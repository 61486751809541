import { render, staticRenderFns } from "./NewDevice.vue?vue&type=template&id=648c09a1&"
import script from "./NewDevice.vue?vue&type=script&lang=js&"
export * from "./NewDevice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesButton: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Button.vue').default,AtomsSkeleton: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Skeleton.vue').default,MoleculesInboxBody: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/inbox/Body.vue').default})
