
import random from 'lodash-es/random'
const skeletonClass = 'bg-gray-200 text-transparent opacity-25 animate-pulse'
const seed = [
  'curabitur',
  'eleifend',
  'blandit',
  'libero',
  'lorem',
  'nunc',
  'sit',
  'ut',
  'a'
]
const sentance = (min, max) => {
  const length = random(min, max)
  const words = []
  for (let i = 0; i < length; i++) {
    words.push(seed[random(0, seed.length - 1)])
  }
  return words.join(' ')
}

export default {
  props: {
    show: { type: Boolean, default: false },
    min: { type: Number, default: 5 },
    max: { type: Number, default: 10 },
    index: { type: Number, default: 0 }
  },
  computed: {
    style() {
      return `animation-delay: ${this.index * 200}ms`
    }
  },
  render(createElement) {
    const children = this.$scopedSlots.default()
    const child = children.length === 1 && children[0]
    const hasNode = child && child.tag

    if (!this.show) {
      return children
    } else if (!hasNode) {
      const el = createElement('mark', { class: skeletonClass }, children)
      return el
    } else if (
      ['img', 'svg', 'div'].includes(child.tag) ||
      child.tag.includes('vue-component')
    ) {
      const el = createElement('div', {
        class: [child.data?.staticClass, skeletonClass].join(' '),
        style: this.style
      })
      return el
    } else {
      const el = createElement(
        child.tag,
        {
          class: [child.data?.staticClass, 'text-transparent'].join(' '),
          style: this.style
        },
        [
          createElement(
            'mark',
            {
              class: skeletonClass,
              style: this.style
            },
            sentance(this.min, this.max)
          )
        ]
      )
      return el
    }
  }
}
