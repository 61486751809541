//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    organization: {
      type: Object,
      required: true
    },
    onPlaceChanged: {
      type: Function,
      required: true
    }
  }
}
