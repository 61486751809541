//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'OrganismsParamsButton',
  inject: {
    $parentForm: {
      default: null
    }
  },
  props: {
    id: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    ariaLabel: {
      type: String,
      default: undefined
    }
  },
  methods: {
    onClick() {
      const payload = {
        data: { [this.name]: '1' },
        validation: { $invalid: false, $dirty: true },
        finishLoading: () => null
      }

      this.$parentForm.$emit('submit', payload)
    }
  }
}
