//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Validations from '~/config/validations'

export default {
  props: {
    thing: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    formData: {
      phoneNumber: '',
      description: '',
      photos: []
    }
  }),
  computed: {
    rules() {
      const definitions = {
        phoneNumber: { required: true },
        description: { required: true },
        photos: {}
      }

      const {
        phoneNumber,
        description,
        photos
      } = Validations.buildValidationRules(this.$i18n.locale, definitions)

      return { phoneNumber, description, photos }
    }
  },
  methods: {
    submit() {
      this.$refs.form.handleSubmit()
    }
  }
}
