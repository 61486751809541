//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    isAdmin() {
      return this.$store.getters['user/isAdmin']
    },
    rootOrg() {
      return this.$store.getters['user/rorg']
    }
  },
  methods: {
    linkToAddress() {
      this.$router.push(`/settings/organization/${this.rootOrg}/edit/details`)
    }
  }
}
