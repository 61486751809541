var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"flex cursor-pointer",attrs:{"tag":"li","to":{
    name: _vm.item.route
  },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var isActive = ref.isActive;
  var href = ref.href;
  var navigate = ref.navigate;
return [_c('span',{staticClass:"flex pl-3"},[_c('a',{class:[
        'flex w-full py-2 items-center',
        _vm.isolated ? 'rounded-md mr-2' : 'rounded-l-md',
        isActive
          ? 'text-primary-900 bg-primary-50'
          : 'text-primary-200 hover:text-white hover:bg-primary-700 hover:bg-opacity-75'
      ],attrs:{"href":href},on:{"click":function (e) {
          isActive && _vm.$parent.$emit('close')
          navigate(e)
        }}},[_c('div',{staticClass:"h-6 w-6 sm:h-5 sm:w-5 mx-3 flex-shrink-0"},[_c('atoms-icon',{staticClass:"h-full w-full",attrs:{"name":((isActive ? 'outline' : 'solid') + "/" + (_vm.item.icon))}})],1),_vm._v(" "),_c('span',{staticClass:"pr-3 flex-grow line-clamp-1 break-all"},[_vm._v("\n        "+_vm._s(_vm.$t(_vm.item.label))+"\n      ")]),_vm._v(" "),(_vm.badgeCount)?_c('atoms-badge',{staticClass:"place-self-end mx-3",attrs:{"color":"primary","counter":""}},[_vm._v("\n        "+_vm._s(_vm.badgeCount)+"\n      ")]):_vm._e(),_vm._v(" "),_vm._t("default",null,{"link":_vm.item})],2)])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }