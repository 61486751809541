//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const iconMap = {
  'no-category': 'color-swatch',
  brochure: 'book-open',
  'declaration of confirmity': 'scale',
  image: 'photograph',
  'maintenance and repair instructions': 'cog',
  movie: 'film',
  'planning data': 'calendar',
  presentation: 'presentation-chart-bar',
  service: 'support',
  'short introduction': 'book-open',
  software: 'code',
  'installation instructions': 'book-open',
  'operating instructions': 'book-open',
  other: 'document',
  manual: 'document-text',
  information: 'information-circle'
}

export default {
  props: {
    id: { type: String, default: null },
    title: { type: String, default: null },
    docs: { type: Array, default: () => [] },
    active: { type: Boolean, default: false },
    index: { type: Number, default: 0 },
    product: { type: Object, required: true }
  },
  computed: {
    showSkeleton() {
      return this.docs.length < 1
    }
  },
  methods: {
    icon(categoryId) {
      if (categoryId && iconMap[categoryId]) {
        return iconMap[categoryId]
      }
      return iconMap['no-category']
    }
  }
}
