//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    options: { type: Array, required: true },
    value: { type: [Number, String, Object], default: undefined },
    disabled: { type: Boolean, default: false }
  },
  computed: {}
}
