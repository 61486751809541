//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isEqual } from 'lodash-es'
import DISPOSAL_QUERY from '~/queries/disposal/disposal.gql'
import DISPOSAL_MUTATION from '~/queries/disposal/disposal.mutation.gql'
import Validations from '~/config/validations'
import NotificationMixin from '~/mixins/notification-mixin/NotificationMixin'

const WasteWatchToggle = {
  ON: 'ENABLED',
  OFF: 'DISABLED'
}

const OptInStatus = {
  PENDING: 'pending',
  EXPIRED: 'expired',
  OPTED_IN: 'opted_in',
  OPTED_OUT: 'opted_out'
}

const WasteWatchStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
}

export default {
  mixins: [NotificationMixin],
  props: {
    thing: { type: Object, default: () => {} }
  },
  apollo: {
    disposal: {
      query: DISPOSAL_QUERY,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          thingId: this.thing?.id
        }
      },
      update({ disposalQuery }) {
        this.isWasteWatchOn =
          disposalQuery.disposal?.wasteWatchStatus === WasteWatchToggle.ON
        return disposalQuery.disposal
      }
    }
  },
  data: () => ({
    isSaving: false,
    isWasteWatchOn: false,
    isEmailChanged: false
  }),
  computed: {
    thingHasValidOrg() {
      return this.$store.getters['organization/isValidOrg'](this.thing?.org)
    },
    disposalStatus() {
      return OptInStatus[this.disposal?.disposalCompany?.optInStatus]
    },
    supplierStatus() {
      return OptInStatus[this.disposal?.supplierCompany?.optInStatus]
    },
    wasteWatchStatus() {
      const isWasteWatchEnabled = this.formData.wasteWatchToggle
      if (!isWasteWatchEnabled) return ''
      if (
        this.disposalStatus === OptInStatus.OPTED_IN &&
        this.supplierStatus === OptInStatus.OPTED_IN
      ) {
        return WasteWatchStatus.ACTIVE
      }
      return WasteWatchStatus.INACTIVE
    },
    wasteWatchColor() {
      if (this.wasteWatchStatus === WasteWatchStatus.ACTIVE) return 'green'
      return 'gray'
    },
    formData() {
      return {
        disposalName: this.disposal?.disposalCompany?.name || '',
        disposalEmail: this.disposal?.disposalCompany?.email || '',
        supplierName: this.disposal?.supplierCompany?.name || '',
        supplierEmail: this.disposal?.supplierCompany?.email || '',
        wasteWatchToggle:
          this.disposal?.wasteWatchStatus === WasteWatchToggle.ON || false,
        dataPrivacy: false
      }
    },
    isAdmin() {
      return this.$store.getters['user/isAdmin']
    },
    readOnly() {
      return this.isSaving || !this.isAdmin
    },
    showDataPrivacy() {
      const wasWasteWatchOff = !this.formData.wasteWatchToggle
      return this.isWasteWatchOn && (this.isEmailChanged || wasWasteWatchOff)
    },
    rules() {
      const definitions = {
        disposalName: { required: this.isWasteWatchOn },
        disposalEmail: { required: this.isWasteWatchOn, email: true },
        supplierName: { required: this.isWasteWatchOn },
        supplierEmail: { required: this.isWasteWatchOn, email: true },
        wasteWatchToggle: {},
        dataPrivacy: {
          customValidation: (value) => !this.showDataPrivacy || value === true
        }
      }
      const {
        disposalName,
        disposalEmail,
        supplierName,
        supplierEmail,
        wasteWatchToggle,
        dataPrivacy
      } = Validations.buildValidationRules(this.$i18n.locale, definitions)
      return {
        disposalName,
        disposalEmail,
        supplierName,
        supplierEmail,
        wasteWatchToggle,
        dataPrivacy
      }
    }
  },
  watch: {
    disposal() {
      this.onUpdate()
    }
  },
  methods: {
    getColor(status) {
      switch (status) {
        case OptInStatus.PENDING:
          return 'yellow'
        case OptInStatus.EXPIRED:
        case OptInStatus.OPTED_OUT:
          return 'red'
        case OptInStatus.OPTED_IN:
          return 'green'
        default:
          return ''
      }
    },
    onChange(name, value) {
      if (name === 'wasteWatchToggle') {
        this.isWasteWatchOn = value
      }
      if (['supplierEmail', 'disposalEmail'].includes(name)) {
        this.isEmailChanged = true
      }
    },
    async onSubmit({ data, validation, formReset }) {
      if (validation.$invalid) return

      if (isEqual(data, this.formData)) {
        this.notifyInfo(this.$t('disposal.no_changes'))
        return
      }

      this.isSaving = true
      await this.submit(data)
      formReset()
      this.isEmailChanged = false
      this.isSaving = false
    },
    async submit(data) {
      try {
        const {
          disposalName,
          disposalEmail,
          supplierName,
          supplierEmail,
          wasteWatchToggle
        } = data
        await this.$apollo.mutate({
          mutation: DISPOSAL_MUTATION,
          variables: {
            input: {
              thingId: this.thing.id,
              disposalName,
              disposalEmail,
              supplierName,
              supplierEmail,
              wasteWatchStatus: wasteWatchToggle
                ? WasteWatchToggle.ON
                : WasteWatchToggle.OFF
            }
          },
          update: (cache, { data: { setDisposal } }) => {
            const query = DISPOSAL_QUERY
            const variables = { thingId: this.thing.id }
            const data = cache.readQuery({
              query,
              variables
            })
            data.disposalQuery.disposal = { ...setDisposal }
            cache.writeQuery({
              query,
              variables,
              data
            })
          }
        })
        this.notifySuccess(this.$t('disposal.save_success'))
      } catch {
        this.notifyError(this.$t('disposal.save_error'))
      }
    },
    getAlert(partnerStatus) {
      switch (partnerStatus) {
        case OptInStatus.PENDING:
          return {
            type: 'info',
            message: 'disposal.alert.pending'
          }
        case OptInStatus.EXPIRED:
          return {
            type: 'warning',
            message: 'disposal.alert.expired'
          }
        case OptInStatus.OPTED_OUT:
          return {
            type: 'warning',
            message: 'disposal.alert.opted_out'
          }
        default:
          return {}
      }
    },
    mergeAlerts(alerts = []) {
      const alertPriority = ['warning', 'info']

      for (const type of alertPriority) {
        const messages = alerts.reduce((filteredMessages, alert) => {
          if (
            alert.type === type &&
            !filteredMessages.includes(alert.message)
          ) {
            filteredMessages.push(alert.message)
          }
          return filteredMessages
        }, [])
        if (messages.length > 0) {
          return { title: 'disposal.alert.title', type, messages }
        }
      }
    },
    onUpdate() {
      const disposalAlert = this.getAlert(this.disposalStatus)
      const supplierAlert = this.getAlert(this.supplierStatus)
      const mergedAlert = this.mergeAlerts([disposalAlert, supplierAlert])
      this.$emit('alert', mergedAlert)
    }
  }
}
