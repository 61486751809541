/* eslint-disable camelcase */
import Validations from '~/config/validations'

const staticDefinitions = {
  practice: { required: true },
  street: { required: true },
  zip: { required: true },
  town: { required: true },
  country: { required: true },
  email: { required: true },
  telephone: { required: true },
  honorific: { required: true },
  title: {},
  firstname: { required: true },
  lastname: { required: true },
  dealer_name: { required: true },
  dealer_town: { required: true },
  dealer_email: { email: true },
  dealer_telephone: {},
  tos: { required: true, checked: (value: boolean) => value },
  termsNewProduct: { required: true, checked: (value: boolean) => value },
  dataProcessing: { required: true, checked: (value: boolean) => value },
  warranty: { required: true, checked: (value: boolean) => value },
  installation: { required: true, checked: (value: boolean) => value },
  news: { required: false },
  coupon_code: { required: false }
}

export default (locale: string, dynamicDefinitions?: object) => {
  const definitions = { ...dynamicDefinitions, ...staticDefinitions }
  const rules = Validations.buildValidationRules(locale, definitions) as any
  const warrantyRules = {} as any
  for (const definitionName of Object.keys(definitions)) {
    warrantyRules[definitionName] = rules[definitionName]
  }
  return warrantyRules
}
