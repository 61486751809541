//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    show: { type: Boolean, default: false },
    count: { type: Number, default: 0 },
    color: { type: String, default: '' }
  }
}
