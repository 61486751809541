var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_tid_maintenance my-4",class:[
    'group',
    _vm.isActive
      ? 'bg-white border-primary-300 border-b border-t sm:border'
      : 'border border-dotted border-gray-300 hover:bg-gray-50 hover:border-solid hover:border-gray-300'
  ],attrs:{"id":_vm.id}},[_c('div',{staticClass:"cursor-pointer"},[_c('div',{class:[
        'transition-all',
        'flex p-4 justify-between items-center',
        _vm.isActive ? 'py-5' : 'py-4'
      ],on:{"click":_vm.onToggle}},[_c('div',{staticClass:"flex"},[_c('atoms-skeleton',{attrs:{"index":_vm.index,"min":3,"max":8,"show":_vm.showSkeleton}},[(_vm.showSkeleton)?_c('span',[_vm._v(" Loading ")]):_c('div',[_c('molecules-heading',{attrs:{"tag":"h4","title":_vm.getLocalizedText(_vm.title),"classes":[
                '_tid_maintenance-title !text-sm font-semibold tracking-wide leading-none uppercase',
                _vm.isActive ? 'text-primary-500' : 'text-gray-500/90'
              ]}}),_vm._v(" "),(_vm.showDue)?_c('div',[_c('atoms-icon',{class:[
                  'w-4 h-4 inline',
                  _vm.isDue ? 'text-yellow-400' : 'text-gray-500/80'
                ],attrs:{"name":"clock"}}),_vm._v(" "),_c('small',{staticClass:"_tid_maintenance_due text-gray-500/80 text-xs"},[_vm._v(_vm._s(_vm.due))])],1):_vm._e()],1)])],1),_vm._v(" "),(!_vm.showSkeleton)?_c('atoms-icon',{class:[
          '_tid_maintenance-icon w-4 h-4 transition-transform',
          _vm.isActive
            ? 'text-gray-500 rotate-180'
            : 'text-gray-400 group-hover:text-gray-500'
        ],attrs:{"name":"chevron-down"}}):_vm._e()],1),_vm._v(" "),(_vm.isActive)?_c('div',{staticClass:"px-4",on:{"click":_vm.onToggle}},[(_vm.deviceCategories.length > 0)?_c('div',_vm._l((_vm.deviceCategories),function(deviceCategory,categoryIndex){return _c('div',{key:categoryIndex},[_c('molecules-heading',{attrs:{"tag":"h4","title":_vm.$t(("_api.product_category." + deviceCategory)),"classes":[
              '!text-sm text-gray-700',
              ("_tid_maintenance-device-category-" + categoryIndex)
            ]}}),_vm._v(" "),_c('molecules-transition-list',{staticClass:"pt-2 space-y-2.5 px-4 pb-2 list-decimal tracking-wide text-[0.82em] text-gray-500",attrs:{"tag":"ol"}},_vm._l((_vm.getCategorizedSteps(deviceCategory)),function(step,stepIndex){return _c('li',{key:("step-" + _vm.index + "-" + stepIndex),class:("_tid_maintenance-step-" + stepIndex)},[_vm._v("\n              "+_vm._s(((_vm.getLocalizedText(step.text)) + " " + (_vm.getPartNumbers(step.parts))))+"\n            ")])}),0)],1)}),0):_c('div',[_c('molecules-transition-list',{staticClass:"pt-2 space-y-2.5 px-8 pb-2 list-decimal tracking-wide text-[0.82em] text-gray-500",attrs:{"tag":"ol"}},_vm._l((_vm.steps),function(step,stepIndex){return _c('li',{key:("step-" + _vm.index + "-" + stepIndex),class:("_tid_maintenance-step-" + stepIndex)},[_vm._v("\n            "+_vm._s(((_vm.getLocalizedText(step.text)) + " " + (_vm.getPartNumbers(step.parts))))+"\n          ")])}),0)],1)]):_vm._e()]),_vm._v(" "),(_vm.isActive)?_c('div',{staticClass:"_tid_maintenance-footer"},[_c('div',{staticClass:"flex justify-end items-end px-4 pb-4"},[_c('div',{staticClass:"flex"},[(_vm.isConfirmable)?_c('molecules-button',{staticClass:"_tid_maintenance-button",attrs:{"primary":_vm.isActive,"tertiary":!_vm.isActive,"rounded":"","color":"green"},on:{"click":function($event){return _vm.$emit('openModal', _vm.id)}}},[_vm._v("\n          "+_vm._s(_vm.$t('buttons.confirm'))+"\n        ")]):_vm._e()],1)])]):_vm._e(),_vm._v(" "),(_vm.isActive && _vm.parts.length > 0)?_c('div',{class:[
      'bg-gray-50 border-t px-2 transition-all',
      _vm.showParts ? 'pt-3' : 'pt-0'
    ],attrs:{"id":_vm.partsId}},[(_vm.showParts)?_c('h5',{staticClass:"text-gray-500/80 uppercase text-[0.7em] font-semibold tracking-wide px-4 pb-1"},[_vm._v("\n      "+_vm._s(_vm.$t('maintenance.parts'))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.showParts)?_c('ul',{staticClass:"py-2 space-y-2 px-4 list text-[0.85em] text-gray-500"},_vm._l((_vm.parts),function(part,idx2){return _c('li',{key:("part-" + _vm.index + "-" + idx2),class:("_tid_maintenance-part-" + _vm.index + "-" + idx2)},[_vm._v("\n        ("+_vm._s(part.number)+") "),_c('b',{staticClass:"font-semibold"},[_vm._v(_vm._s(part.ref))]),_vm._v(" –\n        "+_vm._s(_vm.getLocalizedText(part.name))+"\n      ")])}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"_tid_show-parts text-[0.6em] font-medium uppercase text-gray-400 text-center hover:text-gray-600 p-2 cursor-pointer",on:{"click":_vm.onToggleParts}},[_vm._v("\n      "+_vm._s(_vm.showParts
          ? _vm.$t('maintenance.parts_hide')
          : _vm.$t('maintenance.parts_show'))+"\n    ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }