//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    information: {
      type: String,
      required: true
    }
  }
}
