import { render, staticRenderFns } from "./ThingSettingsModal.vue?vue&type=template&id=79ce14e3&"
import script from "./ThingSettingsModal.vue?vue&type=script&lang=js&"
export * from "./ThingSettingsModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesSettingsLocationForm: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/settings/location/Form.vue').default,AtomsSkeleton: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Skeleton.vue').default,MoleculesButton: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Button.vue').default,Modal: require('/builds/duerrdental/vistor/frontend/vsm/components/Modal.vue').default,MoleculesSettingsMarkModal: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/settings/mark/Modal.vue').default,MoleculesSettingsRemoveModal: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/settings/remove/Modal.vue').default})
