import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=562e107d&"
import script from "./Modal.vue?vue&type=script&lang=js&"
export * from "./Modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesHeading: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Heading.vue').default,MoleculesSteps: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Steps.vue').default,MoleculesExtendWarrantyForm: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/extend-warranty/Form.vue').default,MoleculesButton: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Button.vue').default,Modal: require('/builds/duerrdental/vistor/frontend/vsm/components/Modal.vue').default})
