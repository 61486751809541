//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormInputMixin from '~/mixins/FormInputMixin'
import { FormType } from '~/components/molecules/form/FormType'
import MoleculesFormSelect from '~/components/molecules/form/Select'
import MoleculesFormLazyLoadingSelect from '~/components/molecules/form/LazyLoadingSelect'
import MoleculesFormCheckbox from '~/components/molecules/form/Checkbox'
import MoleculesFormRadio from '~/components/molecules/form/Radio'
import MoleculesFormFile from '~/components/molecules/form/File'
import MoleculesFormPhotos from '~/components/molecules/form/Photos'
import MoleculesFormText from '~/components/molecules/form/Text'
import MoleculesFormPlacesAutocomplete from '~/components/molecules/form/PlacesAutocomplete'
import MoleculesFormCountrySelect from '~/components/molecules/form/CountrySelect'

export default {
  name: 'MoleculesFormInput',
  mixins: [FormInputMixin],
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      default: () => []
    },
    component: {
      type: String,
      default: undefined
    },
    min: {
      type: [Number, String],
      default: undefined
    },
    max: {
      type: [Number, String],
      default: undefined
    },
    step: {
      type: Number,
      default: undefined
    },
    type: {
      type: String,
      default: 'text'
    },
    ariaLabel: {
      type: String,
      default: undefined
    }
  },
  computed: {
    inputCssClasses() {
      return [
        'w-full focus:outline-none sm:text-sm bg-white',
        !['select', 'number'].includes(this.type) && 'pr-3', // padding for icon
        this.innerError
          ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
          : 'border-gray-300 text-primary-900 placeholder-primary-300 focus:ring-primary-500 focus:border-primary-500',
        this.isDisabled && 'cursor-not-allowed bg-gray-100 opacity-50',
        !this.isDisabled && 'shadow-sm'
      ]
    },
    innerComponent() {
      if (this.component) return this.component

      if (this.type === FormType.Select) {
        return MoleculesFormSelect.name
      }

      if (this.type === FormType.LazyLoadingSelect) {
        return MoleculesFormLazyLoadingSelect.name
      }

      if (this.type === FormType.Checkbox) {
        return MoleculesFormCheckbox.name
      }

      if (this.type === FormType.Radio) {
        return MoleculesFormRadio.name
      }

      if (this.type === FormType.File) {
        return MoleculesFormFile.name
      }

      if (this.type === FormType.Photos) {
        return MoleculesFormPhotos.name
      }

      if (this.type === FormType.PlacesAutocomplete) {
        return MoleculesFormPlacesAutocomplete.name
      }

      if (this.type === FormType.CountrySelect) {
        return MoleculesFormCountrySelect.name
      }

      return MoleculesFormText.name
    },
    rangeText() {
      const text = []
      if (this.step)
        text.push(`${this.$t('formFields.default.range.step')}: ${this.step}`)
      if (this.min !== undefined)
        text.push(`${this.$t('formFields.default.range.min')}: ${this.min}`)
      if (this.max !== undefined)
        text.push(`${this.$t('formFields.default.range.max')}: ${this.max}`)
      return text.join(', ')
    }
  }
}
