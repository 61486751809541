//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters['user/isAdmin']
    },
    currentTab() {
      const fullPath = this.$route.fullPath.toLowerCase()

      return this.tabs.findIndex(({ href }) => href.toLowerCase() === fullPath)
    },
    tabs() {
      const details = {
        label: this.$t('settings.organization.details'),
        href: this.getHref('details')
      }
      const members = {
        label: this.$t('settings.members.title'),
        href: this.getHref('members')
      }
      const subscriptions = {
        label: this.$t('settings.subscription.title'),
        href: this.getHref('subscriptions')
      }
      const tabs = [details, this.isAdmin && members, subscriptions].filter(
        (v) => v // remove empty ones
      )
      return tabs
    }
  },
  methods: {
    onChange(tabIndex) {
      const { href } = this.tabs[tabIndex]
      this.$router.push(href)
    },
    getHref(name) {
      const baseName = 'settings-organization-organizationId-edit-'

      const { href } = this.$router.resolve({
        name: `${baseName}${name}`,
        params: { organizationId: this.organizationId }
      })

      return href
    }
  }
}
