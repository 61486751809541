var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('molecules-dropdown',{staticClass:"_tid_dropdown",attrs:{"position":"bottom-end","close-on-select":false,"items":_vm.categoriesDropdownItems},on:{"select":_vm.onCategorySelect},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('div',{staticClass:"flex text-xs px-2 cursor-pointer hover:text-primary-500",class:{
        'text-primary-500 font-bold': _vm.selectedItems.length > 0,
        'text-gray-400': _vm.selectedItems.length === 0
      },on:{"click":toggle}},[_c('span',{staticClass:"dropdown-toggle-text text-center",class:{ 'font-bold': _vm.selectedItems.length > 0 },attrs:{"title":_vm.toggleText}},[_vm._v("\n        "+_vm._s(_vm.toggleText)+"\n      ")]),_vm._v(" "),_c('atoms-icon',{class:['w-4 h-4 ml-1 transition-transform', isOpen && 'rotate-180'],attrs:{"name":"chevron-down"}})],1)]}},{key:"item",fn:function(ref){
      var item = ref.item;
      var select = ref.select;
return [_c('div',{key:item.key,staticClass:"cursor-pointer rounded relative border border-transparent group flex items-center -mx-1 px-3 py-2 text-base sm:text-sm text-gray-700 hover:bg-primary-600 hover:text-white",attrs:{"role":"menuitem"},on:{"mouseup":function($event){return select(item)}}},[_c('div',{staticClass:"mr-3 h-3 w-3 flex-shrink-0 sm:h-3 sm:w-3 border border-gray-400"},[(item.icon)?_c('atoms-icon',{staticClass:"h-full w-full text-gray-400 group-hover:text-white",attrs:{"name":item.icon}}):_vm._e()],1),_vm._v(" "),_c('span',{staticClass:"truncate",attrs:{"title":item.label}},[_vm._v("\n        "+_vm._s(_vm.$t(item.label))+"\n      ")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }