import { render, staticRenderFns } from "./Messages.vue?vue&type=template&id=4c37d8a4&"
import script from "./Messages.vue?vue&type=script&lang=js&"
export * from "./Messages.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesEmptyState: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/EmptyState.vue').default,AtomsIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Icon.vue').default,MoleculesButton: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Button.vue').default,MoleculesMessagesRow: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/messages/MessagesRow.vue').default,MoleculesPagination: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Pagination.vue').default})
