import { GetterTree } from 'vuex'
import { LegalDocument } from '~/models/legal/LegalDocument'
import { IRootState } from '~/store'
import { LegalState } from '~/store/legal/state'
import { LegalDocumentId } from '~/models/legal/LegalDocumentId'

export default {
  documents: ({ documents }) => documents,
  document: (_, { documents }) => (id: LegalDocumentId) => {
    const document = documents.find((d: LegalDocument) => d.hasId(id))

    return document || LegalDocument.empty()
  },
  has: (_, { document }) => (id: LegalDocumentId) => !document(id).isEmpty
} as GetterTree<LegalState, IRootState>
