//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    elements: {
      type: Array,
      required: true
    },
    emptyListText: {
      type: String,
      required: true
    }
  }
}
