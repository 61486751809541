//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import REGISTER_ECRAN_DEVICES from '~/queries/thing/registerEcran.mutation.gql'

export default {
  props: {
    scannedData: {
      type: String,
      required: true
    }
  },
  data() {
    const data = JSON.parse(this.scannedData)
    return {
      isLoading: false,
      model: data.model,
      sn: data.sn,
      qrData: data.code
    }
  },
  computed: {
    rootOrg() {
      return this.$store.getters['organization/byId'](
        this.$store.getters['organization/rootId']
      )
    }
  },
  methods: {
    async registerDevice() {
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: REGISTER_ECRAN_DEVICES,
          variables: {
            qrData: this.qrData
          }
        })
        this.$store.dispatch('notify/dialog', {
          title: this.$t('add_thing.success.title'),
          message: this.$t('add_thing.success.description'),
          dismiss: true,
          timeout: 3000
        })
      } catch (err) {
        this.$store.dispatch('notify/dialog', {
          title: this.$t('add_thing.error.title'),
          message: this.$t('add_thing.error.description'),
          dismiss: false,
          timeout: 999999999
        })
      } finally {
        this.isLoading = false
        this.$emit('close')
      }
    }
  }
}
