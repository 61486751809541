//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import resolveConfig from 'tailwindcss/resolveConfig'
import VueSimpleSuggest from 'vue-simple-suggest'

import tailwindConfig from '~/tailwind.config.js'
import { LegacyConnector } from '~/lib/utils/legacy-connector'
import { State } from '~/models/thing/State'
import Thing from '~/models/thing/Thing'

const states = [State.Gray, State.Green, State.Yellow, State.Red]
const categories = {
  gray: 0,
  green: 1,
  yellow: 2,
  red: 3
}
let PruneCluster = null
let PruneClusterForLeaflet = null

export default {
  components: {
    VueSimpleSuggest
  },
  props: {
    things: {
      type: Array,
      default: () => []
    },
    isDashboard: {
      type: Boolean,
      default: false
    },
    filteredOrgs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      search: '',
      map: null,
      clusterLayer: null,
      zoom: 7,
      center: [0, 0],
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      markers: [],
      positions: [],
      searchStyles: {
        vueSimpleSuggest: '',
        defaultInput:
          'border border-gray-300 h-10 pl-10 text-sm focus:border-primary-500 focus:ring-primary-500',
        suggestions:
          'absolute left-0 right-0 top-full z-50 bg-white border border-primary-500',
        suggestItem:
          'cursor-pointer px-2 py-4 text-sm hover:bg-primary-500 hover:text-white active:bg-primary-500 active:text-white'
      }
    }
  },
  computed: {
    orgs() {
      return this.$store.getters['organization/getList']
    },
    orgsWithCoordinates() {
      return this.$store.getters['organization/getOrgWithCoordinates']
    },
    colors() {
      const {
        theme: { colors }
      } = resolveConfig(tailwindConfig)
      return {
        0: colors.gray['500'],
        1: colors.green['600'],
        2: colors.yellow['600'],
        3: colors.red['600']
      }
    },
    locations() {
      const filteredThings = this.things.filter(
        (t) =>
          !LegacyConnector.isLegacyConnector(t) &&
          !Thing.fromObject(t).isPlate &&
          t.localState &&
          states.includes(t.localState) &&
          this.orgsWithCoordinates.includes(t.org)
      )
      const thingsOrgs = [...new Set(filteredThings.map((t) => t.org))]
      const locations = thingsOrgs.map((o) => {
        const thingCategories = filteredThings
          .filter((t) => t.org === o)
          .map((t) => categories[t.localState])
        const state = states[Math.max(...thingCategories)]
        return {
          id: o,
          state,
          name: this.orgs[o].name,
          geoip: this.orgs[o].geoip
        }
      })
      return locations
    }
  },
  watch: {
    search() {
      if (this.search) return
      // reset view if search is cleared
      this.setMarkers(this.locations)
      this.onReset()
    },
    locations: {
      handler(newLocations) {
        this.setMarkers(newLocations)
      }
    },
    filteredOrgs() {
      this.setMarkers(this.locations)
      this.invalidateSize()
      this.onReset()
    }
  },
  methods: {
    async loadPruneCluster() {
      // we need to make sure that the pruneCluster is loaded AFTER the map component
      // this worked well in pages, but nuxt loads global registered components (and their imports) before pages
      // and since PrunceCluster needs leaflet upfront, this failed.
      if (PruneCluster === null) {
        const cluster = await import('prunecluster-exportable')
        PruneCluster = cluster.PruneCluster
        PruneClusterForLeaflet = cluster.PruneClusterForLeaflet
      }
    },
    getList() {
      return [...new Set(this.locations.map((l) => l.name))]
    },
    filterLocations(locations) {
      return locations.filter(
        (location) =>
          this.filteredOrgs.length === 0 ||
          this.filteredOrgs.includes(location.id)
      )
    },
    filterMarkers(suggestions) {
      const result = this.locations.filter((l) => suggestions.includes(l.name))
      this.setMarkers(result)
      this.onReset()
    },
    invalidateSize() {
      this.$refs.mapRef?.mapObject?.invalidateSize()
    },
    onSelect(selected) {
      if (selected) {
        this.filterMarkers([selected])
      }
    },
    onEnter() {
      const suggestions = this.$refs.searchRef.suggestions
      if (suggestions.length === 1) this.$refs.searchRef.select(suggestions[0])
    },
    onClear() {
      this.search = ''
    },
    onReset() {
      if (!this.markers.length) return

      this.positions = this.markers.map((m) => [m.position.lat, m.position.lng])
      this.map.fitBounds(this.positions, {
        maxZoom: 15,
        paddingTopLeft: [0, 100],
        paddingBottomRight: [40, 25]
      })
    },
    setMarkers(locations) {
      if (!this.clusterLayer) {
        // clusterLayer not yet defined.
        return
      }
      this.markers = []
      this.clusterLayer.RemoveMarkers()
      const filteredLocations = this.filterLocations(locations)
      for (const l of filteredLocations) {
        const marker = new PruneCluster.Marker(
          l.geoip.latitude,
          l.geoip.longitude
        )
        marker.category = categories[l.state]
        // custom data
        marker.data.id = l.id
        marker.data.name = l.name
        marker.data.state = l.state

        this.markers.push(marker)
        this.clusterLayer.RegisterMarker(marker)
      }
      this.clusterLayer.ProcessView()
    },
    async loadMap() {
      await this.loadPruneCluster()
      this.map = this.$refs.mapRef.mapObject
      this.clusterLayer = new PruneClusterForLeaflet()
      this.configCluster()
      this.configMarker()
      this.map.addLayer(this.clusterLayer)
      this.setMarkers(this.locations)
      this.onReset()
    },
    configCluster() {
      this.clusterLayer.BuildLeafletClusterIcon = (cluster) => {
        const e = new this.$L.Icon.MarkerCluster()
        e.stats = cluster.stats
        e.locationCount = cluster.population
        return e
      }

      const pi2 = Math.PI * 2
      const vm = this
      this.$L.Icon.MarkerCluster = this.$L.Icon.extend({
        options: {
          iconSize: new this.$L.Point(44, 44),
          className: 'prunecluster leaflet-markercluster-icon'
        },

        createIcon() {
          const e = document.createElement('canvas')
          this._setIconStyles(e, 'icon')
          const s = this.options.iconSize
          e.width = s.x
          e.height = s.y
          this.draw(e.getContext('2d'), s.x, s.y)
          return e
        },

        createShadow() {
          return null
        },

        draw(canvas) {
          let start = 0
          for (let i = 0; i < Object.keys(categories).length; i++) {
            const size = this.stats[i] / this.locationCount

            if (size > 0) {
              canvas.beginPath()
              canvas.moveTo(22, 22)
              canvas.fillStyle = vm.colors[i]
              let from = start + 0.14
              const to = start + size * pi2

              if (to < from) {
                from = start
              }
              canvas.arc(22, 22, 22, from, to)

              start = start + size * pi2
              canvas.lineTo(22, 22)
              canvas.fill()
              canvas.closePath()
            }
          }

          canvas.beginPath()
          canvas.fillStyle = 'white'
          canvas.arc(22, 22, 18, 0, Math.PI * 2)
          canvas.fill()
          canvas.closePath()

          canvas.fillStyle = '#555'
          canvas.textAlign = 'center'
          canvas.textBaseline = 'middle'
          canvas.font = 'bold 16px sans-serif'

          canvas.fillText(this.locationCount, 22, 22, 40)
        }
      })
    },
    configMarker() {
      this.clusterLayer.PrepareLeafletMarker = (leafletMarker, data) => {
        leafletMarker.setIcon(this.getIcon(data.state))
        leafletMarker.on('click', () => {
          this.$router.push({
            path: '/things',
            query: {
              organizations: JSON.stringify([data.id])
            }
          })
        })
        leafletMarker.bindTooltip(data.name, {
          direction: 'top',
          offset: [1, -45]
        })
      }
    },
    getIcon(state) {
      return this.$L.icon({
        iconUrl: `/svg/markers/${state}.svg`,
        iconSize: [50, 95],
        iconAnchor: [24, 67]
      })
    }
  }
}
