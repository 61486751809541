//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    isRoot: {
      type: Boolean,
      required: true
    },
    hasChildren: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    information() {
      if (this.isRoot) {
        return this.$t('settings.organization.delete_not_possible.is_root')
      }

      if (this.hasChildren) {
        return this.$t('settings.organization.delete_not_possible.has_children')
      }

      return ''
    }
  }
}
