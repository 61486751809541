import Vue from 'vue'
import { LocaleMessages } from 'vue-i18n'
import { Notification } from '~/mixins/notification-mixin/Notification'

export default Vue.extend({
  methods: {
    notifyError(message: string | LocaleMessages) {
      return this._notify(`notify/${Notification.Error}`, message)
    },
    notifySuccess(message: string | LocaleMessages) {
      return this._notify(`notify/${Notification.Success}`, message)
    },
    notifyInfo(message: string | LocaleMessages) {
      return this._notify(`notify/${Notification.Info}`, message)
    },
    _notify(action: string, message: string | LocaleMessages) {
      return this.$store.dispatch(action, message)
    }
  }
})
