var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'gap-6',
    _vm.superDense && 'flex flex-col',
    !_vm.superDense && _vm.dense ? 'xl:flex' : 'md:flex',
    !_vm.superDense && !_vm.wrapped && 'sm:mx-0'
  ]},[_c('div',{class:[
      'max-w-lg',
      _vm.dense ? 'xl:w-4/12 flex-shrink-0' : 'md:w-4/12 flex-shrink-0'
    ]},[_c('div',{class:!_vm.wrapped && 'px-4 sm:px-0'},[_c('h3',{staticClass:"text-lg font-medium leading-6 text-gray-800 break-words"},[_vm._v("\n        "+_vm._s(_vm.$t(_vm.title))+"\n      ")]),_vm._v(" "),(_vm.description)?_c('p',{staticClass:"mt-px text-sm text-gray-500 break-words"},[_vm._v("\n        "+_vm._s(_vm.$t(_vm.description))+"\n      ")]):_vm._e(),_vm._v(" "),_vm._t("footer")],2)]),_vm._v(" "),_c('div',{class:['mt-5 w-full flex-grow', _vm.dense ? 'xl:mt-0' : 'md:mt-0']},[_c('div',{staticClass:"max-w-4xl"},[_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }