//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    thing: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    hasWarrantyPromo: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      currentStep: 0,
      isLoadingLocal: this.isLoading,
      steps: [
        {
          key: 'device_details',
          name: 'extend_warranty.form.device_details',
          fields: ['serialno', 'articleno', 'installed', 'name']
        },
        {
          key: 'practice_details',
          name: 'extend_warranty.form.practice_details',
          fields: [
            'practice',
            'street',
            'zip',
            'town',
            'country',
            'email',
            'telephone',
            'honorific',
            'title',
            'firstname',
            'lastname'
          ]
        },
        {
          key: 'dental_depot',
          name: 'extend_warranty.form.dental_depot_details',
          fields: [
            'dealer_name',
            'dealer_town',
            'dealer_email',
            'dealer_telephone'
          ]
        },
        {
          key: 'confirmation',
          name: 'extend_warranty.form.confirmation',
          fields: [
            'tos',
            'termsNewProduct',
            'dataProcessing',
            'news',
            'coupon_code'
          ]
        }
      ]
    }
  },
  computed: {
    isFirstStep() {
      return this.currentStep === 0
    },
    isLastStep() {
      return this.currentStep === this.steps.length - 1
    }
  },
  methods: {
    setLoadingLocal({ isLoading }) {
      this.isLoadingLocal = isLoading
    },
    onNavigate(index) {
      this.currentStep = index
    },
    onPrevious() {
      this.currentStep--
    },
    onNext({ validation }) {
      let invalid = false
      const step = this.steps[this.currentStep]
      const isDeviceDetails = step.key === 'device_details'
      if (isDeviceDetails) {
        for (let i = 0; i < validation.formData.$model.equipment.length; i++) {
          for (const field of step.fields) {
            if (
              validation.formData[field + i] &&
              validation.formData[field + i].$invalid
            ) {
              validation.formData[field + i].$touch()
              invalid = true
            }
          }
        }
      } else {
        for (const field of step.fields) {
          if (
            validation.formData[field] &&
            validation.formData[field].$invalid
          ) {
            validation.formData[field].$touch()
            invalid = true
          }
        }
      }
      if (!invalid) this.currentStep++
    }
  }
}
