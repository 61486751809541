//
//
//
//
//
//
//
//
//
//

import { ProductScanner } from '~/lib/ProductScanner'

export default {
  props: {
    onScan: {
      type: Function,
      required: true
    }
  },
  mounted() {
    this.productScanner = new ProductScanner(this.$platform.native.scanner)
  },
  methods: {
    onClick() {
      return this.scan()
    },
    async scan() {
      try {
        const result = await this.productScanner.scan()
        this.onScan(result)
      } catch ({ message = '' }) {
        return this.$store.dispatch('notify/dialog', {
          title: this.$t('search.alert_title'),
          message,
          dismiss: false,
          timeout: 999999999
        })
      }
    }
  }
}
