
const allowedProps = [
  'class',
  'style',
  'attrs',
  'props',
  'domProps',
  'on',
  'nativeOn',
  'directives',
  'scopedSlots',
  'slot',
  'key',
  'ref',
  'refInFor'
]

function mapProps(schema) {
  const props = {}
  Object.keys(schema).forEach((key) => {
    if (allowedProps.includes(key)) {
      props[key] = schema[key]
    }
  })
  return props
}

function create(h, schema) {
  const children = schema.children
  const hasMultipleChildren = Array.isArray(children)
  const isComponent = !!schema.component
  const isTag = !!schema.tag
  const isString = typeof schema === 'string'

  if (isString) {
    return schema
  }
  if (isTag || isComponent) {
    return h(
      schema.tag || schema.component,
      mapProps(schema),
      hasMultipleChildren && children.length > 0
        ? children.map((child) => create(h, child))
        : children && children
    )
  }
}

export default {
  props: {
    schema: { type: Object, required: true }
  },
  render(h) {
    return create(h, this.schema)
  }
}
