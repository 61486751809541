//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: null
    },
    information: {
      type: String,
      default: null
    },
    confirmText: {
      type: String,
      default: null
    },
    deleteButtonText: {
      type: String,
      default: null
    },
    deleteButtonIcon: {
      type: String,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    checkboxActive: false
  })
}
