import getPayload from '../../lib/jwt/getPayload'
import { Role } from '~/models/user/Role'
import { AccessType } from '~/models/user/AccessType'
import { OrgType } from '~/models/organization/Organization'

export const token = (state) => state.token
export const eula = (state) => state.eula
export const id = (state) => state.id
export const role = (state) => state.role
export const rorg = (state) => state.rorg
export const rootOrgType = (state) => state.rootOrgType
export const profile = ({ firstName, lastName, name, email }) => ({
  firstName,
  lastName,
  name,
  email
})

export const isTokenExpired = (state) => {
  const payload = getPayload(state.token)
  if (payload.exp) {
    return Date.now() / 1000 > payload.exp
  }
  return true
}

export const isLoggedIn = (state, getters) => {
  return !!(state.token && !getters.isTokenExpired)
}

export const loadingSources = (state) => state.loadingSources

export const isAdmin = (state) => state.role === Role.Admin

export const isUser = (state) => state.role === Role.User

export const isTechnician = (state) => state.role === Role.Technician

export const isInternal = (state) => state.accessType === AccessType.Internal

export const isServicePartner = (state) =>
  state.rootOrgType === OrgType.ServicePartner

/**
 * Returns the User's rootOrgType and accessType.
 * We use this to control access to News and ETK
 * on the routes and Navigation.
 */
export const capabilities = (state) => ({
  rootOrgType: state.rootOrgType,
  accessType: state.accessType
})
