type TextObject = {
  [locale: string]: string
}

export const getLocalizedText = (textObj: TextObject, locale: string) => {
  const localizedText = textObj && textObj[locale]
  if (localizedText) {
    return localizedText
  }
  return textObj.en || textObj
}
