//
//
//
//
//
//

export default {
  mounted() {
    if (this.$platform.isNative) {
      const { StatusBar, Style } = require('@capacitor/status-bar')
      StatusBar.setStyle({ style: Style.Default }) // make text readable if user is on light mode
    }
  }
}
