//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    src: { type: String, default: null },
    index: { type: Number, default: 0 },
    loading: { type: Boolean, default: false },
    hideOnError: { type: Boolean, default: false }
  },
  data() {
    return {
      ready: false,
      error: null
    }
  },
  computed: {
    imgPath() {
      if (this.error) {
        return '/img/no-image.svg'
      }
      return this.src
    }
  },
  watch: {
    imgPath: {
      immediate: true,
      handler(src) {
        if (!this.src && this.loading) return
        if (this.img) {
          this.img.onload = () => {}
          this.img.onerror = () => {}
          delete this.img
        }
        this.img = new Image()
        this.img.onload = this.onLoad
        this.img.onerror = this.onError
        this.img.src = src
      }
    },
    src() {
      // allow retry if previous source has an error loading
      this.ready = false
      this.error = false
    }
  },
  methods: {
    onLoad() {
      this.ready = true
    },
    onError(error) {
      this.ready = true
      this.error = error
    }
  }
}
