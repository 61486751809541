//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    disableOverlay: { type: Boolean, default: false },
    disableFirstAnimation: { type: Boolean, default: false }
  },
  data() {
    return {
      skipAnimation: true,
      isContentVisible: true
    }
  },
  computed: {
    transitions() {
      if (this.skipAnimation) {
        return ''
      } else {
        return 'transform transition ease-in-out duration-500'
      }
    },
    duration() {
      return this.skipAnimation ? 0 : 250
    }
  },
  created() {
    this.skipAnimation = this.disableFirstAnimation
    if (this.disableFirstAnimation) {
      this.skipAnimation = true
      setTimeout(() => {
        this.skipAnimation = false
      }, 500)
    }
  },
  methods: {
    close(callback) {
      // TODO: Add close with escape button functionality
      this.isContentVisible = false
      setTimeout(callback, this.duration)
    }
  }
}
