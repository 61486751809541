//
//
//
//
//
//
//
//
//
//
//
//

const NUMBER_ELEMENTS = 4

export default {
  data: () => ({ elements: new Array(NUMBER_ELEMENTS) }),
  methods: {
    getOpacityCssClass(index) {
      switch (index) {
        case 0:
          return 'opacity-100'
        case 1:
          return 'opacity-75'
        case 2:
          return 'opacity-50'
        case 3:
        default:
          return 'opacity-25'
      }
    }
  }
}
