var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"component",staticClass:"p-2 !-mb-2 w-full"},[_c('div',{staticClass:"flex border-transparent border relative w-full",class:[
      _vm.active
        ? 'bg-white border-gray-200'
        : 'hover:border-gray-300 hover:bg-gray-50'
    ]},[(_vm.active)?_c('div',{staticClass:"absolute right-0 top-0 h-full pr-1 bg-blue-500 z-10"}):_vm._e(),_vm._v(" "),_c('router-link',{attrs:{"to":{ name: 'news-id', params: { id: _vm.item.id } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var navigate = ref.navigate;
return [_c('a',{staticClass:"focus:outline-none flex space-x-3 overflow-hidden w-full border border-transparent",class:_vm.active && 'border-blue-500 shadow',attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"flex-shrink-0 bg-white hidden xs:flex"},[_c('molecules-image',{staticClass:"h-full w-24 pl-1 object-contain flex",attrs:{"loading":_vm.showSkeleton,"index":_vm.index,"src":_vm.item.imageUrl,"alt":_vm.item.title}})],1),_vm._v(" "),_c('div',{staticClass:"pr-4 py-3 flex-col overflow-hidden w-full"},[_c('atoms-skeleton',{class:_vm.showSkeleton && 'leading-snug overflow-clip',attrs:{"index":_vm.index,"show":_vm.showSkeleton,"min":4,"max":14}},[_c('p',{staticClass:"text-sm 2xl:text-md font-medium text-gray-900 line-clamp-2 leading-tight 2xl:leading-snug"},[_vm._v("\n              "+_vm._s(_vm.item.title)+"\n            ")])]),_vm._v(" "),_c('atoms-skeleton',{attrs:{"index":_vm.index,"show":_vm.showSkeleton,"min":20,"max":50}},[_c('p',{staticClass:"text-sm xl:text-xs 2xl:text-sm font-light text-gray-500 line-clamp-3 mt-1 leading-snug"},[_vm._v("\n              "+_vm._s(_vm.item.teaser)+"\n            ")])])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }