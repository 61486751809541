//
//
//
//
//
//
//
//
//
//
//
//

import Validations from '~/config/validations'

export default {
  props: {
    organizationId: {
      type: String,
      required: true
    },
    organization: {
      type: Object,
      default: null
    },
    locations: {
      type: Array,
      default: () => []
    },
    onChange: {
      type: Function,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    }
  },
  computed: {
    rules() {
      const definitions = {
        name: {
          required: true,
          pattern: null
          // TODO: The name regex did not work as expected, for this reason I have implemented this.
          // "pattern": "^[a-zÀ-žÀ-ÖØ-öø-ÿA-я\\s\\-]+$"
        },
        porg: {
          required: this.locations.length !== 0
        },
        address: {}
      }

      const { name, porg, address } = Validations.buildValidationRules(
        this.$i18n.locale,
        definitions
      )

      return { name, porg, address }
    }
  },
  methods: {
    submit() {
      this.$refs.form.handleSubmit()
    }
  }
}
