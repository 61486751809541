const defaultTheme = require('tailwindcss/defaultTheme')
const colors = require('tailwindcss/colors')
const plugin = require('tailwindcss/plugin')

module.exports = {
  mode: 'jit',
  theme: {
    screens: {
      xs: '320px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
      '3xl': '1800px',
      screen: { raw: 'screen' },
      print: { raw: 'print' }
    },
    zIndex: {
      0: 0,
      10: 10,
      20: 20,
      30: 30,
      40: 40,
      50: 50,
      600: 600,
      610: 610,
      620: 620,
      1100: 1100,
      auto: 'auto'
    },
    extend: {
      minWidth: {
        56: '14rem'
      },
      maxWidth: {
        'screen-safe': 'calc(100vw - 3rem)'
      },
      maxHeight: {
        'screen-safe': 'calc(100vh - 15rem)'
      },
      spacing: {
        128: '32rem',
        144: '36rem'
      },
      margin: {
        scroll: 'var(--scroll-margin)'
      },
      opacity: {
        scroll: 'var(--scroll-opacity)'
      },
      fontFamily: {
        sans: ['Inter var', ...defaultTheme.fontFamily.sans]
      },
      animation: {
        bounce: 'bounce 1s 5',
        pulse: 'pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite',
        shake: 'shake 750ms ease-out'
      },
      keyframes: {
        pulse: {
          '0%, 100%': {
            opacity: 0.25
          },
          '50%': {
            opacity: 1
          }
        },
        shake: {
          'from, to': {
            transform: 'translate3d(0, 0, 0)'
          },
          '10%, 30%, 50%, 70%, 90%': {
            transform: 'translate3d(-5px, 0, 0)'
          },
          '20%, 40%, 60%, 80%': {
            transform: 'translate3d(5px, 0, 0)'
          }
        }
      },
      colors: {
        gray: colors.blueGray,
        blue: colors.sky,
        primary: {
          50: '#F3F9FB',
          100: '#E6F2F7',
          200: '#C0DEEB',
          300: '#97C9DF',
          400: '#4DA3C7',
          500: '#007BAF',
          600: '#006E9C',
          700: '#004A69',
          800: '#00384F',
          900: '#002433'
        },
        secondary: {
          DEFAULT: '#00BBEE',
          50: '#D4F6FF',
          100: '#BBF0FF',
          200: '#88E5FF',
          300: '#55DBFF',
          400: '#22D0FF',
          500: '#00BBEE',
          600: '#0093BB',
          700: '#006B88',
          800: '#004355',
          900: '#001B22'
        }
      }
    }
  },
  variants: {},
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/line-clamp'),
    require('tailwindcss-padding-safe')(),
    plugin(function ({ addUtilities }) {
      // TODO: Investigate the expected behaviour.
      //   The Object has duplicate keys, so the last definition will be used,
      //   so the first should be obsolete.

      /* eslint-disable no-dupe-keys */
      const newUtilities = {
        '.pt-safe': {
          paddingTop: [
            'constant(safe-area-inset-top)',
            'env(safe-area-inset-top)'
          ]
        },
        '.pl-safe': {
          paddingLeft: [
            'constant(safe-area-inset-left)',
            'env(safe-area-inset-left)'
          ]
        },
        '.pr-safe': {
          paddingRight: [
            'constant(safe-area-inset-right)',
            'env(safe-area-inset-right)'
          ]
        },
        '.pb-safe': {
          paddingBottom: [
            'constant(safe-area-inset-bottom)',
            'env(safe-area-inset-bottom)'
          ]
        },
        '.mt-safe': {
          marginTop: [
            'constant(safe-area-inset-top)',
            'env(safe-area-inset-top)'
          ]
        },
        '.ml-safe': {
          marginLeft: [
            'constant(safe-area-inset-left)',
            'env(safe-area-inset-left)'
          ]
        },
        '.mr-safe': {
          marginRight: [
            'constant(safe-area-inset-right)',
            'env(safe-area-inset-right)'
          ]
        },
        '.mb-safe': {
          marginBottom: [
            'constant(safe-area-inset-bottom)',
            'env(safe-area-inset-bottom)'
          ]
        },
        '.inline-box': {
          display: '-webkit-inline-box'
        }
      }
      /* eslint-enable no-dupe-keys */
      addUtilities(newUtilities)
    })
  ],
  purge: {
    content: [
      'components/**/*.vue',
      'layouts/**/*.vue',
      'pages/**/*.vue',
      'plugins/**/*.js',
      'nuxt.config.js',
      // TypeScript
      'plugins/**/*.ts',
      'nuxt.config.ts',
      'lib/utils/color-map.ts'
    ]
  }
}
