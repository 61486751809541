import { render, staticRenderFns } from "./Category.vue?vue&type=template&id=61c4d16b&"
import script from "./Category.vue?vue&type=script&lang=js&"
export * from "./Category.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsSkeleton: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Skeleton.vue').default,MoleculesHeading: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Heading.vue').default,AtomsIcon: require('/builds/duerrdental/vistor/frontend/vsm/components/atoms/Icon.vue').default,MoleculesDocumentsPreview: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/documents/Preview.vue').default,MoleculesTransitionList: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/TransitionList.vue').default})
