//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    index: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    animate: {
      type: Boolean,
      default: null
    }
  }
}
