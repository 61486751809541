//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { toLowerCase } from '~/lib/utils/string'

export default {
  props: {
    messages: { type: Array, default: () => [] },
    thingModel: { type: String, default: '' },
    readOnly: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    confirmCallback: { type: Function, default: undefined }
  },
  data() {
    return {
      term: undefined,
      pageOfMessages: []
    }
  },
  computed: {
    filteredMessages() {
      if (!this.messages.length) return []

      const translatedMessages = this.messages.map((m) => {
        const alarmId = this.getAlarmId(m)
        return {
          ...m,
          displayMessage: this.getTranslatedMessage('message', m.code) || m.msg,
          detailedMessage:
            this.getTranslatedMessage('detailedMessage', m.code) || false,
          codeWithAlarmId: alarmId ? `${m.code} - ${alarmId}` : m.code,
          alarmId
        }
      })
      const sortedMessages = translatedMessages.sort(
        (a, b) => parseInt(b.ts_add) - parseInt(a.ts_add)
      )

      if (this.term) {
        const lowerCaseTerm = toLowerCase(this.term)
        return sortedMessages.filter(
          (m) =>
            toLowerCase(m.codeWithAlarmId).includes(lowerCaseTerm) ||
            toLowerCase(m.displayMessage).includes(lowerCaseTerm)
        )
      }

      return sortedMessages
    }
  },
  methods: {
    reset() {
      this.term = undefined
    },
    onChangePage(page) {
      this.pageOfMessages = page
    },
    getTranslatedMessage(type, code) {
      const specificTextkey = `_api.${type}.${this.thingModel}.${code}`
      const defaultTextkey = `_api.${type}.default.${code}`
      if (this.thingModel && this.$te(specificTextkey))
        return this.$t(specificTextkey)
      if (this.$te(defaultTextkey)) return this.$t(defaultTextkey)
    },
    getAlarmId(message) {
      if (!message?.alarm_id || message.alarm_id === '0') return
      return message.alarm_id
    }
  }
}
