//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormInputMixin from '~/mixins/FormInputMixin'

export default {
  name: 'MoleculesFormOnOffStandby',
  mixins: [FormInputMixin],
  inheritAttrs: false,
  props: {
    value: { type: Boolean, default: false },
    onLabel: { type: String, default: 'on' },
    offLabel: { type: String, default: 'off' },
    ariaLabel: { type: String, default: undefined }
  },
  computed: {
    innerValue() {
      if (this.$parentForm) {
        return this.castValue(this.$parentForm.formData[this.name])
      }
      return this.castValue(this.value)
    },
    switchLabel() {
      const intialValue = this.castValue(this.value)
      if (this.innerValue && intialValue) {
        return this.onLabel
      } else if (this.innerValue && !intialValue) {
        return this.$t('remote_operations.parameter.on_off_standby.switch_on')
      } else if (!this.innerValue && intialValue) {
        return this.$t('remote_operations.parameter.on_off_standby.switch_off')
      }
      return this.offLabel
    }
  },
  methods: {
    castValue(value) {
      if ([null, undefined].includes(value)) {
        return false
      }
      return [1, '1', true, 'true'].includes(value)
    },
    onInput(value) {
      this.handleInput(value, 'input')
    }
  }
}
