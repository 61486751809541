//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { inboxMessageClasses } from '~/models/inbox/Message'

export default {
  props: {
    message: { type: inboxMessageClasses, required: true }
  },
  computed: {
    showSkeleton() {
      return !this.message
    },
    orgName() {
      return this.message.orgName || this.message.inviterOrgName
    }
  }
}
